import React, { PureComponent, createRef } from 'react'
import { Col, Container, Row, Card, CardBody } from 'reactstrap'
import "react-datepicker/dist/react-datepicker.css"
import Button from '../../../components/button/Button'
import QRCode from 'qrcode'
import CircleJaffa from '../../../shared/img/logo/Circle_Jaffa.svg'
import { connect } from "react-redux"
@connect(
  store => ({
    drifft: store.drifft
  })
)
class QRCodePage extends PureComponent {

  constructor() {
    super()
    this.state = {
      canvasDrawn: false

    }
    this.downloadQR = this.downloadQR.bind(this)
    this.canvasRef = createRef(null)
  }

  async componentDidMount(){
    let thisHere = this

    setTimeout( function(){ thisHere.drawSplitLogo()} ,250)
  }

  drawSplitLogo = async () => {
    var canvasHtml = document.getElementById('canvas')
    let canvas = await QRCode.toCanvas(canvasHtml,`${this.props.drifft.v2FELink}/qr?publicId=${this.props.drifft.travelAgent.publicId}&branchId=Main`)
    const img = new Image()

    img.src = CircleJaffa
    let ctx = canvas.getContext('2d')

    //smoothen canvas for scaling
    ctx.mozImageSmoothingEnabled = false
    ctx.webkitImageSmoothingEnabled = false
    ctx.msImageSmoothingEnabled = false
    ctx.imageSmoothingEnabled = false
    // drawing Split logo onto canvas
    let QRsize = canvas.width
    let imageSize = 40
    let offSet = (QRsize - imageSize) / 2

    img.onload = () => {
      ctx.drawImage(img, offSet, offSet, imageSize, imageSize)

      this.setState({
        canvasDrawn: true
      })
    }
  }

  downloadQR = async (e) => {
    e.preventDefault()
    if (this.state.canvasDrawn) {
      //get current canvas
      const canvas = this.canvasRef.current
      //create new canvas
      var canvasUpscaled = document.createElement('canvas')
      let dimension = 1000

      canvasUpscaled.width = dimension
      canvasUpscaled.height = dimension
      let ctx = canvasUpscaled.getContext('2d')

      //do not smoothen canvas for scaling (makes image blurry)   
      ctx.mozImageSmoothingEnabled = false
      ctx.webkitImageSmoothingEnabled = false
      ctx.msImageSmoothingEnabled = false
      ctx.imageSmoothingEnabled = false
      ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, dimension, dimension)

      //draw enlarged Split logo over new canvas
      const img = new Image()

      img.src = CircleJaffa
      img.onload = () => {
        let imageSize = 280
        let offSet = (dimension - imageSize) / 2

        ctx.drawImage(img, offSet, offSet, imageSize, imageSize)
        //download QR code
        const a = document.createElement('a')

        a.href = canvasUpscaled.toDataURL()
        a.download = `QRCode_Main.png`
        a.click()
      }
    }
  }

  render() {
    return (
      <Container className = "dashboard">
        <Row>
          <Col md = { 12 }>
            <h3 className = "page-title">Static QR Code</h3>
          </Col>
        </Row>
        <Row>
          <Col md = { 12 }>
            <Card>
              <CardBody>
                <div className = "card__title">
                  <h5 className = "bold-text">Merchant in store static QR code</h5>
                  <p />
                  <p />
                  <div style = { { visibility: this.state.canvasDrawn ? 'visible' : 'hidden'} }>
                    <canvas id = 'canvas'
                      ref = { this.canvasRef }
                    />
                  </div>
                  <form className = "form"
                    onSubmit = { this.downloadQR }
                  >

                    <Button type = "submit">Download</Button>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    )
  }


}

export default QRCodePage

// ReportPage.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   t: PropTypes.func.isRequired,
// };




