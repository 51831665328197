import { combineReducers, createStore, compose,applyMiddleware} from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import { sidebarReducer, themeReducer, rtlReducer ,drifftReducer,customerReducer} from '../../redux/reducers/index'
import ReduxThunk from 'redux-thunk'


const middlewares = [ReduxThunk]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  drifft: drifftReducer,
  customer: customerReducer
})

const store = createStore(reducer, composeEnhancers(applyMiddleware( ...middlewares)))

export default store
