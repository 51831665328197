import React from 'react'
import TextBox from '../../../shared/components/textBox/TextBox'
import Text from '../../../shared/components/text/Text'
import Button from '../../../components/button/Button'
import EyeIcon from 'mdi-react/EyeIcon'
import { errors } from '../../../helpers/constants'
import { connect } from 'react-redux'
import { changeExistingPassword } from '../../../redux/reducers/drifftReducer'
import { Link } from 'react-router-dom'

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    changeExistingPassword: (password, currentPassword) => dispatch(changeExistingPassword(password, currentPassword)),
  })
)

class ChangePassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      previousStage: false,
      nextStage: false,
      currentPassword: '',
      password: '',
      confirmedPassword: '',
      showPassword: false,
      errorMsg: '',
      error: '',
      sucess: false
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  showPassword() {
    this.setState({ showPassword: !this.state.showPassword })
  }

  async onSubmit() {
    this.setState({ errorMsg: '', error: '' })
    let regex = new RegExp('^([a-zA-Z0-9!@*#]{8,15})$')

    if (this.state.password.length < 8) {
      this.setState({ errorMsg: errors.PASSWORD_TOO_SHORT, error: "password" })
    }
    else if (!regex.test(this.state.password)) {
      this.setState({ errorMsg: errors.PASSWORD_INVALID, error: "password" })
    }
    else if (this.state.password != this.state.confirmedPassword) {
      this.setState({ errorMsg: errors.PASSWORD_DO_NOT_MATCH, error: "confirmedPassword" })
    }
    else {
      await this.props.changeExistingPassword(this.state.password, this.state.currentPassword)
      if (!this.props.drifft.error) {
        this.setState({ sucess: true })
      } else {
        this.setState({ errorMsg: this.props.drifft.error, error: "currentPassword" })
      }
    }
  }

  render() {
    if (this.state.sucess) {
      return (
        <div className = "account">
          <div className = "account__wrapper">
            <div className = "account__card">
              <div className = "account__head"
                style = { { marginBottom: "10px" } }
              >
                <h3 className = "account__title">Success!</h3>
              </div>
              <h5 className = "account_sub"
                style = { { marginBottom: "30px" } }
              >Your password has been successfully changed! Click the button below to return to account settings.</h5>
              <div style = { { display: "flex", marginTop: "1.4rem" } }>
                <Link to = { this.props.drifft.travelAgentUser.isAdmin ? '/merchant/dashboard' : '/merchant/pastpayments' }>
                  <Button color = 'primary'>Return</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

      )
    }
    else return (
      <div className = "account">
        <div className = "account__wrapper">

          <div className = "account__card">
            <div className = "account__head"
              style = { { marginBottom: "10px" } }
            >
              <h3 className = "account__title">Change password</h3>
            </div>
            <h5 className = "account_sub"
              style = { { marginBottom: "30px" } }
            >To change your password, we will need your current password for verification.</h5>
            <div style = { { display: "flex", marginTop: "1.4rem", alignItems: 'center' } }>
              <div style = { { display: "flex", justifyContent: 'center', flexDirection: "column", width: "100%" } } >
                <div style = { { display: "flex", alignItems: 'flex-end' } }>
                  <TextBox
                    name = { 'currentPassword' }
                    type = { this.state.showPassword ? 'text' : 'password' }
                    value = { this.state.currentPassword }
                    onChange = { this.handleChange.bind(this) }
                    placeholder = "Old password"
                  />
                  <button
                    className = { `form__form-group-button` }
                    style = { { borderWidth: '0px', borderBottom: '0.1rem solid #c1ced3', backgroundColor: "#FFFFFF" } }
                    onClick = { this.showPassword.bind(this) }
                    type = "button"
                  >
                    <EyeIcon />
                  </button>
                </div>
                <div style = { { display: "flex", alignItems: 'flex-end' } }>
                  <TextBox
                    name = { 'password' }
                    type = { this.state.showPassword ? 'text' : 'password' }
                    value = { this.state.password }
                    onChange = { this.handleChange.bind(this) }
                    placeholder = "New password"
                  />
                  <button
                    className = { `form__form-group-button` }
                    style = { { borderWidth: '0px', borderBottom: '0.1rem solid #c1ced3', backgroundColor: "#FFFFFF" } }
                    onClick = { this.showPassword.bind(this) }
                    type = "button"
                  >
                    <EyeIcon />
                  </button>
                </div>

                <div style = { { display: "flex", alignItems: 'flex-end' } }>
                  <TextBox
                    name = { 'confirmedPassword' }
                    type = { this.state.showPassword ? 'text' : 'password' }
                    value = { this.state.confirmedPassword }
                    onChange = { this.handleChange.bind(this) }
                    placeholder = "Re-enter new password"
                  />
                  <button
                    className = { `form__form-group-button` }
                    style = { { borderWidth: '0px', borderBottom: '0.1rem solid #c1ced3', backgroundColor: "#FFFFFF" } }
                    onClick = { this.showPassword.bind(this) }
                    type = "button"
                  >
                    <EyeIcon />
                  </button>
                </div>
                <Text roboto
                  med
                  style = { { color: 'red' } }
                > {this.state.errorMsg} </Text>
                <div style = { { display: "flex", marginTop: "1.4rem" } }>
                  <Button control
                    color = "primary"
                    onClick = { this.onSubmit.bind(this) }
                  > Confirm </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ChangePassword