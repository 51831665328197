/* eslint-disable react/sort-comp */
/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from "react"
import { connect } from "react-redux"
// import { Button } from "reactstrap";
import { Field, reduxForm } from "redux-form"
import PropTypes from "prop-types"
import renderRadioButtonField from "../../../../shared/components/form/RadioButton"
import validate from "./validateRefund"
import ApiClient from "../../../../helpers/ApiClient"
import Button from '../../../../components/button/Button'
import { Form } from 'redux-form'
const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
  value,
  step,
  handleInputChange,
  defaultValue,
  disabled
}) => (
  <div>
    <input
      { ...input }
      placeholder = { placeholder }
      type = { type }
      value = { value }
      step = { step }
      defaultValue = { defaultValue }
      disabled = { disabled }
      className = "textBox"
      style = { { width: "80%" } }
      onChange = { handleInputChange }
    />
    {touched && error && (
    <span className = "form__form-group-error">{error}</span>
      )}
  </div>
  )

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  handleInputChange: PropTypes.func,
}

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
  disabled: false
}

@connect((store) => ({
  drifft: store.drifft
}))
class RefundFormOrder extends PureComponent {
  constructor() {
    super()
    var ticketId = ''
    const queryString = window.location.search

    if(queryString !== ""){
      const urlParams = new URLSearchParams(queryString)

      ticketId = urlParams.get("ticketId")
    }
    this.state = {
      errors: [],
      email: "",
      type: "",
      amount: null,
      reason: "",
      ticketId: ticketId,
      refundComplete: false,
      fieldType: "hidden"
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    this.setState({
      errors: [],
      showTextError: false,
      textErrorString: "",
    })
    try {
      await ApiClient.post("/refund/drifftRequest", { ...this.state })
      this.setState({ refundComplete: true })
    } catch (e) {
      alert(e.message)
    }
    
  };

  setRefundComplete = () =>{
    this.setState({      
      type: "",
      amount: null,
      reason: "",
      ticketId: "",
      refundComplete: false,
    })
   }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  };

  onChange(radioValue) {
    if(radioValue === "Full Refund"){
      this.setState({amount: null, fieldType: "hidden"})
    }
    else{
      this.setState({amount: document.getElementsByName("amount")[0].value, fieldType: ""})
    }
    this.setState({ type: radioValue })
  }

  render() {
    if (this.state.refundComplete === true) {
      return(
        <div>
          <h3 style = { {fontWeight: "bold"} }>Your request has been received!</h3>
          <div style = { {marginTop: "1rem"} }>
            <span>        
            Your refund request is being processed right now. 
            If we need any more information or clarification, 
            one of our partner support executives will review and reach out to you via e-mail.
            </span>
          </div>
          <div style = { {marginTop: "1rem"} }>
            <Button color = "primary"
              onClick = { this.setRefundComplete }
            >
              Request Another Refund
            </Button>
          </div>
        </div>
        )
    } else {
      return (
        <div>
          <h3 style = { {fontWeight: "bold",marginBottom: "1rem"} }>Refund Request</h3>
          <span>
            Use this option only after the customer's refund is approved by you.
            Split may reach out to you for more information before processing
            this request.
          </span>
          <Form onSubmit = { this.handleSubmit }>
            <div style = { { padding: ".5rem 0 0.1rem 0" } } />
            <div style = { { padding: ".5rem 0 0.1rem 0" } }>
              <Field
                name = "ticketId"
                component = { renderField }
                defaultValue = { this.state.ticketId }
                placeholder = "Order ID you want to refund"
                handleInputChange = { this.handleInputChange.bind(this) }
              />
            </div>
            <div style = { { padding: ".5rem 0 0.1rem 0" } }>
              <Field
                name = "type"
                label = "Full Refund"
                component = { renderRadioButtonField }
                radioValue = "Full Refund"
                onChange = { this.onChange.bind(this) }
                defaultChecked
              />
              <Field
                name = "type"
                label = "Partial Refund"
                component = { renderRadioButtonField }
                radioValue = "Partial Refund"
                onChange = { this.onChange.bind(this) }
              />
              <div style = { { padding: ".5rem 0 0.1rem 0" } }>
                <Field
                  name = "amount"
                  component = { renderField }
                  type = { this.state.fieldType }
                  disabled = { this.state.type === "Full Refund" }
                  placeholder = "Refund amount"
                  handleInputChange = { this.handleInputChange.bind(this) }
                />
              </div>
              <div style = { { padding: ".5rem 0 0.1rem 0" } }>
                <Field
                  name = "reason"
                  component = { renderField }
                  placeholder = "Reason for refund"
                  handleInputChange = { this.handleInputChange.bind(this) }
                />
              </div>
            </div>
            <div style = { {width: "80%"} }>
              <Button style = { {marginTop: "1rem",display: "block",marginLeft: "auto",marginRight: "auto"} }
                type = "submit"
              >
              Submit
              </Button>
            </div>
          </Form>
        </div>
      )
    }
  }
}
export default reduxForm({
  form: "refund_form_order",
  validate,
})(RefundFormOrder)
