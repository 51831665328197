export default class StorageService{
    static set(k, v){
        const val = JSON.stringify(v)

        localStorage.setItem(k, val)
        return val
    }

    static get(k){
        try {
            return JSON.parse(localStorage.getItem(k))
        } catch (e) {
            return null
        }
    }

    static remove(k){
        try {
            return JSON.parse(localStorage.removeItem(k))
        } catch (e) {
            return null
        }
    }
}