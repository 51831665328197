/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.price) {
    errors.price = 'Price field shouldn’t be empty and positive numbers only';
  }
  if(!values.title){
    errors.title = 'Title field shouldn’t be empty';
  }
  if (!values.email) {
    errors.email = 'Email field shouldn’t be empty';
  } 
  else if (!values.email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
    errors.email = 'Invalid email address';
  }
  if (!values.url) {
    errors.url = 'Url field shouldn’t be empty';
  } 
  if (!values.select) {
    errors.select = 'Please select the option';
  }
  if (!values.name) {
    errors.name = 'Legal name field shouldn’t be empty';
  }
  if (values.name) {
    if(values.name.length < 8) {
      errors.name = 'Legal name field is too short';
    }
    if(values.name.length >= 51) {
      errors.name = 'Legal name field is too long';
    }
  }
  if (values.address) {
    if(values.address.length>100){
      errors.address = 'Address is too long';
    }
  }
  if (values.deposit && values.price) {
    if(values.deposit / values.price >= 0.3){
      errors.deposit = 'This deposit amount seems too high'
    }
  }
  if (!values.phone) {
    errors.phone = 'phone field shouldn’t be empty';
  }
  return errors;
};

export default validate;
