import React,{useState,useEffect} from 'react'
import { Field, reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker'
import Picker from '../../../../components/picker/Picker'

import { Card, CardBody } from 'reactstrap'
import Button from '../../../../components/button/Button'

const PastBookingSearchForm = props => {
    const { handleSubmit, isAdmin } = props

    const [branches,populateBranch] = useState([])

    useEffect(() => {
      if(props.branchList){
        let branchList = [{"title": "All","display": "All branches"},{"title": "Online","display": "Online purchase"}]

        props.branchList.forEach((branch)=>{
          branchList.push({
            "title": branch.id,
            "display": branch.name
          })
        })
        populateBranch(branchList)
      }
    }, [props.branchList]) // Only re-run the effect if count changes
      
    return (
      <Card>
        <CardBody>
          <div className = "card__title">
            <h5 className = "bold-text">Filter by Date of First Payment</h5>
            <p />
            <p />
            <form className = "form"
              onSubmit = { handleSubmit }
            >
              <div className = "form__form-group">
                <div className = "form__form-group-field">
                  <Field
                    name = "interval_date"
                    component = { renderIntervalDatePickerField }
                  />
                </div>
                {isAdmin &&
                <div className = "form__form-group-field"
                  style = { {display: 'block'} }
                >
                  <Field
                    name = "branch"
                    component = { (field)=>
                      <Picker
                        { ...field.input }
                        data = { branches }
                        placeholder = 'Filter by branch'
                      />
                  }
                  />
                </div>
              }
              </div>
              <Button type = "submit">Filter</Button>
            </form>
          </div>
        </CardBody>
      </Card>
    )
}



export default reduxForm({
    form: 'date_picker_form', // a unique identifier for this form
  })(withTranslation('common')(PastBookingSearchForm)) 