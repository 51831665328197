import React, { Component } from 'react'
import { Fragment } from 'react'
import { Form, Field } from 'react-final-form'
import { errors } from '../../../../helpers/constants'
import {
  Card, CardBody, Col, Row
} from 'reactstrap'
import { Input } from '@paywithsplit/split-react-component'
import Button from '../../../../components/button/Button'
import { connect } from 'react-redux'
import { loadRedemptionCode } from '../../../../redux/reducers/drifftReducer'


function SearchRedemptionCode(props) {
  const { setTheResult } = props
  
  const onSubmit = async (values) => { // submit form handling function required by react-final-form
    const response = await props.loadRedemptionCode(values)

    if(response === undefined){
      alert("Code doesn't exist")
    } else {setTheResult(response)}
  }

  const validate = (values) => {
    let errors = {}

    if(!values.redemptionCode) {
      errors.redemptionCode = "Please enter redemption code"
    }

    return errors
  }

    return (
      <Fragment>
        <Form
          onSubmit = { onSubmit }
          validate = { validate }
          render = { ({ handleSubmit }) => ( // this handleSubmit is from react-final-form
            <Row>
              <form style = { { width: '100%', display: 'flex', flexWrap: 'wrap' } }
                onSubmit = { handleSubmit }
              >
                <Col md = { 12 }
                  lg = { 7 }
                >
                  <Card className = "grid">
                    <CardBody>
                      <div style = { { textTransform: 'none' } }>
                        <h3 className = "bold-text">Redemption codes</h3>
                        <Card>
                          <CardBody style = { { padding: '0px' } }>
                            <p />
                            <p />
                            <Field name = "redemptionCode">
                              {({ input, meta }) => (
                                <Input
                                  label = "Please enter your redemption code"
                                  type = "text"
                                  { ...input }
                                  error = {
                                    (meta.error || (meta.submitError && this.state.edited)) &&
                                      meta.touched
                                      ? meta.error || meta.submitError
                                      : null
                                  }
                                />
                              )}
                            </Field>
                            <Button type = "submit">Search voucher</Button>
                          </CardBody>
                        </Card>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </form>
            </Row >
          ) }
        />
      </Fragment>
    )
}

const mapDispatchToProps = {
  loadRedemptionCode: loadRedemptionCode
}

export default connect(null, mapDispatchToProps)(SearchRedemptionCode)