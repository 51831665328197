import React from 'react'
import Button from '../../../../components/button/Button'
import PhoneInput from '../../../../components/phoneInput/PhoneInput'
import TextBox from '../../../../components/textBox/TextBox'
import { connect } from 'react-redux'
import { checkNewPhone, validationNewPhone,logout } from '../../../../redux/reducers/customerReducer'
import ButtonLoader from '../../../../components/buttonLoader/ButtonLoader'
import { errors } from '../../../../helpers/constants.js'
import { Link } from 'react-router-dom'
import phone from 'phone'
import { correctMalaysiaAreaCode } from '../../../../helpers/utils'

String.prototype.paddingLeft = function (paddingValue) {
  return String(paddingValue + this).slice(-paddingValue.length)
}

@connect(
  store => ({
    customer: store.customer
  }),
  dispatch => ({
    checkNewPhone: (phoneNumber,clientuid,sendCall) => dispatch(checkNewPhone(phoneNumber,clientuid,sendCall)),
    validationNewPhone: (id, phoneValidationCode) => dispatch(validationNewPhone(id, phoneValidationCode)),
    logout: ()=>dispatch(logout())
  })
)

class AddNewNumber extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      otp: '',
      error: '',
      seconds: 0,
      showOTPError: false,
      OTPCount: 0,
      countryCode: 'sg',
      otpVerified: false,
      enabledPhoneEdit: true
    }

    this.interval = null

    this.countdownTimer = null
  
    this.midPointTimeout = null
  }

  async componentDidMount() {
      if(this.props.customer.userInfo){
        if (this.props.customer.userInfo.defaultClient.currencyId == 'MYR') {
            this.setState({ countryCode: 'my' })
          }
          else if (this.props.customer.userInfo.defaultClient.currencyId == 'BND') {
            this.setState({ countryCode: 'bn' })
          }    
      }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.countdownTimer)
    clearTimeout(this.midPointTimeout)
    this.setState({ seconds: 0 })
  }

  sendOTPtoRegisteredNumber = async (sendCall = false) => {
    if (this.state.phone) {
      let phoneCheck = phone('+' + this.state.phone)

      if (phoneCheck.length == 0) {
        this.setState({phoneError: errors.PHONE_INVALID})
      }
      else if (this.state.phone.length < 10) {
        this.setState({
          phoneError: errors.PHONE_INVALID
        })
      }else{
        this.setState({ error: '' })
        let number = this.state.phone

        if (this.state.country.countryCode == 'my') {
          number = correctMalaysiaAreaCode(number)
        }
        await this.props.checkNewPhone(number, this.props.customer.userInfo.defaultClient.UID, sendCall)

        this.setState({
          otpError: this.props.customer.error ? this.props.customer.error.message : "",
          phoneMessage: "",
          otp: this.props.customer.phoneValidationCode || null,
          otpVerified: this.props.customer.phoneValidationCode ? true : false,
          enabledPhoneEdit: false,
          isCounting: true,
        },
          () => {
            this.setState({ countdownTimer: 60 })
            this.countdownTimer = setInterval(() => {
              if (this.state.countdownTimer === 0) {
                clearInterval(this.countdownTimer)
              } else {
                this.setState({ countdownTimer: this.state.countdownTimer - 1 })
              }
            }, 1000)
            //interval for 60s timer
            this.interval = setTimeout(() => {
              clearTimeout(this.interval)
              if (!this.state.otpVerified) {
                this.setState({enabledPhoneEdit: true,phoneMessage: "Didn't get your OTP? Please enter your phone number again."})
              }
              this.setState({ isCounting: false, midPointCheck: false, otpError: "" })
            }
              , 60 * 1000)
            // interval for 30s timer prompt
            this.midPointTimeout = setTimeout(() => {
              clearTimeout(this.midPointTimeout)
              this.setState({ midPointCheck: true })
            }, 30 * 1000)
          }
        )
        //calling verifyOTP if in dev mode
        if (this.props.customer.phoneValidationCode) this.verifyOTP((this.props.customer.phoneValidationCode).toString())
      }
    }
  }

  async verifyOTP(otp) {
    this.setState({
      otpError: ''
    })
    //validation for OTP length
    if (otp && otp.length != 5) {
      this.setState({ otpError: errors.OTP_LENGTH_INVALID })
    } else {
      await this.props.validationNewPhone(this.props.customer.userInfo.defaultClient.UID, parseInt(otp))
      if (this.props.customer.error) {
        this.setState({ otpError: errors.OTP_INCORRECT })
      } else {
        this.setState({ otpVerified: true })
      }

    }
  }

  onPhoneChange = (phoneNumber,country) =>{
    if(this.state.phoneError){
      this.setState({phoneError: ''})
    }
    this.setState({
      phone: phoneNumber, country: country})
  }

  onNextClick = async () => {
    this.setState({showSuccessPage: true})
  }

  async onFieldChange(evt) {
    evt.persist()
    this.setState({
      [evt.target.name]: evt.target.value
    })
    if (evt.target.name === 'otp') {
      if (evt.target.value && evt.target.value.length === 5) {
        await this.verifyOTP(evt.target.value)
      }
    }
  }

  renderButtons = () => {
    if (this.state.otpVerified) {
      return (
        <Button control
          onClick = { this.onNextClick }
          type = 'submit'
        >
          Continue
        </Button>
      )
    }
    if (!this.state.isCounting) {
      return (
        <React.Fragment>
          <Button control
            onClick = { ()=>this.sendOTPtoRegisteredNumber() }
          >
            Send OTP
          </Button>
          <Button control
            white
            onClick = { () => this.sendOTPtoRegisteredNumber(true) }
          >
            Get OTP via Call
          </Button>
        </React.Fragment>

      )
    } else if (this.state.isCounting) {
      let textFormatter = () => {
        let min = parseInt(this.state.countdownTimer / 60).toString().paddingLeft("00")
        let sec = this.state.countdownTimer - min * 60

        sec = sec.toString().paddingLeft("00")
        return (min + ':' + sec)
      }

      return (
        <ButtonLoader seconds = { 60 }
          text = { textFormatter() }
        />
      )
    }
  }

  render() {
    if(this.state.showSuccessPage){
      return(
        <div className = "account">
          <div className = "account__wrapper">

            <div className = "account__card">
              <div className = "account__head"
                style = { { marginBottom: "10px" } }
              >
                <h3 className = "account__title">Success!</h3>
              </div>
              <h5 className = "account_sub"
                style = { { marginBottom: "30px" } }
              >Your Phone number has been successfully changed! You will need to log into the dashboard again to see the changes.</h5>
              <div style = { { display: "flex", marginTop: "1.4rem" } }>
                <Link to = '/'
                  onClick = { ()=>{this.props.logout()} }
                >
                  <Button >Log out</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

      )
    }else{
      return (
        <div className = "account">
          <div className = "account__wrapper">
            <div className = "account__card">
              <h5 className = "account_sub"
                style = { { marginBottom: "30px" } }
              >This one-time verification is to keep your account secure.</h5>
              <div style = { { display: "flex", marginTop: "1.4rem", alignItems: 'center' } }>
                <div style = { { display: "flex", justifyContent: 'center', flexDirection: "column", width: "100%" } } >
                  <div>
                    <PhoneInput
                      label = 'Phone'
                      country = { this.state.countryCode }
                      value = { this.state.phone }
                      onChange = { (phone, country) => {this.onPhoneChange(phone,country)} }
                      name = { 'phone' }
                      preferredCountries = { ['sg', 'my', 'bn'] }
                      placeholder = { 'Country code & phone no.' }
                      message = { this.state.phoneMessage }
                      disabled = { !this.state.enabledPhoneEdit }
                      error = { this.state.phoneError }
                    />
                  </div>
                </div>
              </div>
              {!this.state.enabledPhoneEdit && <TextBox
                label = "OTP"
                placeholder = { "xxxxx" }
                onChange = { this.onFieldChange.bind(this) }
                name = { 'otp' }
                type = 'number'
                value = { this.state.otp }
                message = { this.state.midPointCheck && "If you still haven't received your OTP, just get a quick OTP via phone call. It's super fast." }
                error = { this.state.otpError }
                success = { this.state.otpVerified && ' ' }
                disabled = { this.state.otpVerified }
                                               />
                      }            
              <div style = { { display: "flex", marginTop: "1.4rem" } }>
                {this.renderButtons()}
              </div>
            </div>
          </div>
        </div>
      )  
    }
  }
}
export default AddNewNumber