import React from "react"
import cn from 'classnames'
import styles from './styles.module.css'

export default (props) => {
  let { roboto, rubik, big, med, sm, dull, bold, classNames, green, grey, block, medSm, truncated, marginBottom,nunito, ...other } = props

  if (!med && !big && !medSm) sm = true

  const classes = cn({
    [styles.fontRoboto]: roboto,
    [styles.fontRubik]: rubik,
    [styles.fontNunito]: nunito,
    [styles.sizeSm]: sm,
    [styles.sizeBig]: big,
    [styles.sizeMed]: med,
    [styles.dull]: dull,
    [styles.bold]: bold,
    [styles.green]: green,
    [styles.grey]: grey,
    [styles.block]: block,
    [styles.sizeMedSm]: medSm,
    [styles.truncated]: truncated,
    [styles.marginBottom]: marginBottom,
    ...classNames
  })

  return <span { ...other }
    className = { classes }
         />
}