import React from "react"
import { CardCvcElement, CardNumberElement, CardExpiryElement,FpxBankElement } from '@stripe/react-stripe-js'
import {style} from './stripeStyle'
import styles from './styles.module.css'
import classNames from 'classnames'
import './stripe.css'
export const StripeCardCvcElement = (props) => {
    let { className, error, success,message, ...other} = props

    if(success){
      message = success
    } else if (error){
      message = error
    }
    return (
      <div className = { classNames([styles.textBoxWrapper,className]) }>
        <label className = { classNames([styles.textBoxLabel,className]) }>CVC</label>
        <div style = { {position: 'relative'} }>
          <CardCvcElement { ...other }
            options = { {style: style} }
          />
        </div>
        <label className = { classNames([styles.textBoxMessage,error && styles.error, success && styles.success,className]) }>{message}</label>
      </div>
  
    )
}  
export const StripeCardNumberElement = (props) => {
    let { className, error, success,message, ...other} = props

    if(success){
      message = success
    } else if (error){
      message = error
    }
    return (
      <div className = { classNames([styles.textBoxWrapper,className]) }>
        <label className = { classNames([styles.textBoxLabel,className]) }>Card number</label>
        <div style = { {position: 'relative'} }>
          <CardNumberElement { ...other }
            options = { {style: style} }
          />
        </div>
        <label className = { classNames([styles.textBoxMessage,error && styles.error, success && styles.success,className]) }>{message}</label>
      </div>
  
    )
}
  export const StripeCardExpiryElement = (props) => {
    let { className, error, success,message, ...other} = props

    if(success){
      message = success
    } else if (error){
      message = error
    }
    return (
      <div className = { classNames([styles.textBoxWrapper,className]) }>
        <label className = { classNames([styles.textBoxLabel,className]) }>Expiry date</label>
        <div style = { {position: 'relative'} }>
          <CardExpiryElement { ...other }
            options = { {style: style} }
          />
        </div>
        <label className = { classNames([styles.textBoxMessage,error && styles.error, success && styles.success,className]) }>{message}</label>
      </div>
  
    )
}

export const StripeFpxElement = (props) => {
  let { className, error, success,message, ...other} = props

  if(success){
    message = success
  } else if (error){
    message = error
  }
  return(
    <div className = { classNames([styles.textBoxWrapper,className]) }>
      <label className = { classNames([styles.textBoxLabel,className]) }>FPX Bank</label>
      <FpxBankElement { ...other }
        options = { {accountHolderType: 'individual'} }
      />
      <label className = { classNames([styles.textBoxMessage,error && styles.error, success && styles.success,className]) }>{message}</label>
    </div>
  )
}
