import React from 'react'
import {
    Card, CardBody
} from 'reactstrap'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'
import { merchantRole } from '../../../../helpers/constants'

const TABLE_COLLUMN_BY_ROLE = {
    "ADMIN": [
            {
                label: 'Currency',
                field: 'currency',
                sort: 'asc'
            },
            {
                label: 'Number of orders',
                field: 'total',
                sort: 'asc'
            },
            {
                label: 'Total order value',
                field: 'price',
                sort: 'asc'
            },
            {
                label: 'Total MDR',
                field: 'MDR',
                sort: 'asc'
            }
    ],
    "OUTLET": [
        {
            label: 'Currency',
            field: 'currency',
            sort: 'asc'
        },
        {
            label: 'Number of orders',
            field: 'total',
            sort: 'asc'
        },
        {
            label: 'Total order value',
            field: 'price',
            sort: 'asc'
        }
    ]
}

class SummaryTable extends React.Component {
    constructor(props){
        super(props)
        let role = merchantRole.OUTLET

        if(this.props.isAdmin){
            role = "ADMIN"
        }

        this.state = { columns: [...TABLE_COLLUMN_BY_ROLE[role]]}
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            rows: nextProps.summaryTable
        })
    }

    render() {
        return(
          <Card>
            <CardBody>
              <div className = "card__title">
                <h5 className = "bold-text">Summary</h5>
              </div>
              <MDBTable>

                <MDBTableHead columns = { this.state.columns } />
                <MDBTableBody rows = { this.state.rows } />
              </MDBTable>

            </CardBody>    
          </Card>
        )
    }
}

export default SummaryTable
