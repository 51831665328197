import React from 'react'
import styles from './bubbles.module.css'
export default props => {
  const {color} = props

  return (
    <div className = { styles.spinner }>
      <div style = { {backgroundColor: color} }
        className = { styles.bounce1 }
      />
      <div style = { {backgroundColor: color} }
        className = { styles.bounce2 }
      />
      <div style = { {backgroundColor: color} }
        className = { styles.bounce3 }
      />
    </div>
  )
}