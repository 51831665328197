/* eslint-disable */ 
import React, { PureComponent,createRef } from 'react';
import {
  Card, CardBody, Col, Row
} from 'reactstrap';
import { Form, Field } from 'react-final-form'
import { RadioButtonGroup, TextArea, Input, InputFrontAdornment } from '@paywithsplit/split-react-component'
import PropTypes from 'prop-types';
import { FullWideNotification } from '../../../../shared/components/Notification';
import { connect } from "react-redux";
import { bookingType, errors } from '../../../../helpers/constants'
import QRCode from 'qrcode'
import CircleJaffa from '../../../../shared/img/logo/Circle_Jaffa.svg'
import Button from '../../../../components/button/Button'
import { resetError } from '../../../../redux/reducers/drifftReducer'

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    resetError: () => dispatch(resetError())
  })
)

class HorizontalFormOrder extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      price: 0,
      ticketDate: '',
      showTicketDateError: false,
      showEarlyReturnError: false,
      deposit: 0,
      dragging: false,
      showDescriptionError: false,
      showOrderIDError:false, // backend error
      showOrderIDMessage: 'Order ID should not include a hash',
      isFullPayment: false,
      canvasDrawn:false,
      // selectedPaymentMethodOption: 'Instalment', // default value for payment method radio button
      // selectedShippingOption: 'noAddress', // default value for shipping address option (when link type is multiple)
      // selectedExpiryOption: 'noExpiry',
      // selectedStockOption:'noStock',
      hasExpiryDate: false,
      hasLimitStock: false,
      collectShippingAddress: false,
    }

    this.dragging = false
    this.downloadQR= this.downloadQR.bind(this)
    this.canvasRef = createRef(null)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.setDragFalse);
    document.addEventListener('mousemove', this.setDragTrue);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.setDragFalse);
    document.removeEventListener('mousemove', this.setDragTrue);
  }
  drawSplitLogo = (canvas) => {
    
    const img = new Image()
    img.src = CircleJaffa
    let ctx = canvas.getContext('2d');
    //smoothen canvas for scaling
    ctx.mozImageSmoothingEnabled = true;
    ctx.webkitImageSmoothingEnabled = true;
    ctx.msImageSmoothingEnabled = true;
    ctx.imageSmoothingEnabled = true;
    // drawing Split logo onto canvas
    let QRsize = 165
    let imageSize = 40
    let offSet = (QRsize-imageSize)/2

    img.onload = () => {
      ctx.drawImage(img, offSet, offSet, imageSize, imageSize)

      this.setState({
        canvasDrawn: true
      })
    }
  }
  async componentDidUpdate(){
    if(this.props.drifft.newLink && this.props.drifft.newLink.drifftSubUrl && !this.state.canvasDrawn){
      let fullLink = this.props.drifft.v2FELink + this.props.drifft.newLink.drifftSubUrl
      let toCanvas = await QRCode.toCanvas(canvas,fullLink)
      if(toCanvas){
        this.drawSplitLogo(toCanvas)
      }
    }
  }
  downloadQR=()=>{
    if(this.props.drifft.newLink && this.props.drifft.newLink.drifftSubUrl && this.state.canvasDrawn){
      //get current canvas
      const canvas = this.canvasRef.current
      //create new canvas
      var canvasUpscaled = document.createElement('canvas');
      let dimension = 1000
      canvasUpscaled.width = dimension
      canvasUpscaled.height = dimension     
      let ctx = canvasUpscaled.getContext('2d')
      //do not smoothen canvas for scaling (makes image blurry)   
      ctx.mozImageSmoothingEnabled = false;
      ctx.webkitImageSmoothingEnabled = false;
      ctx.msImageSmoothingEnabled = false;
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(canvas,0,0,canvas.width,canvas.height,0,0,dimension,dimension)
      //draw enlarged Split logo over new canvas
      const img = new Image();
      img.src = CircleJaffa
      img.onload = ()=>{
        let imageSize = 280
        let offSet = (dimension-imageSize)/2
        ctx.drawImage(img,offSet,offSet,imageSize,imageSize)
        //download QR code
        const a = document.createElement('a');
        a.href = canvasUpscaled.toDataURL();
        a.download = 'QRCode.png';
        a.click()  
      }
    }

  }
  // Functions so we can register event listeners properly
  setDragFalse = () => {
    this.dragging = false
  }
  setDragTrue = () => {
    this.dragging = true
  }
  showSuccess = (notificationMessage) => {
    const { showNotification } = this.props;
    return showNotification(
      <FullWideNotification
        color="success"
        message={notificationMessage}
      />
    )
  };
  onClickGeneratedLink = (e) => {
    if (this.props.drifft.newLink != null && !this.dragging) {
      var temp = document.createElement("textarea");
      document.body.appendChild(temp);
      temp.value = document.getElementById("link").textContent
      temp.select();
      document.execCommand('copy')
      document.body.removeChild(temp)
      this.showSuccess("Copied Link!")
    }
  }
  emailClick = (e) => {
    if (this.props.drifft.newLink != null) {
      //Build the data to pass to MS server
      let payload = {
        travelAgent: {
          name: this.props.drifft.travelAgent.name,
          minInstallmentsMonth: this.props.drifft.travelAgent.minInstallmentsMonth,
          maxInstallmentsMonth: this.props.drifft.travelAgent.maxInstallmentsMonth,
          extraCharge: this.props.drifft.travelAgent.extraCharge,
          linkTTL: this.props.drifft.travelAgent.linkTTL
        },
        ticketAttribute: this.props.drifft.newLink.drifftMapLink.ticketAttribute,
        paymentLink: this.props.drifft.v2FELink + this.props.drifft.newLink.drifftSubUrl,
        minInstallmentPerMonthAmount: ((this.state.price * (1 + (this.props.drifft.travelAgent.extraCharge / 100))) / this.props.drifft.travelAgent.maxInstallmentsMonth).toFixed(2),
        totalTripCost: this.state.price,
        TAType: bookingType.ORDER,
        isFullPayment:this.state.isFullPayment
      }
      this.props.onEmailClick(payload)
      this.showSuccess("Email Sent!")
    }
  }
  openClick = (e) => {
    if (this.props.drifft.newLink != null) {
      let fullLink = this.props.drifft.v2FELink + this.props.drifft.newLink.drifftSubUrl
      window.open(fullLink)
    }
  }

  installmentList = () => {
    return (
      <div style={{
        borderRadius: '3.2px',
        backgroundColor: '#fff',
        margin: '.7rem 0',
        padding: '.7rem',
        border: '1.6px solid #000'
      }}>
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <h4 >For {this.props.drifft.travelAgent.maxInstallmentsMonth} Months</h4>
          <h4>{this.state.currency} {((this.state.price * (1 + (this.props.drifft.travelAgent.extraCharge / 100))) / this.props.drifft.travelAgent.maxInstallmentsMonth).toFixed(2)}</h4>
        </div>
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <h4 className="subhead" >{this.props.drifft.travelAgent.extraCharge}% Service charge</h4>
          <h4 className="subhead">{this.state.currency} {(this.state.price * (1 + (this.props.drifft.travelAgent.extraCharge / 100))).toFixed(2)} / Total</h4>
        </div>
      </div>
    )
  }

  onSubmit = async (data) => { // submit form handling function required by react-final-form
    // data.preventDefault()
    if (!this.state.showEarlyReturnError
      && !this.state.showTicketDateError
      && !this.state.showDescriptionError 
      && !this.state.showOrderIDError) {
        await this.props.handleSubmit(data) // handleSubmit provided in index.jsx (showResults)
        if (this.props.drifft.error == errors.EXTERNAL_ID_ERROR_DISPLAY) {
          await this.setState({
            showOrderIDError: true,
            showOrderIDMessage: errors.EXTERNAL_ID_ERROR_DISPLAY
          })
          this.props.resetError()
        }
        this.setState({canvasDrawn:false})
    }
  }

  validate = (values) => { // validate function required by react-final-form
    const errors = {};
    // PRICE VALIDATION: type is number so no need to check if user key in alphabet
    if (!values.price) {
      errors.price = 'Price field shouldn’t be empty or contain alphabetical letter';
    } else if (this.props.drifft.travelAgent.currencyId === 'SGD' && values.price < 5) {
      errors.price = "Price shouldn't be negative or lower than SGD 5.00"
    } else if (this.props.drifft.travelAgent.currencyId === 'MYR' && values.price < 15) {
      errors.price = "Price shouldn't be negative or lower than MYR 15.00"
    } 
    else {
      this.setState({price: values.price}) // update state to reflect changes on the RHS preview card and for generating drifft link
    }

    // ITEM TITLE VALIDATION: title should be shorter than 300 chars and shouldn't be empty
    if (!values.itemTitle) {
      errors.itemTitle = "Item title field shouldn't be empty"
    } else if (values.itemTitle.length > 300) {
      errors.itemTitle = "Item title field is too long"
    }
    else {
      this.setState({itemTitle: values.itemTitle})
    }

    // DESCRIPTION VALIDATION: desc should be shorter than 300 chars and shouldn't be empty
    if (!values.itemDescription) {
      errors.itemDescription = "Item description field shouldn't be empty"
    } else if (values.itemDescription.length > 300) {
      errors.itemDescription = "Item description field is too long"
    }
    else {
      this.setState({itemDescription: values.itemDescription})
    }

    // ORDER ID VALIDATION: Order ID shouldn't include #
    if (values.externalOrderId && values.externalOrderId.includes("#")) {
      errors.externalOrderId = 'Order ID should not include a hash'
    }
    else {
      this.setState({externalOrderId: values.externalOrderId})
    }

    // SET STATE FOR RADIO BUTTON FIELDS (isFullPayment, expiry, collectShippingAddress, stock)
    // if (values.isFullPayment === "Instalment") {
    //   this.setState({isFullPayment: false})
    // } else if (values.isFullPayment === "Full payment") {
    //   this.setState({isFullPayment: true})
    // }

    // if (values.hasExpiryDate === "yesExpiry") {
    //   this.setState({hasExpiryDate: true})
    // } else if (values.hasExpiryDate === "noExpiry") {
    //   this.setState({hasExpiryDate: false})
    // }

    // if (values.collectShippingAddress === "yesAddress") {
    //   this.setState({collectShippingAddress: true})
    // } else if (values.collectShippingAddress === "noAddress") {
    //   this.setState({collectShippingAddress: false})
    // }

    // if (values.hasLimitStock === "yesStock") {
    //   this.setState({hasLimitStock: true})
    // } else if (values.hasLimitStock === "noStock") {
    //   this.setState({hasLimitStock: false})
    // }
    
    // UPDATE STATE FOR CURRENCY
    this.setState({ currency: this.props.drifft.travelAgent.currencyId })


    // EXPIRY DATE VALIDATION (cannot be a date in the past)
    if(values.hasExpiryDate === 'yesExpiry' && !values.expiredAt){
      errors.expiredAt = "Please set an expiry date."
    }
    if (values.hasExpiryDate === 'yesExpiry' && values.expiredAt < new Date().toISOString().split("T")[0]) {
      errors.expiredAt = "Expiry date cannot be a date in the past"
    }

    // MAX PURCHASE COUNT (number of uses cannot be less than 2)
    if(values.hasLimitStock === 'yesStock' && values.maxPurchaseCount == null){
      errors.maxPurchaseCount = "This cannot be left empty."
    }
    
    if (values.hasLimitStock === 'yesStock' && values.maxPurchaseCount < 2) {
      errors.maxPurchaseCount = "This needs to be more than one."
    }
    return errors;
  };



  render() {
    return (
        <Form 
          onSubmit={this.onSubmit}
          validate={this.validate}
          render={({handleSubmit, reset}) => ( // this handleSubmit is from react-final-form
            <Row>
            <form 
              style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }} 
              onSubmit={event => {
                handleSubmit(event).then(reset);
              }}> 
              <Col md={12} lg={7}>
                <Card className="grid">
                  <CardBody> 
                    <div className="card__title" style={{ textTransform: 'none' }}>
                      <h3 className="bold-text">New Multi-use Payment Link</h3>
                      <span>* indicates required fields</span>
                      <Card>
                        <CardBody style={{ padding: '0px' }}>
                          <p></p>
                          <p></p>
                          <Field name="price">
                            {({ input, meta }) => (
                              <InputFrontAdornment
                                label="Price *"
                                type='number'
                                frontAdornment={this.props.drifft.travelAgent.currencyId}
                                placeholder='0.00'
                                id='currency'
                                step=".01"
                                value={this.state.price}
                                {...input}
                                error={
                                  (meta.error || (meta.submitError && this.state.edited)) &&
                                  meta.touched
                                    ? meta.error || meta.submitError
                                    : null
                                }
                                success={
                                  !meta.error && !meta.submitError && meta.touched ? " " : null
                                }
                              />
                            )}
                          </Field>
                          <Field name="externalOrderId">
                            {({ input, meta }) => (
                              <Input
                                label="Order ID"
                                type="text"
                                placeholder="Will be auto generated if it's empty"
                                id='externalOrderId'
                                {...input}
                                error={
                                  (meta.error || (meta.submitError && this.state.edited)) &&
                                  meta.touched
                                    ? meta.error || meta.submitError
                                    : null
                                }
                                success={
                                  !meta.error && !meta.submitError && meta.touched ? " " : null
                                }
                              />
                            )}
                          </Field>

                          <Field name="itemTitle">
                            {({ input, meta }) => (
                              <Input
                                label="Item Title *"
                                type="text"
                                placeholder="Please fill in title of item"
                                id='itemTitle'
                                {...input}
                                error={
                                  (meta.error || (meta.submitError && this.state.edited)) &&
                                  meta.touched
                                    ? meta.error || meta.submitError
                                    : null
                                }
                                success={
                                  !meta.error && !meta.submitError && meta.touched ? " " : null
                                }
                              />
                            )}
                          </Field>

                          <Field name="itemDescription">
                            {({ input, meta }) => (
                              <TextArea
                                label="Item Description *"
                                type="text"
                                placeholder="Please fill in the order description"
                                id='itemDescription'
                                {...input}
                                error={
                                  (meta.error || (meta.submitError && this.state.edited)) &&
                                  meta.touched
                                    ? meta.error || meta.submitError
                                    : null
                                }
                                success={
                                  !meta.error && !meta.submitError && meta.touched ? " " : null
                                }
                              />
                            )}
                          </Field>

                          <Field name="collectShippingAddress" initialValue="noAddress">
                                {({ input, meta }) => (
                                  <RadioButtonGroup
                                    label="Collect shipping address"
                                    value="noAddress"
                                    options={[
                                      { "value": 'yesAddress', "title": "Yes" }, 
                                      { "value": 'noAddress', "title": "No"}
                                    ]} 
                                    id='collectShippingAddress'
                                    {...input}
                                    onSelect={(event) => {
                                      input.onChange(event.target.value)
                                    }}
                                  />
                                )}
                            </Field>
                          
                          {this.props.drifft && this.props.drifft.travelAgent.allowFullPayment && 
                          <Field name="isFullPayment" initialValue="Instalment">
                            {({ input, meta }) => (
                              <RadioButtonGroup
                                label="Order Type"
                                value="Instalment" // this alone doesn't work inside react-final-form Field :< so i need to use initialValue
                                options={[
                                  { "value": "Instalment", "title": "Instalment" }, 
                                  { "value": "Full payment", "title": "Full Payment"}
                                ]} 
                                id='isFullPayment'
                                {...input}
                                onSelect={(event) => {
                                  input.onChange(event.target.value)
                                }}
                                error={
                                  (meta.error || (meta.submitError && this.state.edited)) &&
                                  meta.touched
                                    ? meta.error || meta.submitError
                                    : null
                                }
                                success={
                                  !meta.error && !meta.submitError && meta.touched ? " " : null
                                }
                              />
                            )}
                          </Field> }  

                      <Field name="hasExpiryDate" initialValue="noExpiry">
                            {({ input, meta }) => (
                              <RadioButtonGroup
                                label="Does this have an expiry date?"
                                value="noExpiry"
                                options={[
                                  { "value": 'yesExpiry', "title": "Yes" },                                  
                                  { "value": 'noExpiry', "title": "No"}
                                ]} 
                                id='hasExpiryDate'
                                {...input}
                                onSelect={(event) => {
                                  input.onChange(event.target.value)
                                }}
                              />
                            )}
                          </Field>

                          <Condition when="hasExpiryDate" is="yesExpiry">
                          <Card style={{ backgroundColor: '#f2f4f7', padding: '10px'}}>
                            <Field name="expiredAt" >
                            {({ input, meta }) => (
                              <Input
                                label="Set payment link expiry date"
                                type="date"
                                min={new Date().toISOString().split("T")[0]}
                                id='expiredAt'
                                {...input}
                                error={
                                  (meta.error || (meta.submitError && this.state.edited)) &&
                                  meta.touched
                                    ? meta.error || meta.submitError
                                    : null
                                }
                                success={
                                  !meta.error && !meta.submitError && meta.touched ? " " : null
                                }
                              />
                            )}
                          </Field>
                          </Card>
                        </Condition>
                                             
                       <Field name="hasLimitStock" initialValue="noStock">
                            {({ input, meta }) => (
                              <RadioButtonGroup
                                label="Set max number of uses"
                                value="noStock"
                                min="2"
                                options={[
                                  { "value": 'yesStock', "title": "Yes" },                                  
                                  { "value": 'noStock', "title": "No"}
                                ]} 
                                id='hasLimitStock'
                                {...input}
                                onSelect={(event) => {
                                  input.onChange(event.target.value)
                                }}
                              />
                            )}
                          </Field>
                          <Condition when="hasLimitStock" is="yesStock">
                            <Card style={{ backgroundColor: '#f2f4f7', padding: '10px'}}>

                              <Field name="maxPurchaseCount" initialValue="2">
                                  {({ input, meta }) => (
                                    <Input
                                      label="Number of Uses"
                                      type="number"
                                      min='2'
                                      id='maxPurchaseCount'
                                      {...input}
                                      error={
                                        (meta.error || (meta.submitError && this.state.edited)) &&
                                        meta.touched
                                          ? meta.error || meta.submitError
                                          : null
                                      }
                                      success={
                                        !meta.error && !meta.submitError && meta.touched ? " " : null
                                      }
                                    />
                                  )}
                              </Field>
                            </Card>
                          </Condition>
                        </CardBody>
                      </Card>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md={12} lg={5} style={{ backgroundColor: '#f2f4f7', paddingRight: '15px', paddingTop: '30px' }}>
                <Card className="grid">
                  <CardBody>
                    <div style={{ textTransform: 'none' }}>
                      <h3 className="bold-text">Total Price</h3>

                    </div>
                    <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                      <h4 >Price</h4>

                      <h4 style={{ paddingRight: '12px' }}>{this.state.currency} {this.state.price}</h4>
                    </div>
                    {this.installmentList()}
                    <div >
                      <div style={{ textTransform: 'none', marginTop: '36px' }}>
                        <h3 className="bold-text">Payment Link</h3>
                      </div>
                      <div style={{
                        backgroundColor: '#fff',
                        margin: '.7rem 0',
                        padding: '.7rem',
                        border: '1.6px solid #000',
                        boxSizing: 'border-box',
                        borderRadius: '3.2px',
                        display: this.props.drifft.newLink == null ? 'none' : 'block'
                      }} 
                      onClick={this.onClickGeneratedLink}
                      >
                        <h4 id="link" style={{ textAlign: "center" }}>{this.props.drifft.newLink ? this.props.drifft.v2FELink + this.props.drifft.newLink.drifftSubUrl : 'Please Generate Link'}</h4>
                      </div>
                      <div style={{marginBottom:'10px'}}>
                        <Button type="submit" controlAll >
                          Generate New Link
                        </Button>
                      </div>
                      <div style={{marginBottom:'10px'}}>
                        <Button white type="button" onClick={this.openClick} controlAll >
                          Open Link
                        </Button>
                      </div>
                      <div style = {{textAlign:'center',visibility:this.state.canvasDrawn?'visible':'hidden'}}>
                        <canvas id='canvas' ref={this.canvasRef} />
                      </div>
                      {this.state.canvasDrawn && 
                          <div style={{textAlign:'center'}}>
                          <Button white onClick={this.downloadQR} >
                            Download QR Code
                          </Button>
                          </div>
                        }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </form>
          </Row >
        )}
      />
    );
  }
}
export default HorizontalFormOrder;

