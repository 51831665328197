import React, { Fragment } from 'react'
import Container from 'reactstrap/lib/Container'
import SearchRedemptionCode from './components/SearchRedemptionCode'
import RedemptionCodesTable from './components/RedemptionCodesTable'
import { useState } from 'react'

function RedemptionCodePage(){

  const [redemptionResult, setRedemptionResult] = useState([{}])

  function setTheResult(redemptionResult) {
    setRedemptionResult(redemptionResult)
  }

    return (
      <Fragment>
        <Container className = "invoice">
          <SearchRedemptionCode setTheResult = { setTheResult } />
          <RedemptionCodesTable redemptionResult = { redemptionResult } />
        </Container>
      </Fragment>
    )

}

export default RedemptionCodePage