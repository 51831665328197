import React from 'react'
import { Table } from 'reactstrap'
import Button from '../../../../components/button/Button'
import { connect } from "react-redux"
import Panel from '../../../../shared/components/Panel'
import { getInstalments } from '../../../../redux/reducers/customerReducer'
import { Link } from 'react-router-dom'
import './index.css'
@connect(
  store => ({
    customer: store.customer
  }),
  dispatch => ({
    getInstalments: (clients) => dispatch(getInstalments(clients))
  })
)

class InstalmentWidget extends React.Component {
  constructor(props) {
    super(props)
  }

  async componentDidMount() {
    if (this.props.customer.userInfo){
      await this.props.getInstalments(this.props.customer.userInfo.clients)
    }
  }

  async componentDidUpdate() {
    if (this.props.customer.userInfo && !this.props.customer.getInstalmentsSuccess) {
      await this.props.getInstalments(this.props.customer.userInfo.clients)
    }
  }

  renderData(key) {
    return this.props.customer.getInstalmentsSuccess[key].map((instalment, index) => {
      const { shopName, orderId, internalId, nextPaymentDate, amountDue } = instalment

      return (<tr key = { orderId + index }
        style = { { alignContent: "center" } }
              >
        <td>{shopName}</td>
        <td>{orderId}</td>
        <td>{nextPaymentDate}</td>
        <td>{amountDue}</td>
        <td>
          <Link to = { "/customer/paynow/" + internalId }>
            <Button className = 'paynow'>Pay off early</Button>
          </Link>
        </td>
      </tr>)
    })
  }

  render() {
    if (!this.props.customer.getInstalmentsSuccess) {
      return (
        <Panel lg = { 8 }
          title = { "Upcoming payments" }
          largeFont
          noMinus = 'true'
        >
          <div style = { { display: 'block', margin: 'auto', width: '1%', marginTop: '3rem', marginBottom: '3rem' } }>
            <div className = "load__icon-wrap">
              <svg className = "load__icon">
                <path fill = "#ff8600"
                  d = "M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                />
              </svg>
            </div>
          </div>

        </Panel>
      )
    }
    else if (this.props.customer.getInstalmentsSuccess['paidInstalments'].length == 0 && this.props.customer.getInstalmentsSuccess[['unpaidInstalments']].length == 0) {
      return (
        <Panel lg = { 8 }
          title = { "Upcoming payments" }
          largeFont
          noMinus = 'true'
        >
          <div> Looks like you have no active purchases at the moment, <span
            style = { { textDecoration: 'underline', cursor: 'pointer' } }
            onClick = { () => { window.open('https://paywithsplit.co/where-to-shop-all-featured/') } }
                                                                       >click here</span> to find out where you can shop with Split.</div>
        </Panel>
      )
    }
    else {
      return (
        <Panel lg = { 8 }
          title = { "Upcoming payments" }
          largeFont
          noMinus = 'true'
        >
          <h5 style = { { fontWeight: 'bold' } }>This Month:</h5>
          <div className = "month-bar" />
          {this.props.customer.getInstalmentsSuccess['paidInstalments'].length != 0 ?
            <Table responsive
              style = { {} }
            >
              <thead>
                <tr>
                  <th>Store</th>
                  <th>Order ID</th>
                  <th>Date</th>
                  <th>Amount due</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {this.renderData('paidInstalments')}
              </tbody>
            </Table>
            :
            <div style = { { marginTop: '1rem', marginBottom: '1rem' } }> Yay! 🥳 you have no pending instalments this month!</div>
          }

          {this.props.customer.getInstalmentsSuccess['unpaidInstalments'].length != 0 ?
            <div>
              <h5 style = { { fontWeight: 'bold' } }>Next Month:</h5>
              <div className = "month-bar" />
              <Table responsive>
                <thead>
                  <tr>
                    <th>Store</th>
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Amount due</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderData('unpaidInstalments')}
                </tbody>

              </Table>
            </div>
            : <div style = { { height: '3rem' } } />
          }
        </Panel>
      )
    }
  }
}
export default InstalmentWidget