import { reset } from 'redux-form'
import ApiClient from '../../helpers/ApiClient'
import StorageService from "../../services/StorageServcies"
import { MSApiClient } from '../../helpers/ApiClient'
import { SYSTEM_MODE } from '../../config'
import { errors } from '../../helpers/constants'
import moment from 'moment'

const initialState = {
  jwt: null,
  loggedIn: false,
  hasJWT: false,
  travelAgentUser: {
    permission: {
      viewRefund: false,
      viewNewLink: true,
      viewNewOrder: true,
      viewNewItem: true,
      viewDeposit: false
    }
  },
  travelAgent: {

  },
  pastbooking: {
    rows: []
  },
  createdItems: {
    rows: []
  },
  branches: {
    rows: []
  },
  MDR: 0,
  v2FELink: 'https://app.paywithsplit.co',
  newLink: null,
  recentOrders: null,
  rankingOrders: null,
  rankingCustomers: null,
  latePayments: null,
  salesByLocation: null,
  productSales: null,
  summaryTable: {
    rows: []
  },
  currency: null,
  currencies: [],
  error: null,
  loginErrorMessage: null,
  redemptionData: null
}

export default function DrifftReducer(state = initialState, action) {
  switch (action.type) {
    case "LOG_IN": {
      return {
        ...state,
        loggedIn: true,
        currency: action.currency,
        ...action.data,
      }
    }
    case "LOG_IN_ERROR": {
      return {
        ...state,
        loginErrorMessage: action.message
      }
    }

    case "LOG_OUT": {
      return initialState

    }

    case 'DONE_TOGGLE_ITEM_STATUS': {
      return {
        ...state
      }
    }

    case 'DONE_HIDE_ITEM': {
      return {
        ...state
      }
    }

    case "LOAD_PASTBOOKING": {
      return {
        ...state,
        pastbooking: action.parsedDataTable,
        summaryTable: action.summaryTable,
      }
    }

    case "LOAD_REDEMPTIONCODE": {
      return {
        ...state,
        redemptionData: action.data
      }
    }

    case "UPDATE_REDEMPTIONCODE": {
      return {
        ...state,
        redemptionData: action.data
      }
    }

    case "LOAD_CREATED_ITEMS": {
      return {
        ...state,
        createdItems: action.parsedDataTable
      }
    }

    case "LOAD_BRANCHES_DATA": {
      return {
        ...state,
        branches: action.parsedDataTable
      }
    }

    case "CREATE_DRIFFT_LINK": {
      return {
        ...state,
        newLink: action.data
      }
    }

    case "CREATE_DRIFFT_ITEM": {
      return {
        ...state,
        newLink: action.data
      }
    }

    case "SET_V2FELINK": {
      return {
        ...state,
        v2FELink: action.v2FELink
      }

    }

    case "RECENT_ORDERS":{
      return {
        ...state,
        recentOrders: action.data
      }
    }

    case "RANKING_ORDERS":{
      return {
        ...state,
        rankingOrders: action.data
      }
    }

    case "RANKING_CUSTOMERS":{
      return {
        ...state,
        rankingCustomers: action.data
      }
    }

    case "LATE_PAYMENTS":{
      return {
        ...state,
        latePayments: action.parsed_data
      }
    }

    case "SALES_BY_LOCATION":{
      return {
        ...state,
        salesByLocation: action.processed_data
      }
    }

    case "PRODUCT_SALES":{
      return{
        ...state,
        productSales: action.parsed_data
      }
    }

    case "CHANGE_CURRENCY":{
      return{
        ...state,
        currency: action.currency
      }
    }

    case "GET_CURRENCIES":{
      return{
        ...state,
        currencies: action.results,
      }
    }

    case "CHANGE_PASSWORD":{
      return{
        ...state,
        changePassword: action.success
      }
    }

    case "ERROR":{
      return{
        ...state,
        error: action.error
      }
    }

    case "CLEAR_ERROR": {
      return{
        ...state,
        error: null
      }
    }

    case "CLEAR_LINK": {
      return {
        ...state,
        newLink: null
      }
    }

    case "HAS_JWT": {
      return {
        ...state,
        hasJWT: action.hasJWT
      }
    }

    case "GET_BRANCHES": {
      return {
        ...state,
        branchList: action.results
      }
    }

    case "MERCHANT_DOESNT_EXIST" : {
      return {
        ...state,
        existingMerchant: action.existingMerchant
      }
    }
    default:
      return state
  }
}

export const login = (email, password) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setV2FELink())
      //Do API call to login and setup the JWT token
      let data = await ApiClient.post('/merchant/login', { email, password })
      const permissions = getState().drifft.travelAgentUser.permission

      for (var key in data.travelAgentUser.permission) {
        if(key === 'viewOrder'){
          permissions['viewNewOrder'] = data.travelAgentUser.permission[key]
        } else {
          permissions[key] = data.travelAgentUser.permission[key]
        }
      }
      data.travelAgentUser.permission = permissions
      ApiClient.setToken(data.token)
      StorageService.set('client_JWT', data)
      let currency = data.travelAgent.currencyId

      dispatch({
        type: 'LOG_IN',
        data,
        currency
      })
      //reset login redux-form data
      dispatch(reset('log_in_form'))
    }
    catch (e) {
      //TODO add to sentry 
      dispatch({
        type: 'LOG_IN_ERROR',
        message: e.message
      })
    }

  }
}

export const setV2FELink = () => {

  return (dispatch) => {
    //console.log(process.env.REACT_APP_MODE)
    //set the v2FELink base on REACT_APP_MODE
    let v2FELink

    if (process.env.REACT_APP_MODE == 'DEV') {
      v2FELink = "https://dev.app.paywithsplit.co"
    } else if (process.env.REACT_APP_MODE == 'STAGE') {
      v2FELink = "https://sandbox.paywithsplit.co"
    } else if (process.env.REACT_APP_MODE == 'DEMO') {
      v2FELink = "https://demo.paywithsplit.co"
    } else {
      v2FELink = 'https://app.paywithsplit.co'
    }
    dispatch({
      type: 'SET_V2FELINK',
      v2FELink
    })
  }
}

export const getJWTToken = (token) => {
  return async (dispatch) => {
    let isToken = await ApiClient.get('/webhooks/get-jwt-exists',{token})

    dispatch({
      type: 'HAS_JWT', 
      hasJWT: isToken.jwtExists
    })
  }
}

export const returnUser = (data) => {
  return (dispatch) => {
    ApiClient.setToken(data.token)
    let currency = data.travelAgent.currencyId

    dispatch(setV2FELink())
    dispatch({
      type: 'LOG_IN',
      data,
      currency

    })
  }
}

// TODO: Improve, In the fetch API should include certain details of customer (Ic or Phone Number)
export const loadRedemptionCode = (redemptionData) => {
  return async (dispatch) => {
    try {
      let data = await ApiClient.get(`/merchant/sngl/${redemptionData.redemptionCode}/redemption`)

      // Filter some properties
      delete data.updatedAt
      delete data.order
      delete data.id
      delete data.meta
      delete data.archive
      delete data.email
      delete data.merchantId
      delete data.name //order name
      delete data.orderId
      delete data.similarClientErrors
      delete data.securedNumber
      delete data.savingsPlanCashback
      delete data.price
      delete data.isBanned

      data.totalAmount = parseFloat(data.totalAmount).toFixed(2)

      dispatch({
        type: 'LOAD_REDEMPTIONCODE',
        data
      })

      return [data]
    } catch (e) {
      checkStatusCode(e)
    }
  }
}

export const RedeemCode = (redemptionCode) => {
  return async (dispatch) => {
    try {
      const isRedeem = true

      await ApiClient.patch(`/merchant/sngl/${redemptionCode}/redemption`, {isRedeem})
      let data = await ApiClient.get(`/merchant/sngl/${redemptionCode}/redemption`)     


      // Filter some properties
      delete data.updatedAt
      delete data.order
      delete data.id
      delete data.meta
      delete data.archive
      delete data.email
      delete data.merchantId
      delete data.name //order name
      delete data.orderId
      delete data.similarClientErrors
      delete data.securedNumber
      delete data.savingsPlanCashback
      delete data.price
      delete data.isBanned

      data.totalAmount = parseFloat(data.totalAmount).toFixed(2)

      dispatch({
        type: 'UPDATE_REDEMPTIONCODE',
        data
      })

      return [data]
    } catch (e) {
      checkStatusCode(e)
    }
  }
}

export const loadPastBookings = (startDate, endDate,branch) => {
  return async (dispatch) => {

    try {
      let data = await ApiClient.get('/drifft/getTicket', { startDate, endDate, branch})
      let summaryData = []

      data.forEach((val, index) => {
        //stuff for summaryData here
        let seen = false

        for (let i = 0; i < summaryData.length; i++) {
          if(summaryData[i].currency == data[index].currency){
            seen = true
            summaryData[i].total = summaryData[i].total + 1
            summaryData[i].price = (parseFloat(summaryData[i].price) + parseFloat(data[index].price)).toFixed(2)
            summaryData[i].MDR = (parseFloat(summaryData[i].MDR) + parseFloat(data[index].MDRFee)).toFixed(2)
            break
          }
        }

        if(!seen){
          summaryData.push({currency: data[index].currency,total: 1 ,price: data[index].price,MDR: data[index].MDRFee})
        }

      })
  
      let parsedDataTable = {
        rows: data
      }
    
      let summaryTable = {
        rows: summaryData
      }

      dispatch({
        type: 'LOAD_PASTBOOKING',
        parsedDataTable,
        summaryTable
      })
    } catch (e) {
      checkStatusCode(e)
    }
  }
}

export const loadCreatedItems = () => {
  return async (dispatch, getState) => {

    try {
      let data = await ApiClient.get('/drifft/allMultiDrifftByTA')
      let relevantData = []
      const currentMoment = moment()

      data.forEach((val, index) => {

        relevantData[index] = {}
        relevantData[index].title = data[index].ticketAttribute.itemTitle ? data[index].ticketAttribute.itemTitle : " "
        relevantData[index].description = data[index].ticketAttribute.itemDescription ? data[index].ticketAttribute.itemDescription : " "
        relevantData[index].price = data[index].ticketAttribute.price
        relevantData[index].link = getState().drifft.v2FELink + "/token?token=" + data[index].token
        relevantData[index].usesLeft = data[index].maxPurchaseCount ? data[index].maxPurchaseCount : "∞"

        let expiredMoment = null

        if (data[index].expiredAt){
          expiredMoment = moment(data[index].expiredAt)
        }

        relevantData[index].expiresAt = expiredMoment ? expiredMoment.format('DD/MM/YYYY') : "Never"

        if (expiredMoment && expiredMoment <= currentMoment) {
          relevantData[index].status = "Expired"
        } else if (data[index].active) {
          relevantData[index].status = "Active"
        } else {
          relevantData[index].status = "Not Active"
        }
        relevantData[index].paymentType = data[index].isFullPayment ? 'Full Payment' : 'Instalment'
      })
  
      let parsedDataTable = {
        rows: relevantData
      }

      dispatch({
        type: 'LOAD_CREATED_ITEMS',
        parsedDataTable
      })
    } catch (e) {
      checkStatusCode(e)
    }
  }
}

export const loadBranchesData = () => {
  return async (dispatch) => {

    try {
      let data = await ApiClient.get('/drifft/allTABranches')
   
      let relevantData = []

      data.forEach((val,index) => {
        relevantData[index] = {}
        relevantData[index].name = data[index].name
        relevantData[index].id = data[index].id
      })
      let parsedDataTable = {
        rows: relevantData
      }

      dispatch({
        type: 'LOAD_BRANCHES_DATA',
        parsedDataTable
      })
    } catch (e) {
      checkStatusCode(e)
    }
  }
}

export const toggleDrifftItemStatus = (token) => {
  return async (dispatch) => {
    try {
      await ApiClient.post('/drifft/toggleItemStatus', {token})
      dispatch({
        type: 'DONE_TOGGLE_ITEM_STATUS'
      })
    } catch (e) {
      checkStatusCode(e)
    }
  }
}

export const hideDrifftItem = (token) => {
  return async (dispatch) => {
    try {
      await ApiClient.post('/drifft/hideItem', {token})
      dispatch({
        type: 'DONE_HIDE_ITEM'
      })
    } catch (e) {
      checkStatusCode(e)
    }
  }
}

export const logout = () => {
  return async (dispatch) => {
    StorageService.remove('client_JWT')
    dispatch({
      type: 'LOG_OUT'
    })

  }
}

export const createDrifftLink = (dataForm, multiLink, linkType) => {
  return async (dispatch,getState) => {
    try {
      let postData = { ...dataForm }

      if(postData.currency.value == null){
        postData.currency = getState().drifft.travelAgent.currencyId
      }else{
        postData.currency = dataForm.currency.value
      }
      postData.platformType = 0
      postData.isFullPayment = (postData.isFullPayment === "Instalment" || !postData.isFullPayment) ? false : true

      //process data
      //console.log(postData)
      //if bookID is emmpty make it equal to PLZGN8EDITH
      if (linkType == 1) {
        if (!postData.externalTicketId) {
          postData.externalTicketId = 'PLZGN8EDITH'
        }

      }

      if (linkType == 0) {
        if (!postData.externalOrderId) {
          postData.externalOrderId = 'PLZGN8EDITH'
        }

      }
      const data = await ApiClient.post('/drifft', { 
        ticketAttribute: { ...postData }, 
        multiLink, 
        isFullPayment: postData.isFullPayment })

      dispatch({
        type: 'CREATE_DRIFFT_LINK',
        data
      })
      return "success"
      //reset  redux-form data
      //dispatch(reset('horizontal_form'))
    }
    catch (e) {
      if (e.message == errors.EXTERNAL_ID_ERROR) {
        dispatch({
          type: 'ERROR',
          error: errors.EXTERNAL_ID_ERROR_DISPLAY
        })
        return e.message
      }
      checkStatusCode(e)
    }

  }
}

export const createDrifftItem = (dataForm, multiLink) => {
  return async (dispatch) => {
    try {
      
      const jwt = StorageService.get("client_JWT")
      const TAType = jwt.travelAgent.type

      if (TAType == 0) {
        dataForm.externalOrderId = dataForm.orderId ? dataForm.orderId : 'PLZGN8EDITH'
      } else if (TAType == 1) {
        dataForm.externalTicketId = dataForm.orderId ? dataForm.orderId : 'PLZGN8EDITH'
      }

      dataForm.currency = jwt.travelAgent.currencyId

      let postData = { ...dataForm }
      
      postData.platformType = 0
      postData.isFullPayment = (postData.isFullPayment === "Instalment" || !postData.isFullPayment) ? false : true


      const data = await ApiClient.post('/drifft', { 
        ticketAttribute: { ...postData }, 
        multiLink,
        isFullPayment: postData.isFullPayment,
        collectShippingAddress: postData.collectShippingAddress
      })

      dispatch({
        type: 'CREATE_DRIFFT_ITEM',
        data
      })
    }
    catch (e) {
      checkStatusCode(e)
    }
  }
}

export const sendDriffEmail = (payload) => {
  return async () => {
    try {
      MSApiClient.setToken('2a7e8b291d234afed9ecdf7c6fe0be26')

      if (SYSTEM_MODE == "DEV" || SYSTEM_MODE == "STAGE") {
        await MSApiClient.post('/ta/test/drifftPaymentRequest', payload)
      } else {
        await MSApiClient.post('/ta/drifftPaymentRequest', payload)
      }
    }
    catch (e) {
      checkStatusCode(e)
    }
  }
}

export const recentOrders = (currency) => {
  return async(dispatch) =>{
    const data = await ApiClient.get('/drifft/recentOrders',{currency})

    dispatch({
      type: 'RECENT_ORDERS',
      data
    })
  }
}

export const rankingOrders = (currency) => {
  return async(dispatch) =>{
    const data = await ApiClient.get('/drifft/rankingOrders',{currency})

    dispatch({
      type: 'RANKING_ORDERS',
      data
    })
  }
}

export const rankingCustomers = (currency) => {
  return async(dispatch) =>{
    const data = await ApiClient.get('/drifft/rankingCustomers',{currency})

    dispatch({
      type: 'RANKING_CUSTOMERS',
      data
    })
  }
}

export const latePayments = (todayDate,currency) => {
  return async(dispatch) =>{
    const data = await ApiClient.get('/drifft/findLatePayments',{todayDate,currency})
    var parsed_data = {thisMonth: 0,lastMonth: 0,trend: null}
    const thisMonth = parseInt(moment(new Date()).format('M'))

    for (let index = 0; index < data.length; index++) {
      const row = data[index]

      for (const key in row) {
        if (row.hasOwnProperty(key)) {
          const createdAt = parseInt(moment.unix(row[key]).format('M'))

          if (thisMonth === createdAt){
            parsed_data.thisMonth = parsed_data.thisMonth + 1
          }
          else if(thisMonth - createdAt === 1 || (thisMonth - createdAt === -11)){
            parsed_data.lastMonth = parsed_data.lastMonth + 1
          }
        }
      }
    }
    if(parsed_data.thisMonth - parsed_data.lastMonth <= 0){
      parsed_data.trend = "good"
    } else parsed_data.trend = "bad"    
    
    dispatch({
      type: 'LATE_PAYMENTS',
      parsed_data
    })
  }
}

export const salesByLocation = (currency) => {
  return async(dispatch) =>{
    const data = await ApiClient.get('/drifft/SalesByLocation',{currency})
    const fill = ['#70bbfd','#f6da6e','#ff4861','#0FA3B1','#B38CB4']
    var processed_data = []
    var others = {name: "others", value: 0, fill: fill[fill.length - 1]}

    for (let index = 0; index < data.length; index++) {
      const row = data[index]
      var newrow = {name: null,value: null,fill: null}

      if(row.city != null && index < 4 && row.city != ""){
        newrow.name = row.city
        newrow.value = parseInt(row.count)
        newrow.fill = fill[index]
        processed_data.push(newrow)
      }
      else{
        others.value = others.value + parseInt(row.count)
      }
    }
    processed_data.push(others)
    dispatch({
      type: 'SALES_BY_LOCATION',
      processed_data
    })
  }
}

export const productSales = (selectedDate,todayDate,utcOffset,currency) => {
  return async(dispatch) =>{
    selectedDate = moment(selectedDate).toLocaleString()
    todayDate = moment(todayDate).toLocaleString()
    const data = await ApiClient.get('/drifft/getProductSales',{selectedDate,todayDate,currency})
    var parsed_data = [[],[]]
    const data_range = ["0000","0100","0200","0300","0400","0500","0600","0700","0800","0900","1000","1100","1200","1300","1400","1500","1600","1700","1800","1900","2000","2100","2200","2300"]
    
    var todayData = data[0]
    var selectedData = data[1]
    var currentSum = 0
    var selectedSum = 0
    var currentTransaction = 0
    var selectedTransaction = 0
    var isTimeOver = false
    var timeNow = moment().format("HH00")

    for (let index = 0; index < data_range.length; index++) {
      //if time is not over, we check for today's revenue
      if(!isTimeOver){
        for (let i = 0; i < todayData.length; i++) {
          let selectedRow = todayData[i].date_trunc

          if(selectedRow === data_range[index]){
            currentSum = (parseFloat(currentSum) + todayData[i].revenue).toFixed(2)
            currentTransaction += parseInt(todayData[i].transactions)
            todayData.splice(i,1)
            break
          }
        }
      }

    for (let i = 0; i < selectedData.length; i++) {
      let selectedRow = selectedData[i].date_trunc

      if(selectedRow === data_range[index]){
        selectedSum = (parseFloat(selectedSum) + selectedData[i].revenue).toFixed(2)
        selectedTransaction += parseInt(selectedData[i].transactions)
        selectedData.splice(i,1)
        break
      }
    }
    let sum = null
    let transactions = null

    if(isTimeOver){
      sum = {name: data_range[index], selected: parseFloat(selectedSum)}
      transactions = {name: data_range[index], selected: selectedTransaction}
    }else{
      sum = {name: data_range[index], current: parseFloat(currentSum), selected: parseFloat(selectedSum)}
      transactions = {name: data_range[index],current: currentTransaction, selected: selectedTransaction}
    }
    parsed_data[0].push(sum)
    parsed_data[1].push(transactions)

    if(timeNow === data_range[index]){
      isTimeOver = true
    }

  }
    dispatch({
      type: 'PRODUCT_SALES',
      parsed_data
    })
  }
}

const checkStatusCode = (e) => {

  return async () => {
    //check if statusCode is 401
    if (e.statusCode == 401) {
      logout()
    }
  }
}

export const changeCurrency = (currency) =>{
  return (dispatch)=>{
    return dispatch({
      type: 'CHANGE_CURRENCY',
      currency
    })
  }
}

export const getCurrencies = () =>{
  return async (dispatch,getState) =>{
    let results = await ApiClient.get('/drifft/getCurrencies')

    if(results.length === 0) {
      results = [getState().drifft.travelAgent.currencyId]
    }
    return dispatch({
      type: 'GET_CURRENCIES',
      results
    })
  }
}

export const changeExistingPassword = (password,currentPassword) =>{
  return async(dispatch) =>{
    try{
      dispatch({type: "CLEAR_ERROR"})
      const results = await ApiClient.post('/drifft/changeExistingPassword',{password,currentPassword})

      if (results.success){
        return dispatch({
          type: 'CHANGE_PASSWORD',
          success: results.success
        })
      }else{
        return dispatch({
          type: 'ERROR',
          error: 'failed to change password'
        })
      }
    }catch(e){
      return dispatch({
        type: 'ERROR',
        error: e.message
      })
    }
  }
}

export const loadBranches = () => {
  return async (dispatch) =>{
    try{
      const results = await ApiClient.get('/drifft/allTABranches')

      return dispatch({
        type: "GET_BRANCHES",
        results
      })
    }catch(e){
      return dispatch({
        type: 'ERROR',
        error: e.message
      })
    }
  }
}

export const clearLink = () =>{
  return {
    type: 'CLEAR_LINK'
  }
}

export const merchantForgotPassword = (email) => { 
  return async (dispatch) => {
    try{
      // Do API call to check if client exists in database.
      await ApiClient.post('/merchant/forgotPassword', {email}) 
      return dispatch({
        type: "RESET_SUCCESS"
      })
    }
    catch (e){
      if (e.message == 'Failed to fetch') {
        return dispatch({
          type: 'ERROR',
          error: e.message
        })
      } else {
        return dispatch({
          type: "RESET_SUCCESS"
        })
      }
    }
  }
}

export const merchantResetPasswordValid = (resetToken, email) => {
  return async (dispatch) => { 
    try {
      let data = await ApiClient.post('/merchant/checkReset', {resetToken, email})

      if (data.error) {
        return dispatch({
          type: 'ERROR',
          error: data.error
        })
      } else if (data.valid) {
        dispatch({
          type: "RESET_SUCCESS"
        })
        return data
      } else {
        return dispatch({
          type: 'ERROR',
          error: "An unknown error has occured"
        })
      }
    } catch (e) {
      return dispatch({
        type: 'ERROR',
        error: e.message
      })
    }
  }
}

export const merchantResetPasswordRequest = (resetToken, email, password) => {
  return async (dispatch) => { 
    try {
      let data = await ApiClient.post('/merchant/setPassword', {resetToken, email, password})

      if (data.passwordSet) {
        return data
      } else {
        return dispatch({
          type: 'ERROR',
          error: "An unknown error has occured"
        })
      }
    } catch (e) {
      return dispatch({
        type: 'ERROR',
        error: e.message
      })
    }
  }
}

export const resetError = () => {
  return async (dispatch) => {
    return dispatch({
      type: 'CLEAR_ERROR'
    })
  }
}