import React from 'react'
import { connect } from 'react-redux'
import { CardNumberElement, ElementsConsumer } from '@stripe/react-stripe-js'
import { createSetupIntent, storeCreditCard, creditCardCreate } from '../../../../redux/reducers/customerReducer'
import { Link } from 'react-router-dom'
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'
import {stripePubkeyMY,stripePubkeySG} from '../../../../config'
import Button from '../../../../components/button/Button'
import StripeForm from '../StripeForm'
import Spin from '../../../../components/loaders/Spin'

@connect(
  store => ({
    customer: store.customer
  }),
  dispatch => ({
    createSetupIntent: (force3DS) => dispatch(createSetupIntent(force3DS)),
    storeCreditCard: (paymentMethodId) => dispatch(storeCreditCard(paymentMethodId)),
    creditCardCreate: (input) => dispatch(creditCardCreate(input))
  })
)
class AddNewCard extends React.Component {
  constructor(props) {
    let options = [{ value: 'MYR', label: 'Malaysia' }, { value: 'SGD', label: "Singapore" }]

    super(props)
    this.state = {
      card: "",
      lastCard: "",
      cvv: "",
      expiry: "",
      name: "",
      errorMessage: null,
      error: false,
      addingCard: false,
      spin: true,
      count: 0,
      cardOnFile: false,
      isDisable: true,
      options: options,
      success: false,
      isLoaded: null
    }
    this.onFieldChange = this.onFieldChange.bind(this)
    this.onNextClick = this.onNextClick.bind(this)
  }

  static getDerivedStateFromProps(props,state){
    if(!state.isLoaded && props.customer.userInfo){
      if(props.customer.userInfo.defaultPaymentGateway.name === 'SG STRIPE'){
        props.setStripePromise(loadStripe(stripePubkeySG))
      }else{
        props.setStripePromise(loadStripe(stripePubkeyMY))
      }
      return {
        ...state,
        isLoaded: true
      }  
    }else{
      return state
    }
  }

  showDisplay(show = true){
    let x = document.getElementById("showDisplay")

    if(!show){
      x.style.visibility = "hidden"
      this.setState({ spin: true })
    }else{
      x.style.visibility = "visible"
      this.setState({ spin: false })
    }
  }

  onFieldChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value.toUpperCase()
    })
  }

  async onNextClick(values) {
      try {
        this.setState({loading: true})
        let cardElement = this.props.elements.getElement(CardNumberElement)

        await this.props.createSetupIntent(true)
        const { setupIntent, error } = await this.props.stripe.confirmCardSetup(this.props.customer.setupIntent.clientSecret,
          {
            payment_method: {
              card: cardElement
              , billing_details: {
                name: values.nameOnCard
              }
            }
          }
        )

        if (setupIntent) {
          //return payment method id instead.
          await this.props.creditCardCreate({
            clientUID: this.props.customer.userInfo.defaultPaymentGateway.clientUID,
            nameOnCard: values.nameOnCard,
            paymentMethodId: setupIntent.payment_method,
            paymentGatewayId: this.props.customer.userInfo.defaultPaymentGateway.id
          })
          this.setState({ success: true })
        }
        else if (error) {
          // bring to card error page
          this.props.storeCreditCard(error.payment_method.id)
          this.setState({ error: true })
        }
        else {
          this.setState({ error: true })
        }

      } catch (e) {
        this.setState({ error: true })
      }
  }

  reactComponentReady =()=> {
    this.setState({
      count: this.state.count + 1
    })
    if (this.state.count === 3) {
      this.showDisplay(true)
      //reload iframe for stripe, to fix iOS bug
      let el = document.querySelector('.StripeElement')

      if (el) {
        let initialDisplay = el.style.display

        el.style.display = 'none'
        el.offsetHeight
        el.style.display = initialDisplay
      }

    }

  }

  render() {
    if (this.state.success === false && this.state.error === false) {
      return (
        <div className = "account">
          <div className = "account__wrapper">
            <div className = "account__card"
              style = { { maxWidth: "1920px" } }
            >
              <div className = "account__head"
                style = { { marginBottom: "10px" } }
              >
                <h3 className = "account__title">Enter your card details</h3>
              </div>
              <h5 className = "account_sub"
                style = { { marginBottom: "30px", lineHeight: '1.8' } }
              >
                This is the card we will charge for your instalments
              </h5>
              {this.state.spin && <Spin />}
              <div id = "showDisplay"
                style = { { visibility: 'hidden' } }
              >
                <StripeForm
                  returningUser = { false }
                  onNextClick = { this.onNextClick }
                  reactComponentReady = { this.reactComponentReady }
                  loading = { this.state.loading }
                />
              </div>
            </div>
          </div>
        </div>
      )
    } else if (this.state.error === true) {
      return (
        <div className = "account">
          <div className = "account__wrapper">
            <div className = "account__card"
              style = { { maxWidth: "1920px" } }
            >
              <div className = "account__head"
                style = { { marginBottom: "10px" } }
              >
                <h3 className = "account__title">Sorry, your card couldn't be added</h3>
              </div>
              <h5 className = "account_sub"
                style = { { marginBottom: "30px", lineHeight: '1.8' } }
              >
                This could be because there’s a problem with the bank, or the card you entered might have been wrong.
                <br /> Please try another card instead.                </h5>
              <div style = { { marginTop: '50px' } }>
                <Button onClick = { () => { window.location.reload() } }
                  color = "primary"
                >Retry</Button>
              </div>
            </div>
          </div>
        </div>

      )
    } else if (this.state.success === true) {
      return (
        <div className = "account">
          <div className = "account__wrapper">

            <div className = "account__card">
              <div className = "account__head"
                style = { { marginBottom: "10px" } }
              >
                <h3 className = "account__title">Success!</h3>
              </div>
              <h5 className = "account_sub"
                style = { { marginBottom: "30px" } }
              >Your card has successfully been updated! Please click the button below to return to the dashboard.</h5>
              <div style = { { display: "flex", marginTop: "1.4rem" } }>
                <Link to = '/customer/dashboard'
                  onClick = { async () => { await new Promise(r => setTimeout(r, 1000)); window.location.reload() } }
                >
                  <Button color = "primary">Return to dashboard</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

      )
    }
  }

}
export default AddNewCard


export class InjectedAddNewCard extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      stripePromise: null
    }
    this.setStripePromise = this.setStripePromise.bind(this)
  }

  setStripePromise(stripePromise){
    this.setState({stripePromise: stripePromise})
  }

  render(){
    return (
      <Elements stripe = { this.state.stripePromise }>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <AddNewCard elements = { elements }
              stripe = { stripe }
              setStripePromise = { this.setStripePromise }
            />
        )}
        </ElementsConsumer>
      </Elements>
    )  
  }
}


