import React, { Component } from 'react'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'
import {
  Card, CardBody, Col, Row, Container
} from 'reactstrap'
import Button from '../../../components/button/Button'
import { connect } from "react-redux"
import { loadBranchesData, toggleDrifftItemStatus } from '../../../redux/reducers/drifftReducer'
import QRCode from 'qrcode'
import CircleJaffa from '../../../shared/img/logo/Circle_Jaffa.svg'
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import { pdfBaseImage,syariahPdfBaseImage } from '../../../helpers/constants'
pdfMake.vfs = pdfFonts.pdfMake.vfs
@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    loadBranchesData: () => dispatch(loadBranchesData()),
    toggleDrifftItemStatus: (token) => dispatch(toggleDrifftItemStatus(token))

  })
)

class ItemDatatablePage extends Component {
  constructor(props) {
    super(props)


    this.state = {
      data: {
        columns: this.getColumns(),
        rows: this.props.drifft.createdItems.rows
      }
    }
  }

  componentDidMount() {
    this.props.loadBranchesData()

  }

  componentWillReceiveProps = (nextProps) => {

    let filteredRows = []

    for (let i = 0; i < nextProps.drifft.branches.rows.length; i++) {

      nextProps.drifft.branches.rows[i].download = {}
      // TODO: Change this so it doesn't create a new function in onclick
      nextProps.drifft.branches.rows[i].download =
        <Button color = "primary"
          size = "sm"
          onClick = { () => this.drawSplitLogoDownload(nextProps.drifft.branches.rows[i].id, nextProps.drifft.branches.rows[i].name) }
        >
          Download
        </Button>
      let temp = { ...nextProps.drifft.branches.rows[i] }

      delete temp.id
      filteredRows.push(temp)
    }
    this.setState({
      data: {
        columns: this.getColumns(),
        rows: filteredRows
      }
    })

  }


  drawSplitLogoDownload = async (id,name) => {
    var canvasHtml = document.createElement('canvas')
    let canvas = await QRCode.toCanvas(canvasHtml, encodeURI(`${this.props.drifft.v2FELink}/qr?taBranchId=${id}`))

    //create new canvas
    var canvasUpscaled = document.createElement('canvas')
    let dimension = 1000

    canvasUpscaled.width = dimension
    canvasUpscaled.height = dimension
    let ctx = canvasUpscaled.getContext('2d')

    //smoothen canvas for scaling
    ctx.mozImageSmoothingEnabled = false
    ctx.webkitImageSmoothingEnabled = false
    ctx.msImageSmoothingEnabled = false
    ctx.imageSmoothingEnabled = false
    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, dimension, dimension)


    const img = new Image()

    img.src = CircleJaffa

    img.onload = () => {
      let imageSize = 280
      let offSet = (dimension - imageSize) / 2

      ctx.drawImage(img, offSet, offSet, imageSize, imageSize)
      //download QR code
      const a = document.createElement('a')
      let baseImageToUse = syariahPdfBaseImage
      if(!this.props.drifft.travelAgent.isSyariah){
        baseImageToUse = pdfBaseImage
      }
      a.href = canvasUpscaled.toDataURL()
      var dd = {
        pageSize: 'A5',
        content: [

          {
            image: baseImageToUse,
            width: 420
          }
          , {
            image: canvasUpscaled.toDataURL(),
            fit: [155, 155],
            absolutePosition: { x: 28, y: 220 }
          },
          {
            columns: [

              {
                width: 150,
                text: this.truncate(`${this.props.drifft.travelAgent.name} (${name})`, 23),
                alignment: 'left',
                absolutePosition: { x: 42, y: 365 }
              },

            ]


          }

        ]

        ,
        pageMargins: [0, 0, 0, 0]
      }

      pdfMake.createPdf(dd).download(`${this.props.drifft.travelAgent.name} (${name})`)

    }
  }

  truncate = function (fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr

    separator = separator || '...'

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2)

    return fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
  }

  getColumns = () => {
    let columns = [
      {
        label: 'Name',
        field: 'name',
        width: 270
      },

      {
        label: 'Download QR Code',
        field: 'download',
      }
    ]

    return columns
  }

  render() {
    return (
      <Container className = "invoice" >
        <Row>
          <Col md = { 12 }>
            <Card>
              <CardBody className = "grid">
                <MDBTable responsive>
                  <MDBTableHead columns = { this.state.data.columns } />
                  <MDBTableBody rows = { this.state.data.rows } />
                </MDBTable>
              </CardBody>
            </Card>

          </Col>

        </Row>
      </Container>


    )
  }
}

export default ItemDatatablePage