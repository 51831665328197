import React, { PureComponent } from 'react'
import {
  Card, CardBody, Col, Row
} from 'reactstrap'
import Button from '../../../../components/button/Button'
import { Field, reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import renderSelectField from '../../../../shared/components/form/Select'
import renderDatePickerField from '../../../../shared/components/form/DatePicker'
import renderPhoneInputField from '../../../../shared/components/form/PhoneInput'
import { FullWideNotification } from '../../../../shared/components/Notification'
import renderRadioButtonField from '../../../../shared/components/form/RadioButton'
import { connect } from "react-redux"
import validate from './validateTicket'
import moment from "moment"
import { bookingType } from '../../../../helpers/constants'

const renderField = ({
  input, placeholder, type, meta: { touched, error }, value, step
}) => (
  <div className = "form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input { ...input }
      placeholder = { placeholder }
      type = { type }
      value = { value }
      step = { step }
    />
    {touched && error && <span className = "form__form-group-error">{error}</span>}
  </div>
  )

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  })
}

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text'
}

@connect(
  store => ({
    drifft: store.drifft
  })
)
class HorizontalFormTicket extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      price: 0,
      currency: this.props.drifft.travelAgent.currencyId,
      ticketDate: '',
      showTicketDateError: false,
      showEarlyReturnError: false,
      showDOBError: false,
      deposit: 0,
      dragging: false,
      showDescriptionError: false,
      isFullPayment: false
    }

    this.dragging = false
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.setDragFalse)
    document.addEventListener('mousemove', this.setDragTrue)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.setDragFalse)
    document.removeEventListener('mousemove', this.setDragTrue)
  }

  // Functions so we can register event listeners properly
  setDragFalse = () => {
    this.dragging = false
  }

  setDragTrue = () => {
    this.dragging = true
  }

  showSuccess = (notificationMessage) => {
    const { showNotification } = this.props

    return showNotification(
      <FullWideNotification
        color = "success"
        message = { notificationMessage }
      />
    )
  };

  onClickGeneratedLink = () => {
    if (this.props.drifft.newLink != null && !this.dragging) {
      var temp = document.createElement("textarea")

      document.body.appendChild(temp)
      temp.value = document.getElementById("link").textContent
      temp.select()
      document.execCommand('copy')
      document.body.removeChild(temp)

      this.showSuccess("Copied Link!")
    }
  }

  emailClick = () => {
    if (this.props.drifft.newLink != null) {
      //Build the data to pass to MS server
      let payload = {
        travelAgent: {
          name: this.props.drifft.travelAgent.name,
          minInstallmentsMonth: this.props.drifft.travelAgent.minInstallmentsMonth,
          maxInstallmentsMonth: this.props.drifft.travelAgent.maxInstallmentsMonth,
          extraCharge: this.props.drifft.travelAgent.extraCharge
        },
        ticketAttribute: this.props.drifft.newLink.drifftMapLink.ticketAttribute,
        paymentLink: this.props.drifft.v2FELink + this.props.drifft.newLink.drifftSubUrl,
        minInstallmentPerMonthAmount: ((this.state.price * (1 + (this.props.drifft.travelAgent.extraCharge / 100))) / this.props.drifft.travelAgent.maxInstallmentsMonth).toFixed(2),
        totalTripCost: this.state.price,
        TAType: bookingType.ORDER
      }

      this.props.onEmailClick(payload)

      this.showSuccess("Email Sent!")
    }
  }

  openClick = () => {
    if (this.props.drifft.newLink != null) {
      let fullLink = this.props.drifft.v2FELink + this.props.drifft.newLink.drifftSubUrl

      window.open(fullLink)
    }
  }

  onFieldChange = (evt) => {
    if(evt === 'Instalment' || evt === 'Full payment'){
      this.setState({isFullPayment: evt === 'Instalment' ? false : true})
    }
    else if (evt.dropdown) {
      this.setState({ currency: this.props.drifft.travelAgent.currencyId })
    }else {
      if (evt.target.name == 'deposit' || evt.target.name == 'price') {
        if (evt.target.value != null) {
          if (evt.target.value <= -1) {
            evt.target.value = evt.target.value * -1
            this.setState({
              [evt.target.name]: evt.target.value
            })
          } else {
            this.setState({
              [evt.target.name]: evt.target.value
            })
          }

        }
      } else {
        this.setState({
          [evt.target.name]: evt.target.value
        })
      }

    }
  }

  installmentList = () => {
    return (
      <div style = { {
        borderRadius: '3.2px',
        backgroundColor: '#fff',
        margin: '.7rem 0',
        padding: '.7rem',
        border: '1.6px solid #000'
      } }
      >
        <div style = { { justifyContent: 'space-between', display: 'flex' } }>
          <h4 >For {this.props.drifft.travelAgent.maxInstallmentsMonth} Months</h4>
          <h4>{this.state.currency} {((this.state.price * (1 + (this.props.drifft.travelAgent.extraCharge / 100))) / this.props.drifft.travelAgent.maxInstallmentsMonth).toFixed(2)}</h4>
        </div>
        <div style = { { justifyContent: 'space-between', display: 'flex' } }>
          <h4 className = "subhead" >{this.props.drifft.travelAgent.extraCharge}% Service charge</h4>
          <h4 className = "subhead">{this.state.currency} {(this.state.price * (1 + (this.props.drifft.travelAgent.extraCharge / 100))).toFixed(2)} / Total</h4>
        </div>
      </div>
    )
  }

  customerValidation = (data) => {
    // TODO: This is a little ugly, should work with react components instead
    let ticketDate = moment(document.getElementById("ticketDate").value, "DD-MM-YYYY")
    let returnTicketDate = moment(document.getElementById("returnTicketDate").value, "DD-MM-YYYY")
    let dateOfBirth = document.getElementById("DOB").value

    let description = document.getElementById("description").value

    if (description != null) {

      if (description.length > 300) {
        this.setState({
          showDescriptionError: true
        })
      }
      else {
        this.setState({
          showDescriptionError: false
        })
      }
    }

    if (!ticketDate.isValid()) {
      this.setState({
        showTicketDateError: true
      })
    } else {
      this.setState({
        showTicketDateError: false
      })
    }

    if (returnTicketDate.isValid() && !returnTicketDate.isAfter(ticketDate)) {
      this.setState({
        showEarlyReturnError: true
      })
    } else {
      this.setState({
        showEarlyReturnError: false
      })
    }

    if (dateOfBirth == '' || moment(dateOfBirth, "DD-MM-YYYY") > moment().subtract(18, "years")) {
      this.setState({
        showDOBError: true
      })
    } else {
      this.setState({
        showDOBError: false
      })
    }

    if (!this.state.showEarlyReturnError
      && !this.state.showTicketDateError
      && !this.state.showDOBError) {
      this.props.handleSubmit(data)
    } else {
      data.preventDefault()
    }
  }

  onTicketDateChange = () => {
    this.setState({
      showTicketDateError: false
    })
  }

  onReturnDateChange = (date) => {
    // TODO: This is a little ugly, should work with react components instead
    let momentReturnDate = moment(date, "DD-MM-YYYY")
    let ticketDate = document.getElementById("ticketDate").value
    let momentTicketDate = moment(ticketDate, "DD-MM-YYYY")

    if (ticketDate != '' && date != '' && momentReturnDate >= momentTicketDate) {
      this.setState({
        showEarlyReturnError: false
      })
    } else if (momentReturnDate < momentTicketDate) {
      this.setState({
        showEarlyReturnError: true
      })
    }
  }

  onDateOfBirthChange = (date) => {
    if (moment(date, "DD-MM-YYYY") > moment().subtract(18, "years")) {
      this.setState({
        showDOBError: true
      })
    } else {
      this.setState({
        showDOBError: false
      })
    }
  }

  render() {
    return (
      <Row>
        <form className = "form form--horizontal"
          style = { { width: '100%' } }
          onSubmit = { this.customerValidation }
        >
          <Col lg = { 7 }
            md = { 12 }
          >
            <Card className = "grid">
              <CardBody>
                <div className = "card__title"
                  style = { { textTransform: 'none' } }
                >
                  <h3 className = "bold-text">Booking Details</h3>
                  <span>* indicates required fields</span>
                  <Card>
                    <CardBody style = { { padding: '0px' } }>
                      <p />
                      <p />

                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Total Price*</span>
                        <div className = "form__form-group-field">
                          <Field
                            onChange = { this.onFieldChange }
                            name = "price"
                            component = { renderField }
                            type = "number"
                            placeholder = "Total Price of the Booking"
                            value = { this.state.price }
                            step = ".01"
                          />
                        </div>
                      </div>
                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Currency*</span>
                        <div className = "form__form-group-field">
                          <Field
                            name = "currency"
                            onChange = { this.onFieldChange }
                            component = { renderSelectField }
                            placeholder = "Currency"
                            options = { [
                              { value: this.props.drifft.travelAgent.currencyId, label: this.props.drifft.travelAgent.currencyId }]
                            }
                          />
                        </div>
                      </div>
                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Booking ID</span>
                        <div className = "form__form-group-field">
                          <Field
                            name = "externalTicketId"
                            component = "input"
                            type = "text"
                            placeholder = "Will be auto generated if it's empty"

                          />
                        </div>
                      </div>
                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Start Date*</span>
                        <div className = "form__form-group-field">
                          <Field
                            name = "ticketDate"
                            placeholderText = "Start Date"
                            component = { renderDatePickerField }
                            preventRawChange
                            minDate = { new Date() }
                            onChange = { this.onTicketDateChange }
                            showDateError = { this.state.showTicketDateError }
                            clearData = { this.props.clearData }
                            id = 'ticketDate'
                          />
                        </div>
                      </div>
                      <div className = "form__form-group">
                        <span className = "form__form-group-label">End Date</span>
                        <div className = "form__form-group-field">

                          <Field
                            name = "returnTicketDate"
                            placeholderText = "End Date"
                            component = { renderDatePickerField }
                            preventRawChange
                            minDate = { new Date() }
                            onChange = { this.onReturnDateChange }
                            showEarlyReturnError = { this.state.showEarlyReturnError }
                            clearData = { this.props.clearData }
                            id = 'returnTicketDate'
                          />

                        </div>
                      </div>
                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Trip Title</span>
                        <div className = "form__form-group-field">
                          <Field
                            name = "title"
                            component = { renderField }
                            type = "text"
                            placeholder = "Trip Title"
                            onChange = { this.onFieldChange }
                          />
                        </div>
                      </div>


                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Trip Description</span>
                        <div className = "form__form-group-field">
                          <div className = "form__form-group-input-wrap form__form-group-input-wrap--error-above">

                            <Field
                              onChange = { this.onFieldChange }
                              name = "description"
                              component = "textarea"
                              type = "text"
                              placeholder = "Trip Description"
                              id = "description"
                            />
                            {this.state.showDescriptionError && <span className = "form__form-group-error" >Description is too long</span>}
                          </div>

                        </div>
                      </div>

                      {this.props.drifft && this.props.drifft.travelAgent.allowFullPayment && <div className = "form__form-group">
                        <span className = "form__form-group-label">Order type: </span>
                        <div className = "form__form-group-field">
                          <div className = "form__form-group-input-wrap form__form-group-input-wrap--error-above"
                            style = { {} }
                          >
                            <Field
                              onChange = { this.onFieldChange }
                              name = "isFullPayment"
                              component = { renderRadioButtonField }
                              radioValue = "Instalment"
                              label = "Instalment"
                              id = "isFullPayment"
                              defaultChecked
                            />
                            <Field
                              onChange = { this.onFieldChange }
                              name = "isFullPayment"
                              component = { renderRadioButtonField }
                              radioValue = "Full payment"
                              label = "Full Payment"
                              id = "isFullPayment"
                            />

                          </div>

                        </div>
                      </div>}                      

                      {this.props.drifft.travelAgentUser.permission.viewDeposit &&
                        <div className = "form__form-group">
                          <span className = "form__form-group-label">Deposit</span>
                          <div className = "form__form-group-field">
                            <Field
                              onChange = { this.onFieldChange }
                              name = "deposit"
                              component = { renderField }
                              type = "number"
                              placeholder = "Deposit"
                              value = { this.state.deposit }
                              step = ".01"
                            />
                          </div>
                        </div>
                      
                      }

                      <h3 className = "bold-text">Customer Details</h3>
                      <p />
                      <p />
                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Full Name*</span>
                        <div className = "form__form-group-field">
                          <Field
                            name = "name"
                            component = { renderField }
                            type = "text"
                            placeholder = "Full Name"
                          />
                        </div>
                      </div>
                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Email ID*</span>
                        <div className = "form__form-group-field">
                          <Field
                            name = "email"
                            component = { renderField }
                            type = "email"
                            placeholder = "example@mail.com"
                          />
                        </div>
                      </div>
                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Date of Birth</span>
                        <div className = "form__form-group-field">
                          <Field
                            name = "DOB"
                            placeholderText = "Date of Birth"
                            startDate = { moment().subtract(20, 'years').toDate() }
                            component = { renderDatePickerField }
                            showDateOfBirthError = { this.state.showDOBError }
                            onChange = { this.onDateOfBirthChange }
                            yearDropdownItemNumber = { 24 }
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode = "select"
                            clearData = { this.props.clearData }
                            id = 'DOB'
                          />
                        </div>
                      </div>
                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Address</span>
                        <div className = "form__form-group-field">
                          <Field
                            name = "address"
                            component = "input"
                            type = "text"
                            placeholder = "Address"
                          />
                        </div>
                      </div>
                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Zipcode*</span>
                        <div className = "form__form-group-field">
                          <Field
                            name = "zipcode"
                            component = { renderField }
                            type = "number"
                            placeholder = "Zipcode"
                          />
                        </div>
                      </div>
                      <div className = "form__form-group">
                        <span className = "form__form-group-label">Phone Number</span>
                        <div className = "form__form-group-field">
                          <Field
                            name = "phone"
                            component = { renderPhoneInputField }
                            error = ""
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </CardBody>
            </Card>

          </Col>
          <Col lg = { 5 }
            md = { 12 }
            style = { { backgroundColor: '#f2f4f7', paddingRight: '15px', paddingTop: '30px' } }
          >
            <Card className = "grid">
              <CardBody>
                <div className = "card__title"
                  style = { { textTransform: 'none' } }
                >
                  <h3 className = "bold-text">Total Price</h3>

                </div>
                <div style = { { justifyContent: 'space-between', display: 'flex' } }>
                  <h4 >Your fare</h4>

                  <h4 style = { { paddingRight: '12px' } }>{this.state.currency} {this.state.price}</h4>
                </div>
                {this.installmentlist()}
                <div >
                  <div className = "card__title"
                    style = { { textTransform: 'none', marginTop: '36px' } }
                  >
                    <h3 className = "bold-text">Payment Link</h3>

                  </div>
                  <div style = { {
                    backgroundColor: '#fff',
                    margin: '.7rem 0',
                    padding: '.7rem',
                    border: '1.6px solid #000',
                    boxSizing: 'border-box',
                    borderRadius: '3.2px',
                    display: this.props.drifft.newLink == null ? 'none' : 'block'
                  } }
                    onClick = { this.onClickGeneratedLink }
                  >
                    <h4 id = "link"
                      style = { { textAlign: "center" } }
                    >{this.props.drifft.newLink ? this.props.drifft.v2FELink + this.props.drifft.newLink.drifftSubUrl : 'Please Generate Link'}</h4>
                  </div>
                  <div style = { {marginBottom: '10px'} }>
                    <Button type = "submit"
                      controlAll
                    >
                    Generate New Link
                    </Button>
                  </div>
                  <div style = { {marginBottom: '10px'} }>
                    <Button white
                      onClick = { this.openClick }
                      controlAll
                    >
                    Open Link
                    </Button>
                  </div>
                  <div style = { {marginBottom: '10px'} }>
                    <Button white
                      onClick = { this.emailClick }
                      controlAll
                    >
                    Email Payment Link
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </form>
      </Row >
    )
  }
}

export default reduxForm({
  form: 'horizontal_form_ticket', // a unique identifier for this form
  validate,
})(withTranslation('common')(HorizontalFormTicket))
