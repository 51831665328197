import React from 'react'
import { Redirect } from 'react-router-dom'
import qs from 'query-string'
import TextBox from '../../../components/textBox/TextBox'
import Button from '../../../components/button/Button'
import { Timer } from '@paywithsplit/split-react-component'
import { errors } from '../../../helpers/constants'
import { connect } from 'react-redux'
import { merchantResetPasswordValid, merchantResetPasswordRequest } from '../../../redux/reducers/drifftReducer'
import { Form, Field } from 'react-final-form'

@connect(
    store => ({
        drifft: store.drifft,
    }),
    dispatch => ({
        merchantResetPasswordValid: (resetToken, email) => dispatch(merchantResetPasswordValid(resetToken, email)),
        merchantResetPasswordRequest: (resetToken, email, password) => dispatch(merchantResetPasswordRequest(resetToken, email, password)),
    })
)

class StageResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            previousStage: false,
            nextStage: false,
            email: '',
            resetToken: '',
            error: false,
            duration: null
        }
    }

    componentDidMount() {
        this.validateResetRequest()
    }

    validateResetRequest = async () => {
        const queryParam = new qs.parse(this.props.location.search)
        let data = await this.props.merchantResetPasswordValid(queryParam.token, queryParam.email)
        
        this.setState({ email: queryParam.email, resetToken: queryParam.token })
        if (this.props.drifft.error) {
          this.setState({ error: true })
        } else {
          this.setState({ loading: false, duration: data.duration > 900 ? 900 : data.duration })
        }
    }

    onSubmit = async (values) => {
        await this.props.merchantResetPasswordRequest(this.state.resetToken, this.state.email, values.password)
        if (this.props.drifft.error) {
          this.setState({ error: true })
        } else {
          this.setState({ nextStage: true })
        }
    }

    validation = async (values) => {
        let errorMessages = {}
        let regex = new RegExp('^([a-zA-Z0-9!@*#]{8,15})$')

        if (!regex.test(values.password)) {
            errorMessages.password = errors.PASSWORD_INVALID
        }
        if (!values.password || values.password.length < 8) {
            errorMessages.password = errors.PASSWORD_TOO_SHORT
        }
        if (values.password != values.confirmedPassword) {
            errorMessages.confirmedPassword = errors.PASSWORD_DO_NOT_MATCH
        }
        return errorMessages
    }

    timeOut = () => { 
      this.setState({ error: true })
    }

    render() {
        if (this.state.nextStage) return <Redirect to = { { pathname: '/merchant/complete', state: { isForgot: false } } } />
        if (this.state.error) return <Redirect to = '/merchant/error' />
        return (
          <div className = "account">
            {!this.state.loading && <div className = "account__wrapper">
              <div className = "account__card">
                {this.state.duration && 
                  <Timer onComplete = { this.timeOut } 
                    seconds = { this.state.duration } 
                  />}
                <div className = "account__head"
                  style = { { marginBottom: "10px" } }
                >
                  <h3 className = "account__title">One last step, let's secure your account.</h3>
                </div>
                <h5 className = "account_sub"
                  style = { { marginBottom: "30px" } }
                >You can use this new password to log into your Split account.</h5>
                <Form
                  onSubmit = { this.onSubmit }
                  validate = { this.validation }
                  render = { ({ handleSubmit, form }) => (

                    <form onSubmit = { handleSubmit }>
                      <Field name = "password">
                        {({ input, meta }) => (
                          <TextBox
                            { ...input }
                            name = { 'password' }
                            label = 'Password'
                            placeholder = "Enter password"
                            error = { (meta.error || (meta.submitError)) && meta.touched && (meta.error || meta.submitError) }
                            password
                          />
                                        )}
                      </Field>
                      <Field name = "confirmedPassword">
                        {({ input, meta }) => (
                          <TextBox
                            name = { 'confirmedPassword' }
                            { ...input }
                            label = 'Confirm Password'
                            placeholder = "Re-enter password"
                            error = { (meta.error || (meta.submitError)) && meta.touched && (meta.error || meta.submitError) }
                            password
                          />
                                    )}
                      </Field>
                      <div style = { { display: "flex", marginTop: "1.4rem" } }>
                        <Button white
                          type = 'button'
                          onClick = { () => { this.setState({ previousStage: true }) } }
                        > Back </Button>
                        <Button type = 'submit'> Next </Button>
                      </div>
                    </form>
                            ) }
                />
              </div>
            </div>}
          </div>
        )
    }
}
export default StageResetPassword