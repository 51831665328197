import React from "react"
import classNames from 'classnames'
import styles from './styles.css'
import InputMask from 'react-input-mask'

const TextBox = (props) => {
    let { className, error, mask, type, ...other} = props

    if ( mask ) return <Masked { ...props } />
    return <input { ...other }
      type = { type }
      className = { classNames([styles.textBox, error && styles.error, className]) }
           />
}

const Masked = (p) => (
  <InputMask name = { p.name }
    style = { p.style }
    maskChar = { null }
    mask = { p.mask }
    value = { p.value }
    onChange = { p.onChange }
    onBlur = { p.onBlur }
  >
    {(inputProps) => <TextBox { ...inputProps }
      error = { p.error }
      placeholder = { p.placeholder }
      autoComplete = { p.autoComplete }
      type = { p.type }
                     />}
  </InputMask>
)

export default TextBox