export const style = {
    base: {
        fontFamily: 'Nunito , sans-serif',
        fontSize: "1.2rem",
        color: "#495057",
        '::placeholder': {
            color: '#6C757D',
            opacity: 1
        }
    }
}