import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
import LogInForm from './components/LogInForm'
import { connect } from "react-redux"
import {login} from '../../../redux/reducers/drifftReducer'
import {changeUserType} from '../../../redux/reducers/customerReducer'
@connect(
  store => ({
    drifft: store.drifft,
  }),
  dispatch => ({
    login: (email,password) => dispatch(login(email,password)), 
    changeUserType: (type) => dispatch(changeUserType(type))
  })
)
class LogIn extends Component {

  constructor(props) {
    super(props)
    this.props.changeUserType("merchant")
    this.state = {
      justloggedIn: false,
      failedLogin: false,
      forgotPassword: false,
      errorMessage: null
    }
  }

  forgotPasswordLink() {
    this.setState({forgotPassword: true})
  }

  showResults = async (values) => {
    await this.props.login(values.email,values.password)

    if (this.props.drifft.loggedIn) {
      this.setState({
        justloggedIn: true,
        failedLogin: false
      })
    } else {
      this.setState({
        failedLogin: true,
        errorMessage : this.props.drifft.loginErrorMessage
      })
    }
  }

  render(){
    if(this.props.drifft.loggedIn && this.state.justloggedIn){
      // return <Redirect to={ "dashboard"} />
      if(this.props.drifft.loggedIn && this.state.justloggedIn){
        if(this.props.drifft.travelAgentUser.isAdmin){
          return <Redirect to = { "/merchant/dashboard" } />
        }else{
          return <Redirect to = { "/merchant/pastpayments" } />
        }
      }
    }
    if(this.state.forgotPassword){
      return <Redirect to = { "/merchant/forgotPassword/" } />
    }
    return (
      <div className = "account">
        <div className = "account__wrapper">
          <div className = "account__card">
            <div className = "account__head">
              <h3 className = "account__title">Log in to your Split Partner account
              </h3>
            </div>
            <LogInForm onSubmit = { this.showResults }
              failedLogin = { this.state.failedLogin }
              errorMessage = { this.state.errorMessage }
              forgotPasswordLink = { this.forgotPasswordLink.bind(this) }
            />
          </div>
        </div>
      </div>
    )
  }

}
export default LogIn
