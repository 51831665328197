import React, { useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'
import { useState } from "react"
import Button from "../../../../components/button/Button"
import { RedeemCode } from "../../../../redux/reducers/drifftReducer"
import { connect } from "react-redux"

import { Dialog } from "@material-ui/core"
import { DialogActions } from "@material-ui/core"
import { DialogContent } from "@material-ui/core"
import { DialogContentText } from "@material-ui/core"
import { DialogTitle } from "@material-ui/core"
import moment from "moment"

const columns = [
  {
    label: 'Redemption Code',
    field: 'redemptionCode',
  },
  {
    label: 'Redeem',
    field: 'isRedeem',
  },
  {
    label: 'Savings completed',
    field: 'createdAt',
  },
  {
    label: 'Claimed at',
    field: 'claimedAt',
  },
  {
    label: 'National ID',
    field: 'nationalId',
  },
  {
    label: 'Phone Number',
    field: 'phoneNumber',
  },
  {
    label: 'Amount',
    value: 'totalAmount'
  },
]

function RedemptionCodesTable (props) {
  const [rows, setRows] = useState([{}])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    // TODO: can make a helper function for this and call it
    if(props.redemptionResult){
      
      for(let i = 0; i < props.redemptionResult.length; i++) {
        if(props.redemptionResult[i].isRedeem === false){
          props.redemptionResult[i].isRedeem = <Button onClick = { ()=>{handleClickOpen()} }>
          Redeem
          </Button>
        }

        if(props.redemptionResult[i].isRedeem === true){
          props.redemptionResult[i].isRedeem = "Redeemed"
        }

        if(props.redemptionResult[i].createdAt){
          let createdDate = moment(props.redemptionResult[i].createdAt).format("Do MMM YYYY, h:mm:ss A")

          props.redemptionResult[i].createdAt = createdDate
        }

        if(props.redemptionResult[i].claimedAt){
          let claimedDate = moment(props.redemptionResult[i].claimedAt).format("Do MMM YYYY, h:mm:ss A")

          props.redemptionResult[i].claimedAt = claimedDate
        }

        if(props.redemptionResult[i].claimedAt === null){
          props.redemptionResult[i].claimedAt = "Ready to be claimed"
        }

      }

      setRows(props.redemptionResult)
    }
  }, [props.redemptionResult])

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const redeemCode = async (redemptionCode) => {
    try {

      const response = await props.RedeemCode(redemptionCode)

      for(let i = 0; i < response.length; i++) {
        if(response[i].isRedeem === true){
          response[i].isRedeem = "Redeemed"
        }

        if(response[i].createdAt){
          let createdDate = moment(response[i].createdAt).format("Do MMM YYYY, h:mm:ss A")

          response[i].createdAt = createdDate
        }

        if(response[i].claimedAt){
          let claimedDate = moment(response[i].claimedAt).format("Do MMM YYYY, h:mm:ss A")

          response[i].claimedAt = claimedDate
        }
      }

      handleClose()
      setRows(response)
    } catch(e) {
      console.error(e)
    }
  }

    return(
      <>
        <Card>
          <CardBody>
            <div className = "card__title">
              <h5 className = "bold-text">Redemption code</h5>
            </div>
            <MDBTable>
              <MDBTableHead columns = { columns } />
              <MDBTableBody rows = { rows } />
            </MDBTable>

          </CardBody>    
        </Card>
        <Dialog
          open = { open }
          onClose = { handleClose }
          aria-labelledby = "alert-dialog-title"
          aria-describedby = "alert-dialog-description"
        >
          <DialogTitle id = "alert-dialog-title">
            {"Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id = "alert-dialog-description">
              Are you sure you want to redeem this code? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick = { handleClose }
              color = "primary"
            >
              No
            </Button>
            <Button onClick = { ()=>{redeemCode(rows[0].redemptionCode)} }
              color = "primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )

}

const mapDispatchToProps = {
  RedeemCode: RedeemCode
}

export default connect(null, mapDispatchToProps)(RedemptionCodesTable)