import React, { Component, Fragment } from 'react'
import { hot } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import '../../scss/app.scss'
import Router from './Router'
import store from './store'
import ScrollToTop from './ScrollToTop'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import { config as i18nextConfig } from '../../translations'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'

if ( process.env.NODE_ENV === 'production' ) {
  Sentry.init({dsn: "https://eb370acda77c41699b04fa73cac685d6@o362478.ingest.sentry.io/5379338"})
  ReactGA.initialize('UA-174633996-1')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

i18next.init(i18nextConfig)
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loaded: false,
    }
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false })
      setTimeout(() => this.setState({ loaded: true }), 500)
    })
  }

  render() {
    return (
      <Provider store = { store }>
        <MuiPickersUtilsProvider utils = { MomentUtils }>
          <BrowserRouter>
            <I18nextProvider i18n = { i18next }>  
              <ScrollToTop>
                <Fragment>
                  <div>
                    <Router />
                  </div>
                </Fragment>
              </ScrollToTop>
            </I18nextProvider>
          </BrowserRouter>
        </MuiPickersUtilsProvider>

      </Provider>
    )
  }
}

export default hot(module)(App)
