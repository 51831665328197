import React from 'react'
import TextBox from '../../../../components/textBox/TextBox'
import Button from '../../../../components/button/Button'
import { errors } from '../../../../helpers/constants'
import { connect } from 'react-redux'
import { changeExistingPassword, login, clearStore, changeUserType } from '../../../../redux/reducers/customerReducer'
import { Form, Field } from 'react-final-form'

@connect(
    store => ({
        customer: store.customer
    }),
    dispatch => ({
        changeExistingPassword: (password, currentPassword, email) => dispatch(changeExistingPassword(password, currentPassword, email)),
        login: (email, password) => dispatch(login(email, password)),
        clearStore: () => dispatch(clearStore()),
        changeUserType: (type) => dispatch(changeUserType(type))

    })
)

class ChangePassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            previousStage: false,
            nextStage: false,
            sucess: false
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    showPassword() {
        this.setState({ showPassword: !this.state.showPassword })
    }

    validation = async (values) => {
        let errorMessages = {}
        let regex = new RegExp('^([a-zA-Z0-9!@*#]{8,15})$')

        if (!regex.test(values.password)) {
            errorMessages.password = errors.PASSWORD_INVALID
        }
        if (!values.password || values.password.length < 8) {
            errorMessages.password = errors.PASSWORD_TOO_SHORT
        }
        if (values.password != values.confirmedPassword) {
            errorMessages.confirmedPassword = errors.PASSWORD_DO_NOT_MATCH
        }
        return errorMessages
    }

    return = () => {
        this.props.toggle()
    }

    onSubmit = async(values)=> {
        await this.props.changeExistingPassword(values.password, values.currentPassword, this.props.customer.userInfo.clients[0].email)
        if (!this.props.customer.error) {
            let email = this.props.customer.userInfo.clients[0].email
            let password = values.password

            this.props.clearStore()
            this.props.changeUserType("customer")
            await this.props.login(email, password)
            this.setState({ sucess: true })
        } else {
            return ({ currentPassword: this.props.customer.error.message })
        }

    }

    render() {
        if (this.state.sucess) {
            return (
              <div className = "account">
                <div className = "account__wrapper">

                  <div className = "account__card">
                    <div className = "account__head"
                      style = { { marginBottom: "10px" } }
                    >
                      <h3 className = "account__title">Success!</h3>
                    </div>
                    <h5 className = "account_sub"
                      style = { { marginBottom: "30px" } }
                    >Your password has been successfully changed! Click the button below to return to account settings.</h5>
                    <div style = { { display: "flex", marginTop: "1.4rem" } }>
                      <Button onClick = { this.return }>Return</Button>
                    </div>
                  </div>
                </div>
              </div>

            )
        }
        else return (
          <div className = "account">
            <div className = "account__wrapper">

              <div className = "account__card">
                <div className = "account__head"
                  style = { { marginBottom: "10px" } }
                >
                  <h3 className = "account__title">Change password</h3>
                </div>
                <h5 className = "account_sub"
                  style = { { marginBottom: "30px" } }
                >To change your password, we will need your current password for verification.</h5>
                <Form
                  onSubmit = { this.onSubmit }
                  validate = { this.validation }
                  render = { ({ handleSubmit, form }) => (
                    <form onSubmit = { handleSubmit }>
                      <Field name = "currentPassword">
                        {({ input, meta }) => (
                          <TextBox
                            name = { 'currentPassword' }
                            { ...input }
                            label = "Old password"
                            password
                            error = { (meta.error || (meta.submitError)) && meta.touched && (meta.error || meta.submitError) }
                          />
                                        )}
                      </Field>
                      <Field name = "password">
                        {({ input, meta }) => (
                          <TextBox
                            name = { 'password' }
                            { ...input }
                            label = "New password"
                            password
                            error = { (meta.error || (meta.submitError)) && meta.touched && (meta.error || meta.submitError) }
                          />
                                        )}
                      </Field>
                      <Field name = "confirmedPassword">
                        {({ input, meta }) => (
                          <TextBox
                            name = { 'confirmedPassword' }
                            { ...input }
                            type = { this.state.showPassword ? 'text' : 'password' }
                            password
                            error = { (meta.error || (meta.submitError)) && meta.touched && (meta.error || meta.submitError) }
                            label = "Re-enter new password"
                          />
                                        )}
                      </Field>
                      <div style = { { display: "flex", marginTop: "1.4rem" } }>
                        <Button white
                          type = 'button'
                          onClick = { this.props.toggle }
                        > Back </Button>
                        <Button type = 'submit'> Confirm </Button>
                      </div>
                    </form>
                            ) }
                />
              </div>
            </div>
          </div>
        )
    }
}
export default ChangePassword