/* eslint-disable */
const validate = (values) => {
    const errors = {};
    if (!values.reason) {
      errors.reason = 'Reason field shouldn’t be empty';
    }
    if (!values.amount &&  values.type === "Partial Refund") {
      errors.amount = "Amount field shouldn't be empty";
    }
    else if(!(/^\s*-?\d+(\.\d{1,2})?\s*$/.test(values.amount)) && values.type === "Partial Refund"){
      errors.amount ="Please enter a valid amount";
    }
    if (!values.ticketId) {
      errors.ticketId = 'OrderID field shouldn’t be empty';
    }
    return errors;
  };
  
  export default validate;
  