/* eslint-disable */ 
import React, { PureComponent,createRef } from 'react';
import {
  Card, CardBody, Col, Row
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import { FullWideNotification } from '../../../../shared/components/Notification';
import { connect } from "react-redux";
import validate from './validateItem';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton'
import QRCode from 'qrcode'
import CircleJaffa from '../../../../shared/img/logo/Circle_Jaffa.svg'
import Button from '../../../../components/button/Button'
const renderField = ({
  input, placeholder, type, meta: { touched, error }, value, step
}) => (
    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
      <input {...input} placeholder={placeholder} type={type} value={value} step={step} />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  })
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text'
};

@connect(
  store => ({
    drifft: store.drifft
  })
)
class HorizontalFormItem extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };
  constructor() {
    super();
    this.state = {
      price: 0,
      currency: '',
      deposit: 0,
      dragging: false,
      collectShippingAddress:true,
      isFullPayment:false,
      canvasDrawn:false,
      iconDrawn:false
    }

    this.dragging = false
    this.canvasRef = createRef(null)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.setDragFalse);
    document.addEventListener('mousemove', this.setDragTrue);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.setDragFalse);
    document.removeEventListener('mousemove', this.setDragTrue);
  }

  drawSplitLogo = (canvas) => {
    
    const img = new Image()
    img.src = CircleJaffa
    let ctx = canvas.getContext('2d');
    //smoothen canvas for scaling
    ctx.mozImageSmoothingEnabled = false;
    ctx.webkitImageSmoothingEnabled = false;
    ctx.msImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;
    // drawing Split logo onto canvas
    let QRsize = 165
    let imageSize = 40
    let offSet = (QRsize-imageSize)/2

    img.onload = () => {
      ctx.drawImage(img, offSet, offSet, imageSize, imageSize)

      this.setState({
        canvasDrawn: true
      })
    }
  }

  async componentDidUpdate(){
    if(this.props.drifft.newLink && this.props.drifft.newLink.drifftSubUrl && !this.state.canvasDrawn){
      let fullLink = this.props.drifft.v2FELink + this.props.drifft.newLink.drifftSubUrl
      let toCanvas = await QRCode.toCanvas(canvas, fullLink)

      if(toCanvas){
        this.drawSplitLogo(toCanvas)
      }
    }
  }

  downloadQR = () => {
    if(this.props.drifft.newLink && this.props.drifft.newLink.drifftSubUrl && this.state.canvasDrawn){
      //get current canvas
      const canvas = this.canvasRef.current
      //create new canvas
      var canvasUpscaled = document.createElement('canvas');
      let dimension = 1000
      canvasUpscaled.width = dimension
      canvasUpscaled.height = dimension     
      let ctx = canvasUpscaled.getContext('2d')
      //do not smoothen canvas for scaling (makes image blurry)   
      ctx.mozImageSmoothingEnabled = false;
      ctx.webkitImageSmoothingEnabled = false;
      ctx.msImageSmoothingEnabled = false;
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(canvas,0,0,canvas.width,canvas.height,0,0,dimension,dimension)
      
      //draw enlarged Split logo over new canvas
      const img = new Image();
      img.src = CircleJaffa
      img.onload = ()=>{
        let imageSize = 280
        let offSet = (dimension-imageSize)/2
        ctx.drawImage(img,offSet,offSet,imageSize,imageSize)
        //download QR code
        const a = document.createElement('a');
        a.href = canvasUpscaled.toDataURL();
        a.download = 'QRCode.png';
        a.click()  
      }
    }
  }

  // Functions so we can register event listeners properly
  setDragFalse = () => {
    this.dragging = false
  }

  setDragTrue = () => {
    this.dragging = true
  }

  showSuccess = (notificationMessage) => {
    const { showNotification } = this.props;
    return showNotification(
      <FullWideNotification
        color="success"
        message={notificationMessage}
      />
    )
  };

  onClickGeneratedLink = (e) => {
    if (this.props.drifft.newLink != null && !this.dragging) {
      var temp = document.createElement("textarea");
      document.body.appendChild(temp);
      temp.value = document.getElementById("link").textContent
      temp.select();
      document.execCommand('copy')
      document.body.removeChild(temp)

      this.showSuccess("Copied Link!")
    }
  }

  openClick = (e) => {
    if (this.props.drifft.newLink != null) {
      let fullLink = this.props.drifft.v2FELink + this.props.drifft.newLink.drifftSubUrl
      window.open(fullLink)
    }
  }

  onFieldChange = (evt) => {
    if(evt==='Instalment' || evt === 'Full payment'){
      this.setState({isFullPayment:evt==='Instalment'?false:true})
    }
    else if (evt.dropdown) {
      this.setState({ currency: this.props.drifft.travelAgent.currencyId })
    }  else {

      if (evt.target.name == 'deposit' || evt.target.name == 'price') {
        if (evt.target.value != null) {
          if (evt.target.value <= -1) {
            evt.target.value = evt.target.value * -1
            this.setState({
              [evt.target.name]: evt.target.value
            })
          } else {
            this.setState({
              [evt.target.name]: evt.target.value
            })
          }

        }
      } else {
        this.setState({
          [evt.target.name]: evt.target.value
        })
      }

    }
  }

  installmentList = () => {
    return (
      <div style={{
        borderRadius: '3.2px',
        backgroundColor: '#fff',
        margin: '.7rem 0',
        padding: '.7rem',
        border: '1.6px solid #000'
      }}>
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <h4 >For {this.props.drifft.travelAgent.maxInstallmentsMonth} Months</h4>
          <h4>{this.state.currency} {((this.state.price * (1 + (this.props.drifft.travelAgent.extraCharge / 100))) / x).toFixed(2)}</h4>
        </div>
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <h4 className="subhead" >{this.props.drifft.travelAgent.extraCharge}% Service charge</h4>
          <h4 className="subhead">{this.state.currency} {(this.state.price * (1 + (this.props.drifft.travelAgent.extraCharge / 100))).toFixed(2)} / Total</h4>
        </div>
      </div>
    )
  }

  //take away the shit that we do not need
  customerValidation = async(data) => {
    // data.preventDefault()
    // TODO: Validate any form fields
    await this.props.handleSubmit(data)

    this.setState({
      canvasDrawn: false
    })
  }

  render() {
    return (
      <Row>
        <form className="form form--horizontal" style={{ width: '100%' }} onSubmit={this.customerValidation}>
          <Col md={12} lg={7}>
            <Card className="grid">
              <CardBody>
                <div className="card__title" style={{ textTransform: 'none' }}>
                  <h3 className="bold-text">Item Details</h3>
                  <span>* indicates required fields</span>
                  <Card>
                    <CardBody style={{ padding: '0px' }}>
                      <p></p>
                      <p></p>

                      <div className="form__form-group">
                        <span className="form__form-group-label">Price*</span>
                        <div className="form__form-group-field">
                          <Field
                            onChange={this.onFieldChange}
                            name="price"
                            component={renderField}
                            type="number"
                            placeholder="Total Price of the Order"
                            value={this.state.price}
                            step=".01"
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Item Title*</span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">

                            <Field
                              name="itemTitle"
                              component={renderField}
                              type="text"
                              placeholder="The item name"
                              id="itemTitle"
                            />
                            </div>
                        </div>
                      </div>

                      <div className="form__form-group">
                        <span className="form__form-group-label">Item Description</span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">

                            <Field
                              onChange={this.onFieldChange}
                              name="itemDescription"
                              component="textarea"
                              type="text"
                              placeholder="The details of the item"
                              id="itemDescription"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Expiry Date</span>
                        <div className="form__form-group-field">
                          <Field
                            name="expiredAt"
                            placeholderText="Leave empty for no expiry"
                            component={renderDatePickerField}
                            minDate={new Date()}
                            id='expiredAt'
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Number of Uses</span>
                        <div className="form__form-group-field">
                          <Field
                            name="maxPurchaseCount"
                            component={renderField}
                            type="number"
                            placeholder="Leave empty for unlimited uses"
                            step="1"
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Collect shipping address: </span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above" style={{}}>
                            <Field
                              onChange={()=>{this.setState({collectShippingAddress:true})}}
                              name="collectShippingAddress"
                              component={renderRadioButtonField}
                              radioValue={true}
                              label="Yes"
                              id="collectShippingAddress"
                              defaultChecked={true}
                            />
                            <Field
                              onChange={()=>{this.setState({collectShippingAddress:false})}}
                              name="collectShippingAddress"
                              component={renderRadioButtonField}
                              radioValue={false}
                              label="No"
                              id="collectShippingAddress"
                            />
                          </div>
                        </div>
                      </div>                
                      {this.props.drifft && this.props.drifft.travelAgent.allowFullPayment && <div className="form__form-group">
                        <span className="form__form-group-label">Order type: </span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above" style={{}}>
                            <Field
                              onChange={this.onFieldChange}
                              name="isFullPayment"
                              component={renderRadioButtonField}
                              radioValue="Instalment"
                              label="Instalment"
                              id="isFullPayment"
                              defaultChecked={true}
                            />
                            <Field
                              onChange={this.onFieldChange}
                              name="isFullPayment"
                              component={renderRadioButtonField}
                              radioValue="Full payment"
                              label="Full Payment"
                              id="isFullPayment"
                            />
                          </div>
                        </div>
                      </div>}                      
                    </CardBody>
                  </Card>
                </div>
              </CardBody>
            </Card>

          </Col>
          <Col md={12} lg={5} style={{ backgroundColor: '#f2f4f7', paddingRight: '15px', paddingTop: '30px' }}>
            <Card className="grid">
              <CardBody>
                <div className="card__title" style={{ textTransform: 'none' }}>
                  <h3 className="bold-text">Total Price</h3>

                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <h4 >Price</h4>

                  <h4 style={{ paddingRight: '12px' }}>{this.state.currency} {this.state.price}</h4>
                </div>
                {this.installmentlist()}
                <div >
                  <div className="card__title" style={{ textTransform: 'none', marginTop: '36px' }}>
                    <h3 className="bold-text">Payment Link</h3>

                  </div>
                  <div style={{
                    backgroundColor: '#fff',
                    margin: '.7rem 0',
                    padding: '.7rem',
                    border: '1.6px solid #000',
                    boxSizing: 'border-box',
                    borderRadius: '3.2px',
                    display: this.props.drifft.newLink == null ? 'none' : 'block'
                  }} onClick={this.onClickGeneratedLink}>
                    <h4 id="link" style={{ textAlign: "center" }}>{this.props.drifft.newLink ? this.props.drifft.v2FELink + this.props.drifft.newLink.drifftSubUrl : 'Please Generate Link'}</h4>
                  </div>
                  <Button type="submit" controlAll >
                    Generate New Link
                  </Button>
                  <div style = {{textAlign:'center', visibility: this.state.canvasDrawn ? 'visible': 'hidden'}}>
                    <canvas id='canvas' ref={this.canvasRef} />
                  </div>
                  {this.state.canvasDrawn && 
                      <div style={{textAlign:'center'}}>
                      <Button white onClick={this.downloadQR} >
                        Download QR Code
                      </Button>
                      </div>
                    }

                </div>
              </CardBody>
            </Card>
          </Col>
        </form>
      </Row >
    );
  }
}

export default reduxForm({
  form: 'horizontal_form_order', // a unique identifier for this form
  validate,
})(withTranslation('common')(HorizontalFormItem));
