import param from "jquery-param";
export let BASE_URL;
export let API_URL;
export let MS_API_URL;
export let DOMAIN_URL;
import StorageService from "../services/StorageServcies";

if (process.env.REACT_APP_MODE == "DEV") {
  DOMAIN_URL = "dev.api.paywithsplit.co";
} else if (process.env.REACT_APP_MODE == "STAGE") {
  DOMAIN_URL = "sandboxapi.paywithsplit.co";
} else if (process.env.REACT_APP_MODE == "DEMO") {
  DOMAIN_URL = "demoapi.paywithsplit.co";
} else {
  DOMAIN_URL = "api.paywithsplit.co";
}

if (window.location.protocol == "https:") {
  BASE_URL = "https://" + DOMAIN_URL;
} else {
  BASE_URL = "http://" + DOMAIN_URL;
}
if (
  process.env.REACT_APP_MODE == "STAGE" ||
  process.env.REACT_APP_MODE == "DEV"
) {
  MS_API_URL = "https://ms.sandbox.paywithsplit.co";
} else {
  MS_API_URL = "https://ms.paywithsplit.co";
}
// BASE_URL = "https://api.paywithsplit.co" ;
// BASE_URL = "http://localhost:3000"
//BASE_URL = "https://sandboxapi.paywithsplit.co"
//BASE_URL = "http://localhost:3000";
// BASE_URL = "https://sandboxapi.paywithsplit.co";
// MS_API_URL = "http://localhost:8010"
// BASE_URL = "https://dev.api.paywithsplit.co";

API_URL = `${BASE_URL}/api/v1`;

export default class ApiClient {
  static setToken(token) {
    this.token = `Bearer ${token}`;
  }

  static get(url, data, params) {
    return this._send("GET", url, data, params);
  }

  static async post(url, data, params) {
    return await this._send("POST", url, data, params);
  }

  static async patch(url, data, params) {
    return await this._send("PATCH", url, data, params);
  }

  static async _send(
    method,
    url,
    data = {},
    params = {
      json: true,
      headers: {},
    }
  ) {
    let headers = new Headers({
      Authorization: this.token,
      Credentials: "same-origin",
    });

    if (params.json) {
      headers.append("Content-Type", "application/json");
    }
    for (let h in params.headers) {
      headers.append(h, params.headers[h]);
    }
    try {
      let _url = API_URL + url;
      let sendData = {
        method,
        mode: "cors",
        headers,
      };

      if (method === "POST" || method === "PATCH")
        sendData.body = params.json ? JSON.stringify(data) : data;
      else if (method === "GET") _url += `?${param(data)}`;
      let response = await fetch(_url, sendData);
      let json = await response.json();

      if (json.statusCode >= 400) throw json;
      return json;
    } catch (e) {
      if (e.statusCode === 401) {
        const data = StorageService.get("client_JWT");

        StorageService.remove("client_JWT");
        if (data !== null) {
          alert(
            "Your session has expired, please re-login to the dashboard again"
          );
          if (data.token.substring(0, 2) == "CS") {
            window.location.href = "/customer/login";
          } else if (data.token.substring(0, 2) == "DF") {
            window.location.href = "/merchant/login";
          }
        }
      }
      throw e;
    }
  }

  static async _sendDownloadReport(
    method = "GET",
    url,
    data = {},
    params = {
      json: true,
      headers: {},
    }
  ) {
    let headers = new Headers({
      Authorization: this.token,
      Credentials: "same-origin",
    });

    if (params.json) {
      headers.append("Content-Type", "application/json");
    }
    for (let h in params.headers) {
      headers.append(h, params.headers[h]);
    }
    try {
      let _url = API_URL + url;
      let sendData = {
        method,
        mode: "cors",
        headers,
      };

      if (method === "POST")
        sendData.body = params.json ? JSON.stringify(data) : data;
      else if (method === "GET") _url += `?${param(data)}`;

      let response = await fetch(_url, sendData);

      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");

        a.href = url;
        a.download = "Past-Payment-Report.xlsx";
        a.click();
      });
    } catch (e) {
      throw e;
    }
  }
}

//Add MS API functions

export class MSApiClient {
  static setToken(token) {
    this.token = `Bearer ${token}`;
  }

  static get(url, data, params) {
    return this._send("GET", url, data, params);
  }

  static async post(url, data, params) {
    return await this._send("POST", url, data, params);
  }

  static async _send(
    method,
    url,
    data = {},
    params = {
      json: true,
      headers: {},
    }
  ) {
    let headers = new Headers({
      Authorization: this.token,
      Credentials: "same-origin",
    });

    if (params.json) {
      headers.append("Content-Type", "application/json");
    }
    for (let h in params.headers) {
      headers.append(h, params.headers[h]);
    }
    try {
      let _url = MS_API_URL + url;
      let sendData = {
        method,
        mode: "cors",
        headers,
      };

      if (method === "POST")
        sendData.body = params.json ? JSON.stringify(data) : data;
      else if (method === "GET") _url += `?${param(data)}`;

      let response = await fetch(_url, sendData);
      let json = await response.json();

      if (json.statusCode >= 400) throw json;
      return json;
    } catch (e) {
      throw e;
    }
  }
}
