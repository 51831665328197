import React from "react"
import classNames from 'classnames'
import styles from './styles.module.css'
import { isDeviceVertical } from "../../helpers/utils"
import { brandingMap } from "../../helpers/constants"

export default (props) => {
  let { className, disabled, onClick, white, control, apiKey, style,controlAll, ...others } = props

  let subKey = null

  if (apiKey) {
    subKey = apiKey.slice(-4)
  }

  let buttonStyle = null

  if (style) {
    buttonStyle = { ...style }
  }

  if (subKey == null) {
    if (buttonStyle == null) {
      buttonStyle = {}
      buttonStyle['backgroundImage'] = 'linear-gradient(97.2deg, #FF8600 0%, #FF5A00 101.54%)'
    }
    else if (buttonStyle.background != 'gray' && !disabled) {
      buttonStyle['backgroundImage'] = 'linear-gradient(97.2deg, #FF8600 0%, #FF5A00 101.54%)'
    }

  }
  else { // if subkey is != null but not in brandingMap

    if (buttonStyle == null) {
      buttonStyle = {}
    }

    if (brandingMap[subKey]) {

      if(brandingMap[subKey].buttonStyle){
        buttonStyle['backgroundImage'] = brandingMap[subKey].buttonStyle
      }
      if(brandingMap[subKey].buttonColor){
        buttonStyle['backgroundColor'] = brandingMap[subKey].buttonColor
      }
           
           
    } else {
      buttonStyle['backgroundImage'] = 'linear-gradient(97.2deg, #FF8600 0%, #FF5A00 101.54%)'
    }

  }


  return <button

    className = { classNames([styles.buttonGradient,
      white && styles.buttonWhite,
      disabled && styles.disabled,
      control && isDeviceVertical() && styles.control,
      controlAll && styles.control,
      className]) }
    onClick = { !disabled ? onClick : undefined }
    style = { buttonStyle }
    { ...others }
         />
}