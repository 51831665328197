import React from 'react'
import { Redirect } from 'react-router-dom'
import TextBox from '../../../components/textBox/TextBox'
import Button from '../../../components/button/Button'
import { errors } from '../../../helpers/constants'
import { connect } from 'react-redux'
import { changePassword } from '../../../redux/reducers/customerReducer'
import { Form, Field } from 'react-final-form'

@connect(
    store => ({
        customer: store.customer
    }),
    dispatch => ({
        changePassword: (validatePhone, password, email) => dispatch(changePassword(validatePhone, password, email))
    })
)

class StageReturnPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            previousStage: false,
            nextStage: false,
        }
    }

     onSubmit = async (values) => {
            await this.props.changePassword(this.props.customer.phoneNumber, values.password, this.props.customer.email)
            if (!this.props.customer.error) {
                this.setState({ nextStage: true })
            }
        
    }

    validation = async (values) => {
        let errorMessages = {}
        let regex = new RegExp('^([a-zA-Z0-9!@*#]{8,15})$')

        if (!regex.test(values.password)) {
            errorMessages.password = errors.PASSWORD_INVALID
        }
        if (!values.password || values.password.length < 8) {
            errorMessages.password = errors.PASSWORD_TOO_SHORT
        }
        if (values.password != values.confirmedPassword) {
            errorMessages.confirmedPassword = errors.PASSWORD_DO_NOT_MATCH
        }
        return errorMessages
    }

    render() {
        if (this.state.previousStage) return <Redirect to = '/customer/newuser' />
        if (this.state.nextStage) return <Redirect to = '/customer/newuser/finish' />
        return (
          <div className = "account">
            <div className = "account__wrapper">
              <div className = "account__card">
                <div className = "account__head"
                  style = { { marginBottom: "10px" } }
                >
                  <h3 className = "account__title">One last step, let's secure your account.</h3>
                </div>
                <h5 className = "account_sub"
                  style = { { marginBottom: "30px" } }
                >You can use this password to log into your Split account.</h5>
                <Form
                  onSubmit = { this.onSubmit }
                  validate = { this.validation }
                  render = { ({ handleSubmit, form }) => (

                    <form onSubmit = { handleSubmit }>
                      <Field name = "password">
                        {({ input, meta }) => (
                          <TextBox
                            { ...input }
                            name = { 'password' }
                            label = 'Password'
                            placeholder = "Enter password"
                            error = { (meta.error || (meta.submitError)) && meta.touched && (meta.error || meta.submitError) }
                            password
                          />
                                        )}
                      </Field>
                      <Field name = "confirmedPassword">
                        {({ input, meta }) => (
                          <TextBox
                            name = { 'confirmedPassword' }
                            { ...input }
                            label = 'Confirm Password'
                            placeholder = "Re-enter password"
                            error = { (meta.error || (meta.submitError)) && meta.touched && (meta.error || meta.submitError) }
                            password
                          />
                                    )}
                      </Field>
                      <div style = { { display: "flex", marginTop: "1.4rem" } }>
                        <Button white
                          type = 'button'
                          onClick = { () => { this.setState({ previousStage: true }) } }
                        > Back </Button>
                        <Button type = 'submit'> Next </Button>
                      </div>
                    </form>
                            ) }
                />
              </div>
            </div>
          </div>
        )
    }
}
export default StageReturnPassword