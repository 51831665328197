import React, { PureComponent } from 'react'
import { Container } from 'reactstrap'
import HorizontalFormOrder from './components/HorizontalFormOrder'
import { connect } from "react-redux"
import {createDrifftItem, sendDriffEmail,clearLink} from '../../../redux/reducers/drifftReducer'
import NotificationSystem from 'rc-notification'
let notification = null

const showNotification = (notificationContent) => {
  notification.notice({
    content: notificationContent,
    duration: 3,
    closable: true,
    style: { top: 0, left: 0 },
    className: 'full ltr-support',
  })
}

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    createDrifftItem: (dataForm, multiLink) => dispatch(createDrifftItem(dataForm, multiLink)),
    sendDriffEmail: (payload) => dispatch(sendDriffEmail(payload)),
    clearLink: () => dispatch(clearLink())
  })
)


class NewOrderPage extends PureComponent{ 
  constructor(){
    super()
    this.state = {
      clearData: false
    }
  }

  componentDidMount() {
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n)
  }

  componentWillUnmount(){
    this.props.clearLink()
  }

  showResults = async (dataForm) => {
    let dataFormClone = JSON.parse(JSON.stringify(dataForm))

    dataFormClone.collectShippingAddress = dataForm.collectShippingAddress === "yesAddress" ? true : false
    dataFormClone.hasExpiryDate = dataForm.hasExpiryDate === "yesExpiry" ? true : false
    dataFormClone.hasLimitStock = dataForm.hasLimitStock === "yesStock" ? true : false
    if(dataForm.deposit <= -1){
      dataFormClone.deposit = dataForm.deposit * -1
    }
    await this.props.createDrifftItem(dataFormClone, true) // multiLink is true


  }

  onEmailClick = (data) => {
    this.props.sendDriffEmail(data)
  }

  render(){
    return(
      <Container className = "invoice">
        <HorizontalFormOrder 
          handleSubmit = { this.showResults } 
          clearData = { this.state.clearData } 
          onEmailClick = { this.onEmailClick }
          showNotification = { (notificationContent) => showNotification(notificationContent) }
        />
      </Container>
    )
  }
}

export default NewOrderPage
