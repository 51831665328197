import React from 'react'
import { Table } from 'reactstrap'
import Panel from '../../../../../shared/components/Panel'
import Collapse from '../../../../../shared/components/Collapse'
import { connect } from "react-redux"
import { rankingOrders, rankingCustomers } from '../../../../../redux/reducers/drifftReducer'

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    rankingOrders: (currency) => dispatch(rankingOrders(currency)),
    rankingCustomers: (currency) => dispatch(rankingCustomers(currency))
  })
)

class Rankings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTitle: "Orders",
      collapse: false
    }
  }

  async componentDidMount(){
      await this.props.rankingCustomers(this.props.drifft.currency)
      await this.props.rankingOrders(this.props.drifft.currency)
  }

  async componentWillUpdate(nextProps) {
    if (nextProps.drifft.currency !== this.props.drifft.currency || (this.props.drifft.hasJWT && !this.props.drifft.loggedIn && nextProps.drifft.loggedIn)) {
      await this.props.rankingCustomers(nextProps.drifft.currency)
      await this.props.rankingOrders(nextProps.drifft.currency)
    }
  }

  toggle(){
    this.setState((prevState)=>({ collapse: !prevState.collapse}))
  }

  renderTableDataOrders() {
    return this.props.drifft.rankingOrders.map((order, index) => {
      const { externalOrderId, price } = order

      return (<tr key = { externalOrderId + index }>
        <td>{externalOrderId}</td>
        <td>{price}</td>
      </tr>)
    })
  }

  renderTableDataCustomers() {
    return this.props.drifft.rankingCustomers.map((customers, index) => {
      const { sum, name } = customers

      return (<tr key = { name + index }>
        <td>{name}</td>
        <td>{sum}</td>
      </tr>)
    })
  }

  render() {
    let component

    if (this.props.drifft.rankingOrders === null || this.props.drifft.rankingCustomers === null) {
      component = <div >
        <div style = { { display: 'block', textAlign: 'center', paddingTop: '2rem' } }>
          <div className = "load__icon-wrap">
            <svg className = "load__icon">
              <path fill = "#ff8600"
                d = "M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      </div>
    }
    else {
      if (this.state.selectedTitle == "Orders") {
        component = <Table responsive
          className = "table--bordered"
                    >
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTableDataOrders()}
          </tbody>
        </Table>
      }
      else {
        component = <Table responsive
          className = "table--bordered"
                    >
          <thead>
            <tr>
              <th>Customer name</th>
              <th>Total paid</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTableDataCustomers()}
          </tbody>
        </Table>

      }
    }
    return (
      <Panel lg = { 4 }
        title = { "Top orders" }
      >
        <Collapse collapse = { this.state.collapse }
          toggle = { this.toggle.bind(this) }
          title = { this.state.selectedTitle }
          className = 'with-shadow productSales'
        >
          <div>
            <p type = "button"
              onClick = { () => { this.setState({ selectedTitle: "Orders" });this.setState({ collapse: false}) } }
            >Orders</p>
            <p type = "button"
              onClick = { () => { this.setState({ selectedTitle: "Customers" });this.setState({ collapse: false}) } }
            >Customers</p>
          </div>
        </Collapse>
        {component}
      </Panel>
    )
  }

}

export default Rankings
