import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
const theme = createMuiTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#28a745',
      },
    },
  })
  
function CircularProgressWithLabel(props) {
    return (
      <ThemeProvider theme = { theme }>
        <Box position = "relative"
          display = "inline-flex"
        >
          <CircularProgress variant = "static"
            { ...props }
          />
          <Box
            top = { 0 }
            left = { 0 }
            bottom = { 0 }
            right = { 0 }
            position = "absolute"
            display = "flex"
            alignItems = "center"
            justifyContent = "center"
          >
            <Typography variant = "caption"
              component = "div"
              color = "textPrimary"
            >
              {`${Math.round(props.total)}%`}
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>
    )
  }
  
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and static variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }
  
 export default function CircularStatic(props) {
    const [progress, setProgress] = React.useState(0)
  
    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= props.percentComplete ? props.percentComplete : prevProgress + 1))
      }, 1)

      return () => {
        clearInterval(timer)
      }
    }, [])
  
    return <CircularProgressWithLabel value = { progress }
      total = { props.percentComplete }
           />
  }
