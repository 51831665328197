import { StripeCardCvcElement, StripeCardExpiryElement, StripeCardNumberElement } from '../../../components/stripeElements/stripeElements'
import { Form, Field } from 'react-final-form'
import Bubbles from "../../../components/loaders/Bubbles"
import React from "react"
import Text from "../../../components/text/Text"
import Button from "../../../components/button/Button"
import TextBox from "../../../components/textBox/TextBox"
import processStyles from "./AddNewCard/processStyle.module.css"
import { errors } from "../../../helpers/constants"

const imagesCard = [
    require("../../../assets/images/pci.svg"),
    require("../../../assets/images/master.svg"),
    require("../../../assets/images/visa.svg")
  ]

export default (props) => {
   let {onNextClick,reactComponentReady, loading, apiToken, fullPayment,lockEmail,resetPaymentMethod,returningUser, initialValues,amount,currency,} = props
    const renderTextButton = fullPayment && currency && amount ? 'Pay ' + currency + ' ' + amount : 'Next'
    const validateForm = (values)=> {
      const errorMessages = {}

      //bypass form validation if returning user with existing card
      if (!fullPayment && returningUser) {
        return errorMessages
      }

      if(fullPayment){
        if (!values.emailId || !values.emailId.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
          errorMessages.emailId = errors.EMAIL_INVALID
        }  
      }

      if (!values.nameOnCard) {
        errorMessages.nameOnCard = errors.NAME_INVALID.MIN
      }
      else if (values.nameOnCard.length < 2) {
        errorMessages.nameOnCard = errors.NAME_INVALID.MIN
      }
      else if (values.nameOnCard.length > 50) {
        errorMessages.nameOnCard = errors.NAME_INVALID.MAX
      }
      else if (/[!@#$%^&*()?":{}|<>]/.test(values.nameOnCard)) {
        errorMessages.nameOnCard = errors.NAME_INVALID.SPECIAL_CHAR
      }

      if (!values.cardNumber || values.cardNumber.empty) {
        errorMessages.cardNumber = "Your card number cannot be empty"
      }
      else if (values.cardNumber && values.cardNumber.error) {
        errorMessages.cardNumber = values.cardNumber.error.message
      }

      if (!values.cardCvc || values.cardCvc.empty) {
        errorMessages.cardCvc = "Your card CVC cannot be empty"
      }
      else if (values.cardCvc && values.cardCvc.error) {
        errorMessages.cardCvc = values.cardCvc.error.message
      }

      if (!values.cardExpiry || values.cardExpiry.empty) {
        errorMessages.cardExpiry = "Your card expiry cannot be empty"
      }
      else if (values.cardExpiry && values.cardExpiry.error) {
        errorMessages.cardExpiry = values.cardExpiry.error.message
      }
      return errorMessages
    }
  
    return(
      <Form
        onSubmit = { onNextClick }
        validate = { validateForm }
        initialValues = { initialValues }
        render = { ({ handleSubmit }) => (
          <form onSubmit = { handleSubmit }>
            <Field name = "cardNumber">
              {({ input, meta }) => (

                <StripeCardNumberElement
                  onReady = { reactComponentReady }
                  onChange = { (evt) => {input.onChange(evt)} }
                  { ...input }
                  error = { (meta.error || meta.submitError) && meta.touched && (meta.error || meta.submitError) }
                />
                )}
            </Field>
            <Field name = "cardExpiry">
              {({ input, meta }) => (
                <StripeCardExpiryElement
                  onReady = { reactComponentReady }
                  onChange = { (evt) => {input.onChange(evt)} }
                  { ...input }
                  error = { (meta.error || meta.submitError) && meta.touched && (meta.error || meta.submitError) }
                  message = { 'Please ensure that your card is valid for more than 6 months from now' }
                />
                )}
            </Field>
            <Field name = "cardCvc">
              {({ input, meta }) => (
                <StripeCardCvcElement
                  onReady = { reactComponentReady }
                  onChange = { (evt) => {input.onChange(evt)} }
                  { ...input }
                  error = { (meta.error || meta.submitError) && meta.touched && (meta.error || meta.submitError) }
                />
                )}
            </Field>
            <Field name = "nameOnCard">
              {({ input, meta }) => (
                <TextBox
                  placeholder = { "Siti Nurhaliza" }
                  { ...input }
                  upperCase
                  error = { (meta.error || meta.submitError) && meta.touched && (meta.error || meta.submitError) }
                  success = { !meta.error && meta.touched && ' ' }
                  label = { "Name on card" }
                />
                )}
            </Field>
            {fullPayment &&
              <Field name = "emailId">
                {({ input, meta }) => (
                  <TextBox
                    placeholder = { "Email ID" }
                    { ...input }
                    readOnly = { lockEmail }
                    disabled = { lockEmail }          
                    error = { (meta.error || meta.submitError) && meta.touched && (meta.error || meta.submitError) }
                    label = { "Email" }
                  />
                )}
              </Field>
              
              }
            <div className = { processStyles.point }>
              <Text roboto
                med
              >
                  Your security is our biggest priority, and your card is safely saved in our secure vaults
              </Text>
            </div>
            <div>
              {imagesCard.map((i, k) => {
                  return <img key = { k }
                    src = { i }
                    style = { { margin: ".75rem", width: 'auto'} }
                         />
                })}
            </div>
            <div style = { { display: "flex", marginTop: "1.4rem" } }>
              {resetPaymentMethod &&
                <Button white
                  onClick = { resetPaymentMethod }
                  type = "button"
                  disabled = { loading }
                >
                  Back
                </Button>
              }
              <Button control
                onClick = { handleSubmit }
                type = "submit"
                disabled = { loading }
                apiKey = { apiToken }
              >
                {loading ? <Bubbles color = { "white" } /> : renderTextButton}
              </Button>
            </div>
          </form>

          ) }
      />

    )
}