import React from 'react'
import { connect } from 'react-redux'
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts'
import CustomPanel from './CustomPanel'
import Collapse from '../../../../../shared/components/Collapse'
import getTooltipStyles from '../../../../../shared/helpers'
import { DatePicker } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import './ProductSales.css'
import { createMuiTheme } from '@material-ui/core'
import orange from "@material-ui/core/colors/orange"
import { productSales } from '../../../../../redux/reducers/drifftReducer'
const materialTheme = createMuiTheme({
  palette: {
    primary: orange,
  },

})

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    productSales: (selectedDate, todayDate, utcOffset, currency) => dispatch(productSales(selectedDate, todayDate, utcOffset, currency))
  })

)

class ProductSales extends React.Component {
  constructor(props) {
    super(props)
    var d = new Date()

    d.setDate(d.getDate() - 1)

    this.state = {
      toggleView: "Gross Volume",
      selectedDate: d,
      collapse: false
    }
  }

  async componentDidMount(){
    let selectedDate = this.state.selectedDate

    this.props.productSales(selectedDate, new Date(), this.props.drifft.travelAgent.utcOffset, this.props.drifft.currency)
  }

  async componentWillUpdate(nextProps) {
    if (nextProps.drifft.currency !== this.props.drifft.currency || (this.props.drifft.hasJWT && !this.props.drifft.loggedIn && nextProps.drifft.loggedIn)) {
      let selectedDate = this.state.selectedDate

      await this.props.productSales(selectedDate, new Date(), this.props.drifft.travelAgent.utcOffset, nextProps.drifft.currency)
    }
  }

  toggle(){
    this.setState((prevState)=>({ collapse: !prevState.collapse}))
  }

  render() {
    let component

    if (this.props.drifft.productSales === null) {
      component = <div >
        <div style = { { display: 'block', textAlign: 'center', paddingTop: '2rem' } }>
          <div className = "load__icon-wrap">
            <svg className = "load__icon">
              <path fill = "#ff8600"
                d = "M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>

      </div>
    } else {
      if (this.state.toggleView == "Successful payments") {
        component = <div dir = "ltr">
          <ResponsiveContainer height = { 250 }
            className = "dashboard__area"
          >
            <AreaChart data = { this.props.drifft.productSales[1] } >
              <XAxis dataKey = "name"
                tickLine = { false }
              />
              <YAxis tickLine = { false }
                orientation = { 'left' }
              />
              <Tooltip { ...getTooltipStyles(this.state.themeName, 'defaultItems') } />
              <Legend />
              <CartesianGrid />
              <Area
                name = "Today's transactions"
                type = "monotone"
                dataKey = "current"
                fill = "#70bbfd"
                stroke = "#70bbfd"
                fillOpacity = { 0.2 }
              />
              <Area name = { this.state.selectedDate.toDateString() + " transactions" }
                type = "monotone"
                dataKey = "selected"
                fill = "#4ce1b6"
                stroke = "#4ce1b6"
                fillOpacity = { 0.2 }
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

      }
      else {
        component = <div dir = "ltr">
          <ResponsiveContainer height = { 250 }
            className = "dashboard__area"
          >
            <AreaChart data = { this.props.drifft.productSales[0] } >
              <XAxis dataKey = "name"
                tickLine = { false }
              />
              <YAxis tickLine = { false }
                orientation = { 'left' }
              />
              <Tooltip { ...getTooltipStyles('defaultItems') } />
              <Legend />
              <CartesianGrid />
              <Area
                name = "Today's Sales"
                type = "monotone"
                dataKey = "current"
                fill = "#70bbfd"
                stroke = "#70bbfd"
                fillOpacity = { 0.2 }
              />
              <Area name = { this.state.selectedDate.toDateString() + " sales" }
                type = "monotone"
                dataKey = "selected"
                fill = "#4ce1b6"
                stroke = "#4ce1b6"
                fillOpacity = { 0.2 }
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

      }
    }
    return (
      <CustomPanel lg = { 8 }
        title = { "Daily Revenue" }
      >
        <div className = 'productSaleContainer'>
          <Collapse collapse = { this.state.collapse }
            toggle = { this.toggle.bind(this) }
            title = { this.state.toggleView }
            className = 'with-shadow productSales'
          >
            <div>
              <p type = "button"
                onClick = { () => { this.setState({ toggleView: "Gross Volume" }); this.setState({collapse: false}) } }
              >Gross Volume</p>
              <p type = "button"
                onClick = { () => { this.setState({ toggleView: "Successful payments" });this.setState({collapse: false}) } }
              >Successful payments</p>
            </div>
          </Collapse>
          <ThemeProvider theme = { materialTheme }>

            <DatePicker
              className = "datePicker"
              value = { this.state.selectedDate }
              onChange = { (dateChanged) => {
                let selectedDate = dateChanged.toDate()

                this.props.productSales(selectedDate, new Date(), this.props.drifft.travelAgent.utcOffset, this.props.drifft.currency)
                this.setState({ selectedDate: dateChanged.toDate() })
              }
              }
              variant = { "inline" }
              inputVariant = "standard"
              label = "Compared to"

            />
          </ThemeProvider>
        </div>
        {component}
      </CustomPanel>

    )
  }
}




export default ProductSales
