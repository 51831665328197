import React from 'react'
import Button from '../../../../components/button/Button'
import { connect } from 'react-redux'
import { checkExistingPhoneNumber, login, clearStore, changeUserType } from '../../../../redux/reducers/customerReducer'
import AddNewNumber from './AddNewNumber'
import { FullWideNotification } from '../../../../shared/components/Notification'
import NotificationSystem from 'rc-notification'
import PhoneInput from "../../../../components/phoneInput/PhoneInput"
import { correctMalaysiaAreaCode } from '../../../../helpers/utils'

@connect(
    store => ({
        customer: store.customer
    }),
    dispatch => ({
        checkExistingPhoneNumber: (currentNumber,clientUID) => dispatch(checkExistingPhoneNumber(currentNumber,clientUID)),
        login: (email, password) => dispatch(login(email, password)),
        clearStore: () => dispatch(clearStore()),
        changeUserType: (type) => dispatch(changeUserType(type))

    })
)

class ChangePhoneNumber extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            previousStage: false,
            nextStage: false,
            sucess: false,
            countryCode: "sg",
            number: '',
            error: ''
        }
    }

    async componentDidMount() {
      if(this.props.customer.userInfo){
        if (this.props.customer.userInfo.defaultClient.currencyId == 'MYR') {
            this.setState({ countryCode: 'my' })
          }
          else if (this.props.customer.userInfo.defaultClient.currencyId == 'BND') {
            this.setState({ countryCode: 'bn' })
          }    
      }
  }
  
    showFrozenNotification = () => {
      NotificationSystem.newInstance({ style: { top: 65 } }, notification=> {
        notification.notice({
          content: (
            <FullWideNotification 
              color = "success"
              message = "You are unable to change phone number while your account is frozen for security reasons. To continue using Split's services, please reach out to us at support@paywithsplit.co"
              
            />
          ),
          duration: 30,
          closable: true,
          style: { top: 0, left: 0 },
          className: 'full ltr-support'
        })
      })
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    showPassword() {
        this.setState({ showPassword: !this.state.showPassword })
    }

    onPhoneChange(phoneNumber, country) {
      this.setState({number: phoneNumber, country: country});
    }

    return = () => {
        this.props.toggle()
    }

    onSubmit = async () => {
      const number = this.state.country.countryCode == 'my' ? correctMalaysiaAreaCode(this.state.number) : this.state.number

      await this.props.checkExistingPhoneNumber(number,this.props.customer.userInfo.defaultClient.UID)
      if (!this.props.customer.error) {
          this.setState({ addingNumber: true })
      } else {
        if (this.props.customer.error.message == 'Account Frozen') {
          if (!this.state.frozenNotificationShown) {
            this.showFrozenNotification();
            this.setState({ frozenNotificationShown: true })
          }
        } else {
          this.setState({ error: this.props.customer.error })
          return { currentNumber: this.props.customer.error }
        }
      }
    }

    render() {
        if (this.state.addingNumber) {
            return (
              <AddNewNumber return = { this.return } />
            )
        }
        else return (
          <div className = "account">
            <div className = "account__wrapper">

              <div className = "account__card">
                <div className = "account__head"
                  style = { { marginBottom: "10px" } }
                >
                  <h3 className = "account__title">Change Phone Number</h3>
                </div>
                <h5 className = "account_sub"
                  style = { { marginBottom: "30px" } }
                >Please verify your current phone number</h5>
                <div>
                  <PhoneInput
                    label = 'Phone'
                    country = { this.state.countryCode }
                    value = { this.state.phone }
                    onChange = { (phone, country) => {this.onPhoneChange(phone,country)} }
                    name = { 'phone' }
                    preferredCountries = { ['sg', 'my', 'bn'] }
                    placeholder = { 'Country code & phone no.' }
                    error = { this.state.error }
                  />
                  <div style = { { display: "flex", marginTop: "1.4rem" } }>
                    <Button white
                      type = 'button'
                      onClick = { this.props.toggle }
                    > Back </Button>
                    <Button onClick={this.onSubmit}> Confirm </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}
export default ChangePhoneNumber