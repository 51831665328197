import ApiClient from '../../helpers/ApiClient'
import StorageService from "../../services/StorageServcies"

const initialState = {
    loggedIn: false,
    userInfo: null,
    remainingCredit: null,
    emailValidationData: null,
    userType: null,
    getInstalmentsSuccess: null,
    loading: false
}

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case "USER_EXISTS": {
      return {
        ...state,
        existingUser: action.existingUser,
        existingUserData: action.data,
        email: action.email
      }
    }

    case "USER_DOESNT_EXIST": {
      return {
        ...state,
        existingUser: action.existingUser,
        existingUserData: null
      }
    }
    case 'PROCESS_EMAIL_CHECK_DONE':
      return {
        ...state,
        emailValidationData: action.data,
      }
    case 'PROCESS_PHONE_CHECK_SUCCESS':
      return{
        ...state,
        phoneValidationCode: action.phoneValidationCode,
        securedNumber: action.securedNumber,
        phoneNumber: action.phoneNumber
      }
    case 'PROCESS_PHONE_VALIDATION_SUCCESS':
      return{
        ...state,
        validatePhone: action.validatePhone,
        hasPassOTP: action.hasPassOTP,
      }
    case 'PROCESS_ERROR':
      return{
        ...state,
        error: action.error
      }
    case "CLEAR_STORE": {
      return initialState
    }
    case "CLEAR_ERROR": {
      return{
        ...state,
        error: null
      }
    }
    case "PASSWORD_SUCCESS": {
      return{
        ...state,
        userInfo: action.userInfo
      }
    }
    case "CHANGE_USER":{
      return{
        ...state,
        userType: action.userType
      }
    }
    case "LOG_IN":{
      return{
        ...state,
        loggedIn: action.loggedIn,
        userInfo: action.data
      }
    }
    case "LOG_OUT":{
      return initialState
    }
    case "GET_INSTALMENTS":{
      return{
        ...state,
        getInstalmentsSuccess: action.success
      }
    }
    case "INSTALMENT_CARD":{
      return{
        ...state,
        instalmentCard: action.data
      }
    }
    case "EARLY_PAYMENT_RESULT":{
      return{
        ...state,
        payNowResult: action.result
      }
    }
    case "CHANGE_EXISTING_PASSWORD":{
      return{
        ...state,
        changePasswordResult: action.result
      }
    }
    case 'CREATE_SETUP_INTENT':
      return {
        ...state,
        setupIntent: action.setupIntent
      }        
    case 'PROCESS_LOADING':
      return {
        ...state,
        loading: true
      }
    case 'LOADING_FALSE':
      return {
        ...state,
        loading: false
      }
    case 'UPDATE_CREDIT_LIMIT_LEFT':
      return {
        ...state,
        remainingCredit: action.limit
      }
    case 'UPDATE_FROZEN_STATUS':
      return {
        ...state,
        isFrozen: action.isFrozen
      }
    case 'UPDATE_FROZEN_STATUS_FAILED':
      return {
        ...state,
        error: action.error
      }
    case 'RETURNING_PHONE_VALIDATION_SUCCESS':
      return {
        ...state,
        isValidNumber: true
      }
    default: return state
  }   
}


export const checkExistingUser = (email) => {
  return async (dispatch) => {
    try{
      // Do API call to check if client exists in database.
      let data = await ApiClient.post('/newuser/findByEmailOnly',{email})

      if(!data.exist) {
        dispatch({
          type: "USER_DOESNT_EXIST",
          existingUser: false
        })
      } else {
        dispatch({
        type: "USER_EXISTS",
        existingUser: true,
        email: email, 
        data
      })
    }
    }
    catch (e){
      throw new Error(e.message)
    }
  }
}

export const checkPhone = (email, number, sendCall = false) => {
  return async (dispatch) =>{
    try{
      const data = await ApiClient.post('/newuser/check', { email, number, sendCall })

      dispatch({
        type: 'PROCESS_PHONE_CHECK_SUCCESS',
        phoneValidationCode: data.phoneValidationCode,
        phoneNumber: number
      })
    }catch(e){
      dispatch({
        type: 'PROCESS_ERROR',
        error: e
      })
    }

  }
}

export const validateNumber = (email, number) => {
  return async (dispatch) => {
    try {
      const data = await ApiClient.post('/newuser/validateNumber', {email, number});

      if (data) {
        dispatch({ 
          type: 'RETURNING_PHONE_VALIDATION_SUCCESS',
          isValidNumber: true
        })
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
}

export const checkNewPhone = (phoneNumber,clientuid,sendCall = false) => {
  return async (dispatch) =>{
    dispatch({ type: 'CLEAR_ERROR'})

    try{
      const phone = await ApiClient.post('/phone/create', {number: parseInt(phoneNumber),clientID: clientuid,location: 'drifft'})
      const data = await ApiClient.post('/phone/check', {clientUID: clientuid, sendCall})
      
      dispatch({
        type: 'PROCESS_PHONE_CHECK_SUCCESS',
        phoneValidationCode: data.phoneValidationCode,
        securedNumber: phone.securedNumber
      })
    }catch(e){
      dispatch({
        type: 'PROCESS_ERROR',
        error: e
      })
    }

  }
}

export const validationNewPhone = (clientUID, phoneValidationCode) => {
  return async (dispatch) => {
    dispatch({ type: 'CLEAR_ERROR'})

    try {
      const data = await ApiClient.post('/phone/validate', { clientUID, phoneValidationCode, location: 'drifft' })

      dispatch({
        type: 'PROCESS_PHONE_VALIDATION_SUCCESS',
        validatePhone: data.clientToken,
        storePhone: true
      })
    } catch (e) {
      dispatch({
        type: 'PROCESS_ERROR',
        error: e
      })
    }
  }
}

export const validationPhone = (email, phoneValidationCode) => {
  return async (dispatch) => {
    dispatch({ type: 'CLEAR_ERROR'})
    try {
      const data = await ApiClient.post('/newuser/validate', { email, phoneValidationCode })

      dispatch({
        type: 'PROCESS_PHONE_VALIDATION_SUCCESS',
        hasPassOTP: data.isSuccess,
      })
    } catch (e) {
      dispatch({
        type: 'PROCESS_ERROR',
        error: e
      })
    }
  }
}

export const clearStore = () =>{
  return dispatch => {(dispatch({ type: 'CLEAR_STORE' }))}
}

export const changePassword = ( phoneNumber, password, email) =>{
  return async (dispatch) =>{
    dispatch({ type: 'CLEAR_ERROR'})

    try{
      const data = await ApiClient.post('/newuser/createpassword',{ phoneNumber, password, email })

      if(data.passwordSet){
        let userInfo = {email: email, password: password}

        dispatch({
          type: 'PASSWORD_SUCCESS',
          userInfo: userInfo
        })  
      }
      else{
        dispatch({type: 'PROCESS_ERROR',error: {code: 400,message: "could not set password."}})
      }
    } catch(e) {
      dispatch({
        type: 'PROCESS_ERROR',
        error: e
      })
    }
  }
}

export const changeUserType = (type) =>{
  return (dispatch) =>{
    dispatch({type: 'CHANGE_USER',userType: type})
  }
}

export const login = (email,password)=>{
  return async (dispatch)=>{
    try {
      dispatch({
        type: "CLEAR_ERROR"
      })

      const data = await ApiClient.post('/newuser/login',{email,password})

      ApiClient.setToken(data.token)
      StorageService.set('client_JWT',data)

      dispatch({
        type: 'LOG_IN',
        loggedIn: true,
        data
      })

      const clientID = data.defaultClient.UID

      let creditsLeft

      try{
        creditsLeft = await ApiClient.get('/client/ClientCredit', {clientID})
      } catch(e){
        creditsLeft = null
      }

      dispatch({
        type: 'UPDATE_CREDIT_LIMIT_LEFT',
        limit: creditsLeft
      })

    } catch(e) {

      dispatch({
        type: 'PROCESS_ERROR',
        error: e
      })
      dispatch({
        type: 'LOG_IN',
        loggedIn: false
      })
    }
  }
}

export const checkFrozenStatus = (clientID) => {
  return async (dispatch) => {
    try {
      const isFrozen = await ApiClient.get('/client/getFrozenStatus', {clientID})

      dispatch({
        type: 'UPDATE_FROZEN_STATUS',
        isFrozen
      })
    } catch (e) {
      dispatch({
        type: 'UPDATE_FROZEN_STATUS_FAILED',
        error: e
      })
    }
  }
}

export const getCreditLimit = () => {
  return async (dispatch, getState) => {
    try {
      const clientID = getState().customer.userInfo.defaultClient.UID
      let creditsLeft

      try{
        creditsLeft = await ApiClient.get('/client/ClientCredit', {clientID})
      } catch(e){
        creditsLeft = null
      }

      
      dispatch({
        type: 'UPDATE_CREDIT_LIMIT_LEFT',
        limit: creditsLeft
      })
    } catch (e) {
      dispatch({
        type: 'PROCESS_ERROR',
        error: e
      })
    }
  }
}

export const getInstalments = (clients)=>{
  return async (dispatch)=>{
    let data = await ApiClient.get('/customer/getinstalments',{clients})

    dispatch({
      type: "GET_INSTALMENTS",
      success: data
    })
  }
}

export const getInstalmentCard = (clients) =>{
  return async (dispatch)=>{
    let data = await ApiClient.get('/customer/getinstalmentcard',{clients})

    dispatch({type: "INSTALMENT_CARD",data})
  }
}

export const returnCustomer = (data) => {
  return (dispatch) => {
    ApiClient.setToken(data.token)
    dispatch({
      type: 'LOG_IN',
      loggedIn: true,
      data
    })
  }
}

export const logout = ()=>{
  return (dispatch)=>{
    StorageService.remove('client_JWT')
    dispatch({type: "LOG_OUT"})
  }
}

export const submitEarlyPayment = (orderId, paymentType) =>{
  return async (dispatch, getState) =>{
    try{
      dispatch({type: "CLEAR_ERROR"})
      await ApiClient.post('/customer/submitearlypayment',{orderId,paymentType})
      
      const clientHasLoyalty = getState().customer.userInfo.defaultClient.loyaltyLevel
      const clientID = getState().customer.userInfo.defaultClient.UID

      if (paymentType === 'toPayFully' && clientHasLoyalty){
        const creditsLeft = await ApiClient.get('/client/ClientCredit', {clientID})

        dispatch({
          type: 'UPDATE_CREDIT_LIMIT_LEFT',
          limit: creditsLeft
        })
      }

      dispatch({type: "EARLY_PAYMENT_RESULT", result: true})
    }
    catch(e){
      dispatch({type: "EARLY_PAYMENT_RESULT", result: false})
      dispatch({type: "PROCESS_ERROR", error: e})
    }
  }
}

export const changeExistingPassword = (password,currentPassword,email)=>{
  return async (dispatch)=>{
    try{
      dispatch({type: "CLEAR_ERROR"})
      await ApiClient.post('/customer/changeexistingpassword',{password,currentPassword,email})
      dispatch({type: "CHANGE_EXISTING_PASSWORD",result: true})
    } catch(e){
      dispatch({type: "CHANGE_EXISTING_PASSWORD",result: false})
      dispatch({type: "PROCESS_ERROR",error: e})
    }
  }
}

export const createSetupIntent = (force3DS) =>{
  return async (dispatch, getState) =>{
    try{
      dispatch({
        type: "PROCESS_LOADING"
      })
      let setupIntent = await ApiClient.post(
        '/client/create-setup-intent', 
        {
          clientUID: getState().customer.userInfo.defaultPaymentGateway.clientUID,
          force3DS: force3DS,
          paymentGatewayId: getState().customer.userInfo.defaultPaymentGateway.id
        }
      )

      dispatch({
        type: "CREATE_SETUP_INTENT",
        setupIntent: setupIntent
      })
    }catch(e){
      dispatch({
        type: "PROCESS_ERROR",
        error: e
      })
      dispatch({
        type: 'LOADING_FALSE'
      })
    }
  }
}

export const storeCreditCard = (paymentMethodId) =>{
  return async(dispatch,getState) =>{
    try{
      await ApiClient.post(
        '/client/store-credit-card',
        {
          clientUID: getState().customer.userInfo.defaultPaymentGateway.clientUID,
          paymentMethodId: paymentMethodId,
          paymentGatewayId: getState().customer.userInfo.defaultPaymentGateway.id
        }
      )
      
      dispatch({
        type: 'LOADING_FALSE'
      })

    } catch(e){
      dispatch({
        type: 'PROCESS_ERROR',
        error: e
      })
      dispatch({
        type: 'LOADING_FALSE'
      })
    }
  }
}

export const creditCardCreate = (input) => {
  return async (dispatch) => {
    try {
      await ApiClient.post(
        '/client/creditCard', input
      )
      dispatch({
        type: 'LOADING_FALSE'
      })
    } catch (e) {
      dispatch({
        type: 'PROCESS_ERROR',
        error: e
      })
      dispatch({
        type: 'LOADING_FALSE'
      })
    }
  }
}

export const checkExistingPhoneNumber = (currentNumber, clientUID) =>{
  return async (dispatch)=>{
    try {
      dispatch({
        type: 'CLEAR_ERROR',
      })  
      const data = await ApiClient.get(
        '/customer/checkExistingPhoneNumber', {currentNumber, clientUID}
      )

      if(!data.success){
        dispatch({
          type: 'PROCESS_ERROR',
          error: 'Looks like the number does not match. Please try again.'
        })  
      }
    } catch (e) {
      dispatch({
        type: 'PROCESS_ERROR',
        error: e
      })
    }

  }
}
