import React, { PureComponent } from 'react'
import {Container} from 'reactstrap'
import { connect } from "react-redux"
import HorizontalFormItem from './components/HorizontalFormItem'
import {createDrifftItem, clearLink} from '../../../redux/reducers/drifftReducer'
import NotificationSystem from 'rc-notification'
let notification = null

const showNotification = (notificationContent) => {
  notification.notice({
    content: notificationContent,
    duration: 3,
    closable: true,
    style: { top: 0, left: 0 },
    className: 'full ltr-support',
  })
}

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    createDrifftItem: (dataForm, multiLink) => dispatch(createDrifftItem(dataForm, multiLink)),
    clearLink: () => dispatch(clearLink())
  })
)

class NewItemPage extends PureComponent{

  componentDidMount() {
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n)
  }

  componentWillUnmount(){
    this.props.clearLink()
  }

  showResults = (formData) => {
    this.props.createDrifftItem(formData, true)
  }

  render(){
    return(
      <Container className = "invoice">
  
        <HorizontalFormItem 
          onSubmit = { this.showResults } 
          showNotification = { (notificationContent) => showNotification(notificationContent) }
        />
  
      </Container>

    )
  }

} 
export default NewItemPage
