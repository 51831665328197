import React from 'react'
import { Container, Row, Card } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'
import Button from '../../../components/button/Button'
import CircularStatic from './CircularProgress/index'
import './instalmentcard.css'
import moment from 'moment'
class InstalmentCard extends React.Component {

  renderInstalmentTable() {

    const firstUpcomingIndex = this.props.instalments.indexOf(this.props.instalments.find((instalment) => ['UPCOMING', 'LATE'].includes(instalment.status)))

    return this.props.instalments.map((instalment, index) => {
      const { date, status, amount } = instalment

      let renderStatus, formattedDate

      if (!this.props.payNow && index === firstUpcomingIndex) {
        renderStatus = 'WILL BE CHARGED'
        formattedDate = moment().add(index - firstUpcomingIndex, 'month').format("DD MMM YYYY")
      } else if (index > firstUpcomingIndex){
        if (this.props.paymentType === 'toPayFully') {
          renderStatus = 'WILL BE CHARGED'
          formattedDate = moment().format("DD MMM YYYY")
        } else if (this.props.payNow){
          formattedDate = moment(date).format("DD MMM YYYY")
        } else {
          formattedDate = moment().add(index - firstUpcomingIndex, 'month').format("DD MMM YYYY")
        }
      } else {
        renderStatus = status
        formattedDate = moment(date).format("DD MMM YYYY")
      }

      return (
        <tr key = { date + index }
          style = { { alignContent: "center" } }
        >
          <td>{formattedDate}</td>
          {
            renderStatus == 'WILL BE CHARGED' ? 
              <td className = { 'status-CHARGE' }>{renderStatus}</td> 
            : <td className = { 'status-' + status }>{status}</td>}
          <td>{amount}</td>
        </tr>
        )
    })
  }

  render() {
    return (
      <Card className = "shadow"
        style = { { marginBottom: '40px' } }
      >
        {this.props.flexible ?
          <Container>
            <Row>
              <h5 style = { { fontWeight: '500', marginTop: '20px', marginLeft: '10px' } }>{'Order ID: ' + this.props.orderId}</h5>
            </Row>
            <Row>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Store</th>
                    <th>Purchase date</th>
                    <th>Total</th>
                    <th>Progress</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style = { { alignContent: "center" } }>
                    <td>{this.props.data.shopName}</td>
                    <td>{this.props.data.orderDate}</td>
                    <td>{this.props.data.total}</td>
                    <td>
                      <CircularStatic percentComplete = { parseInt(this.props.data.instalments.complete / this.props.data.instalments.total * 100) } />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5 style = { { fontWeight: '500', marginTop: '20px', marginLeft: '20px' } }>Your new payment schedule</h5>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Instalment Date</th>
                    <th>Status</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderInstalmentTable()}
                </tbody>
              </Table>

            </Row>
          </Container>
          :
          <Container>
            <Row style = { {justifyContent: "space-between",alignItems: 'baseline'} }>
              <h5 style = { { fontWeight: '500', marginTop: '20px', marginLeft: '20px' } }>{'Order ID: ' + this.props.orderId}</h5>
              {this.props.payNow &&
              <Link to = { '/customer/paynow/' + this.props.internalId }>
                <Button style = { {marginRight: '20px'} }
                  className = 'paynow'
                >Pay off early</Button>
              </Link>
  }
            </Row>
            <Row>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Store</th>
                    <th>Purchase date</th>
                    <th>Total</th>
                    <th>Progress</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style = { { alignContent: "center" } }>
                    <td>{this.props.data.shopName}</td>
                    <td>{this.props.data.orderDate}</td>
                    <td>{this.props.data.total}</td>
                    <td>
                      <CircularStatic percentComplete = { parseInt(this.props.data.instalments.complete / this.props.data.instalments.total * 100) } />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5 style = { { fontWeight: '500', marginTop: '20px', marginLeft: '20px' } }>Your new payment schedule</h5>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Instalment Date</th>
                    <th>Status</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderInstalmentTable()}
                </tbody>
              </Table>

            </Row>
          </Container>
        }            </Card>
    )
  }
}
export default InstalmentCard