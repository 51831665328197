import React, { PureComponent } from 'react'
import { Container } from 'reactstrap'
import RefundFormOrder from './components/RefundFormOrder'
import { connect } from "react-redux"
import {createDrifftLink, sendDriffEmail} from '../../../redux/reducers/drifftReducer'

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    createDrifftLink: (dataForm, multiLink,type) => dispatch(createDrifftLink(dataForm, multiLink,type)),
    sendDriffEmail: (payload) => dispatch(sendDriffEmail(payload))
  })
)


class RefundRequests extends PureComponent{ 
  constructor(){
    super()
    this.state = {
      clearData: false
    }
  }

  render(){
    return(
      <Container className = "invoice">
        <RefundFormOrder />
      </Container>
    )
  }
}

export default RefundRequests
