import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Button from '../../../../components/button/Button'
import TextBox from '../../../../components/textBox/TextBox'
import { Form, Field } from 'react-final-form'

import './LogInForm.css'
class LogInForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super()
    this.state = {
      showPassword: false,
      newUser: null,
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      ...this.state,
      nextProps
    })
  }

  showPassword = (e) => {
    e.preventDefault()
    this.setState(prevState => ({ showPassword: !prevState.showPassword }))
  };

  render() {
    const { onSubmit } = this.props

    return (
      <Form
        onSubmit = { onSubmit }
      // validate={validateForm}
        render = { ({ handleSubmit, form }) => (
          <form onSubmit = { handleSubmit }>
            <Field name = "email">
              {({ input, meta }) => (
                <TextBox
                  label = 'Email'
                  name = "email"
                  { ...input }
                  error = { (meta.error || (meta.submitError)) && meta.touched && (meta.error || meta.submitError) }
                />
            )}
            </Field>                        
            <Field name = "password">
              {({ input, meta }) => (
                <TextBox
                  label = 'Password'
                  name = "password"
                  component = "input"
                  { ...input }
                  password
                  error = { (meta.error || (meta.submitError)) && meta.touched && (meta.error || meta.submitError) }
                />
          )}
            </Field>                        

            <div style = { {display: 'flex',justifyContent: 'space-between',width: '100%'} }>
              <Button type = "submit">Submit</Button>
              <button type = "button"
                className = "new-user-link"
                onClick = { this.props.newUserLink }
              >First-time login <br /> <br /> Forgot Password</button>
            </div>
            <div style = { {width: '100%',textAlign: 'left'} }>
              <Link to = '/merchant/login'>
                <button type = "button" 
                  className = "merchant-link"
                >Are you a Split partner? Click here.
                </button>
              </Link>
            </div>
          </form>
        ) }
      />
    
    )
  }
}

export default LogInForm
