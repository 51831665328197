import React from 'react'
import Button from '../../../components/button/Button'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {login,clearStore,changeUserType} from '../../../redux/reducers/customerReducer'
@connect(
    store => ({
      customer: store.customer
    }),
    dispatch => ({
      login: (email,password)=>dispatch(login(email,password)),
      clearStore: ()=>dispatch(clearStore()),
      changeUserType: (type) => dispatch(changeUserType(type))
    })
)  
class StageFinish extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nextStage: false,
            email: this.props.customer.userInfo.email,
            password: this.props.customer.userInfo.password
        }
      }

    async logIn(){
      try{
        //clear state first to that of original page, then log in
        this.props.clearStore()
        this.props.changeUserType("customer")
        await this.props.login(this.state.email,this.state.password)
        this.setState({ nextStage: true })
      }catch(e){
        throw e
      }
    }

    render(){
        if (this.state.nextStage) return <Redirect to = '/customer/dashboard' />
        return(
          <div className = "account">
            <div className = "account__wrapper">
              <div className = "account__card">
                <div className = "account__head"
                  style = { { marginBottom: "10px" } }
                >
                  <h3 className = "account__title">You are all set!</h3>
                </div>
                <h5 className = "account_sub"
                  style = { { marginBottom: "30px" } }
                >Your Split account has been created, you can now log in.</h5>
                <div style = { { marginBottom: "30px" } }>
                  <Button onClick = { this.logIn.bind(this) }>Log In</Button>
                </div>
              </div>
            </div>
          </div>
    
            )
    }
}
export default StageFinish