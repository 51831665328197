import React, { Component } from 'react'
import { MDBDataTable } from 'mdbreact'
import {
    Card, CardBody, Col, Row, Container
} from 'reactstrap'
import Button from '../../../components/button/Button'
import PastBookingSearchForm from './components/PastBookingSearchForm'
import { connect } from "react-redux"
import { loadPastBookings, setV2FELink, loadBranches } from '../../../redux/reducers/drifftReducer'
import moment from 'moment'
import SummaryTable from './components/SummaryTable'
import RefreshIcon from '@material-ui/icons/Refresh'
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from 'pdfmake/build/vfs_fonts.js'
import { splitLogoImage } from '../../../helpers/constants'
pdfMake.vfs = pdfFonts.pdfMake.vfs

@connect(
    store => ({
        drifft: store.drifft
    }),
    dispatch => ({
        loadPastBookings: (startDate, endDate, branch) => dispatch(loadPastBookings(startDate, endDate, branch)),
        loadBranches: () => dispatch(loadBranches()),
        setV2FELink: () => dispatch(setV2FELink())
    })
)
class DatatablePage extends Component {
    constructor(props) {
        super(props)

        //console.log('rows:', this.props.drifft.pastbooking.rows)
        this.state = {
            data: {
                columns: this.getColumns(),
                rows: this.props.drifft.pastbooking.rows
            }
        }
    }

    componentDidMount() {
        if(this.props.drifft.travelAgentUser.isAdmin){
            this.props.loadBranches()
        }
        this.props.loadPastBookings()

    }

    componentWillReceiveProps = (nextProps) => {
        for (let i = 0; i < nextProps.drifft.pastbooking.rows.length; i++) {
            let { externalId, price, currency, createdAt,branchId } = nextProps.drifft.pastbooking.rows[i]

            nextProps.drifft.pastbooking.rows[i].downloadInvoice =
              <Button onClick = { ()=>{this.generateOrderInvoice(externalId, price, currency, createdAt,branchId )} }>
                    Download Invoice
              </Button>
        }

        if (this.props.drifft.travelAgentUser.permission.viewRefund) {
            for (let i = 0; i < nextProps.drifft.pastbooking.rows.length; i++) {
                let ticketId = nextProps.drifft.pastbooking.rows[i].externalId

                nextProps.drifft.pastbooking.rows[i].refundButton = {}
                // TODO: Change this so it doesn't create a new function in onclick
                nextProps.drifft.pastbooking.rows[i].refundButton = nextProps.drifft.pastbooking.rows[i].refund ? nextProps.drifft.pastbooking.rows[i].refund :
                <Button white
                  onClick = { () => {
                        window.open("/merchant/settings/refundrequest" + "?ticketId=" + ticketId)
                    } }
                >
                        Refund
                </Button>
            }
        }

        // Create function that updates column values so MDR gets updated
        this.setState({
            data: {
                columns: this.getColumns(),
                rows: nextProps.drifft.pastbooking.rows
            }
        })

    }

    generateOrderInvoice = (externalId, price, currency, createdAt,branchId) => {
        let docDefinition = {

            pageMargins: [35, 40, 35, 20],
            footer: function (currentPage, pageCount) {
              return ({
                columns: [
                  { text: 'This is computer generated document, no signature is required', style: 'footerText' },

                  { width: 67, text: currentPage.toString() + ' of ' + pageCount, alignment: 'left' }
                ],

              })
            },
            content: [
              {
                image: 'Logo',
                width: 124,
                margin: [3, 0, 0, 10]
              },
              { text: 'PAY WITH SPLIT SDN. BHD. 201901012836 (1322164-H)', style: 'subheader' },
              ' ',
              ' ',
              { canvas: [{ type: 'line', x1: 5, y1: 0, x2: 515, y2: 0, lineWidth: 1, lineColor: '#666366' }] },

              {
                style: 'tableSplitStatment',
                color: 'black',
                table: {
                  widths: ['50%', '50%'],
                  body: [

                    [{ text: `Order Confirmation: ${this.props.drifft.travelAgent.name} (${branchId})`, alignment: 'left', bold: true, fontSize: '28', colSpan: 2, margin: [0, 20, 0, 20], border: [false, false, false, false] }, {}],


                  ]

                }
              },
              { canvas: [{ type: 'line', x1: 5, y1: 0, x2: 515, y2: 0, lineWidth: 1, lineColor: '#666366' }] },
              ' ', ' ',
              
              {text: `Order ID: ${externalId}`, fontSize: '16'},
              ' ',
              {text: `Order Amount: ${currency}${price}`,fontSize: '16'},
              ' ',
              {text: `Date/Time of payment: ${createdAt}`,fontSize: '16'}
              


            ],
            styles: {

              subheader: {
                fontSize: 12,
                bold: false,
                margin: [5, 0, 0, 0],
                color: '#666366'
              },
              footerText: {
                fontSize: 12,
                bold: false,
                margin: [32, 0, 0, 0],
                color: '#666366'
              },
              emptyUnderLine: {
                decoration: 'underline',
                color: '#666366',
                margin: [20, 0, 0, 0]
              },
              tableSplitStatment: {
                margin: [0, 0, 0, 0],
                fontSize: 12
              },
              tableOrange: {
                margin: [0, 0, 0, 0],
                fontSize: 9
              },
              text: {
                margin: [5, 0, 0, 0]
              }

            },

            images: {
              'Logo': splitLogoImage
            }
          }
  
        pdfMake.createPdf(docDefinition).download(`${this.props.drifft.travelAgent.name} (${branchId}) ${externalId}`)
    }
 
    getColumns = () => {
        let columns = [
            {
                label: 'Name',
                field: 'name',
            },
            {
                label: 'Email ID',
                field: 'email',
            },
            {
                label: 'Shipping Address',
                field: 'address',
            },
            {
                label: 'ID',
                field: 'externalId',
            },
            {
                label: 'Branch',
                field: 'branchId',
            },
            {
                label: 'Date of First Payment',
                field: 'createdAt',
                sort: 'asc'
            },
            {
                label: 'Price',
                field: 'price',
            },
            {
                label: 'Currency',
                field: 'currency',
            },
            {
                label: 'Payment Type',
                field: 'paymentType',
            },
            {
              label: 'Refund Amount',
              field: 'refundAmount'
            },
            {
                label: 'Download Invoice',
                field: 'downloadInvoice'
            },
        ]
            
        if (this.props.drifft.travelAgentUser.isAdmin && this.props.drifft.travelAgentUser.permission.viewRefund) {
            columns.push({
                label: 'Refund',
                field: 'refundButton'
            },
            {
              label: 'MDR',
              field: 'MDRFee',
            },
            )
        }
        
        columns.push({
          label: 'Card Network',
          field: 'cardNetwork'
        })

        return columns
    }

    showResults = (values) => {
        values = values === undefined ? this.state.storedValues : values
        if(values){
            if (values.interval_date != null) {
                let startDate = moment(values.interval_date.start)
                let endDate = moment(values.interval_date.end)

                this.props.loadPastBookings(startDate.format('YYYY-MM-DD'),
                    endDate.format('YYYY-MM-DD'),values.branch)
            } else {
                this.props.loadPastBookings(null,null,values.branch)
            }    
            this.setState({storedValues: values})
        }else{
            this.props.loadPastBookings()
        }
    }


    render() {
        return (
          <div>
            <Container className = "dashboard"
              style = { { paddingLeft: '0px', paddingRight: '0px' } }
            >
              <Col style = { {margin: '20px 0', display: 'flex',justifyContent: 'space-between'} }>
                <h3 className = "page-title" >{`${this.props.drifft.travelAgentUser.branch ? this.props.drifft.travelAgentUser.branch.name : 'Past Payments'} Overview`}</h3>
                <Button style = { {height: '48px'} }
                  onClick = { ()=>{this.showResults()} }
                ><RefreshIcon /> Refresh </Button>
              </Col>
              <Row>
                <Col xs = { 12 }
                  sm = { 12 }
                  md = { 4 }
                  lg = { 4 }
                  xl = { 4 }
                >
                  <PastBookingSearchForm onSubmit = { this.showResults }
                    branchList = { this.props.drifft.branchList }
                    isAdmin = { this.props.drifft.travelAgentUser.isAdmin }
                  />
                </Col>
                <Col xs = { 12 }
                  sm = { 12 }
                  md = { 8 }
                  lg = { 8 }
                  xl = { 8 }
                >
                  <SummaryTable summaryTable = { this.props.drifft.summaryTable.rows } 
                    isAdmin = { this.props.drifft.travelAgentUser.isAdmin } />
                </Col>
              </Row>
              <Row>
                <Col xs = { 12 }
                  sm = { 12 }
                  md = { 12 }
                  lg = { 12 }
                  xl = { 12 }
                >
                  <Card>
                    <CardBody className = "grid">
                      <MDBDataTable responsive
                        noBottomColumns
                        entriesLabel = { 'show entries:    ' }
                        data = { {columns: this.state.data.columns,rows: this.state.data.rows} }
                      />
                    </CardBody>
                  </Card>

                </Col>
              </Row>

            </Container>

          </div>
        )
    }

}

export default DatatablePage