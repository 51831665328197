import React from 'react'
import { Table } from 'reactstrap'
import Panel from '../../../../../shared/components/Panel'
import { recentOrders } from '../../../../../redux/reducers/drifftReducer'
import { connect } from "react-redux"

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    recentOrders: (currency) => dispatch(recentOrders(currency))
  })
)

class RecentOrders extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount(){
      this.props.recentOrders(this.props.drifft.currency)
      this.timer = setInterval(() => this.props.recentOrders(this.props.drifft.currency), Math.floor(Math.random() * 10000) + 60000)
  }

  componentWillUpdate(nextProps) {
    if (nextProps.drifft.currency !== this.props.drifft.currency || (this.props.drifft.hasJWT && !this.props.drifft.loggedIn && nextProps.drifft.loggedIn)) {
      this.props.recentOrders(nextProps.drifft.currency)
      this.timer = setInterval(() => this.props.recentOrders(nextProps.drifft.currency), Math.floor(Math.random() * 10000) + 60000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  renderTableData() {
    return this.props.drifft.recentOrders.map((order) => {
      const { externalOrderId, price, branchId } = order
      let text = branchId != null ? `(${branchId})` : ''
 
      return (<tr key = { externalOrderId }>
        <td>{externalOrderId} {text}</td>
        <td>{price}</td>
      </tr>)
    })

  }

  render() {
    return (
      <Panel lg = { 4 }
        title = { "Recent orders" }
      >
        {this.props.drifft.recentOrders === null ?
          <div >
            <div style = { { display: 'block', textAlign: 'center', paddingTop: '2rem' } }>
              <div className = "load__icon-wrap">
                <svg className = "load__icon">
                  <path fill = "#ff8600"
                    d = "M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                  />
                </svg>
              </div>
            </div>
          </div>
          :
          <Table responsive
            className = "table--bordered"
          >
            <thead>
              <tr>
                <th>Order Number</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {this.renderTableData()}
            </tbody>
          </Table>
        }
      </Panel>
    )
  }



}
export default RecentOrders
