import React from "react"
import classNames from 'classnames'
import styles from './styles.module.css'
import Masked from './Masked'


const TextBox = (props) => {
  let { className, error, mask, type, ...other} = props

  if ( mask ) return <Masked { ...props } />
  return <input { ...other }
    type = { type }
    className = { classNames([styles.textBox, error && styles.error, className]) }
         />
}

export default TextBox