export function isDeviceVertical() {
  return window.innerHeight > window.innerWidth || window.innerWidth < 700
}

export function correctMalaysiaAreaCode(number) {
  const numberString = number.toString();
  if (numberString.length > 11 && numberString[2] == '0') {
    const correctedNumber = numberString.slice(0, 2) + numberString.slice(3);
    return parseInt(correctedNumber);
  }
  return number;
}