/* eslint-disable */
const validate = (values) => {
    const errors = {};
    if (!values.price) {
      errors.price = 'Price field shouldn’t be empty and positive numbers only';
    }
    if(!values.itemTitle) {
      errors.itemTitle = `Item title shouldn't be empty`;
    }
    return errors;
  };
  
  export default validate;
  