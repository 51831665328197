import React from 'react'
import Panel from '../../../../shared/components/Panel'
import InstalmentCard from '../../../../shared/components/instalmentCard/index'
import {connect} from 'react-redux'
import {getInstalmentCard} from '../../../../redux/reducers/customerReducer'

@connect(
    store => ({
      customer: store.customer
    }),
    dispatch => ({
        getInstalmentCard: (clients) => dispatch(getInstalmentCard(clients))
    })
  )
  
class ActiveInstalments extends React.Component{
    constructor(props) {
      super(props)
      if (this.props.customer.userInfo && !this.props.customer.instalmentCard) {
        this.props.getInstalmentCard(this.props.customer.userInfo.clients)
      }
    }

    componentDidUpdate() {
      if (this.props.customer.userInfo && !this.props.customer.instalmentCard) {
        this.props.getInstalmentCard(this.props.customer.userInfo.clients)
      }
    }

    renderCards(){
      return this.props.customer.instalmentCard.map((instalment)=>{
        const {
          shopName,
          orderId,
          internalId, 
          total,
          orderDate,
          instalments,
          instalmentDetails
      } = instalment

      return(<InstalmentCard payNow
        key = { orderId }
        orderId = { orderId }
        internalId = { internalId }
        data = { {shopName: shopName,orderDate: orderDate,total: total,instalments: instalments} }
        instalments = { instalmentDetails }
             />)
      })
    }

    render() {
      if(!this.props.customer.instalmentCard){
        return(
          <Panel lg = { 6 }
            md = { 12 }
            sm = { 12 }
            largeFont
            title = { "Active instalments" }
            noMinus = 'true'
          >
            <div style = { { display: 'block',margin: 'auto',width: '1%' } }>
              <div className = "load__icon-wrap">
                <svg className = "load__icon">
                  <path fill = "#ff8600"
                    d = "M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                  />
                </svg>
              </div>
            </div>              
          </Panel>  

        )
      }
      if(this.props.customer.instalmentCard.length == 0){
        return (
          <Panel lg = { 6 }
            md = { 12 }
            sm = { 12 }
            title = { "Active instalments" }
            largeFont
            noMinus = 'true'
          >
            <div> Looks like you have no active purchases at the moment, <span 
              style = { {textDecoration: 'underline',cursor: 'pointer'} } 
              onClick = { ()=>{window.open('https://paywithsplit.co/where-to-shop-all-featured/')} }
                                                                         >click here</span> to find out where you can shop with Split.</div>
          </Panel>
        )
      }
    else return(
      <Panel lg = { 6 }
        md = { 12 }
        sm = { 12 }
        title = { "Active instalments" }
        largeFont
        noMinus = 'true'
      >
                
        { this.renderCards()
}                
      </Panel>  
        )
    }
}

export default ActiveInstalments