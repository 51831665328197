import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import TopbarSidebarButton from './TopbarSidebarButton'

@connect(
  store => ({
    drifft: store.drifft
  })
)

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props

    return (
      <div className = "topbar">
        <div className = "topbar__wrapper">
          <div className = "topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility = { changeMobileSidebarVisibility }
              changeSidebarVisibility = { changeSidebarVisibility }
            />
            <Link className = "topbar__logo"
              to = { "/customer/dashboard" }
            />
          </div>
          <div className = "topbar__right" />
        </div>
      </div>
    )
  }
}

export default Topbar
