import React from 'react'
import { Redirect } from 'react-router-dom'
import Button from '../../../components/button/Button'
import { connect } from 'react-redux'
import { checkExistingUser, changeUserType } from '../../../redux/reducers/customerReducer'
import TextBox from '../../../components/textBox/TextBox'
@connect(
  store => ({
    customer: store.customer
  }),
  dispatch => ({
    checkExistingUser: (email) => dispatch(checkExistingUser(email)),
    changeUserType: (type) => dispatch(changeUserType(type))
  })
)
class StageCheckUser extends React.Component {
  constructor(props) {
    super(props)
    this.props.changeUserType("customer")
    this.state = {
      newUserStage: false,
      existingUserStage: false,
      errorPage: false,
      previousStage: false,
      email: "",
      showError: false
    }
  }

  async handleSubmit(event) {
    event.preventDefault()
    await this.props.checkExistingUser(this.state.email)
    if (this.props.customer.existingUser === false) {
      this.setState({ showError: true })
    }
    if (this.props.customer.existingUser) {
      if (this.props.customer.existingUserData.phone[0]) {
        this.setState({ existingUserStage: true })
      } else {
        this.setState({ errorPage: true })
      }
    }
  }

  handleChange(event) {
    this.setState({ email: event.target.value.toLowerCase() })
  }

  render() {
    if (this.state.previousStage) return <Redirect to = '/customer/login' />
    if (this.state.existingUserStage) return <Redirect to = '/customer/returninguser/phone' />
    if (this.state.errorPage) return <Redirect to = '/customer/returninguser/error' />
    return (
      <div className = "account">
        <div className = "account__wrapper">
          <div className = "account__card">
            <div className = "account__head"
              style = { { marginBottom: "10px" } }
            >
              <h3 className = "account__title">Let's get started!</h3>
            </div>
            <h5 className = "account_sub"
              style = { { marginBottom: "30px" } }
            />
            <form onSubmit = { this.handleSubmit.bind(this) }>
              <TextBox 
                onChange = { this.handleChange.bind(this) } 
                label = "Email"
                placeholder = "Email ID" 
                message = "Please enter the email ID that you used to make your Split instalment plan."
                error = { this.state.showError && `Oops! Looks like you don't have a Split account yet.` }
              />
              <Button type = "button"
                white
                onClick = { () => { this.setState({ previousStage: true }) } }
              > Back </Button>
              <Button type = "submit" > Next </Button>
            </form>
          </div>
        </div>
      </div>

    )
  }
}
export default StageCheckUser