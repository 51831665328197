import React from 'react'
import { Redirect } from 'react-router-dom'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import {clearStore,changeUserType} from '../../../../redux/reducers/customerReducer'

@connect(
    store => ({
      customer: store.customer
    }),
    dispatch => ({
        clearStore: ()=>dispatch(clearStore()),
        changeUserType: (type) => dispatch(changeUserType(type))
    })

)
class ErrorPhoneIdNull extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            returnLogIn: false
        }
    }

    return(){
        this.props.clearStore()
        this.props.changeUserType("customer")
        this.setState({returnLogIn: true})
    }

    render(){
        if(this.state.returnLogIn) return <Redirect to = '/customer/login' />
        return(
          <div className = "account">
            <div className = "account__wrapper">
              <div className = "account__card">
                <h3 className = "account__title">Oops! looks like we encountered an error with your account. Please contact support@paywithsplit.co for more information.</h3>
                <div style = { { margin: "30px 0px 0px 0px" } }>
                  <Button color = "primary"
                    onClick = { this.return.bind(this) }
                  >Return to log in page</Button>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default ErrorPhoneIdNull