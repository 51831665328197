import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SidebarLink from './SidebarLink'
import SidebarCategory from './SidebarCategory'
import { connect } from "react-redux"
import { logout } from '../../../../redux/reducers/drifftReducer'

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    logout: () => dispatch(logout())
  })
)
class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props

    onClick()
  };

  logOutSideBar = () => {
    this.props.logout()
  }

  NewProductLink = () => {
    if (this.props.drifft.travelAgentUser.permission.viewNewOrder) {
      return (
        <SidebarLink title = "New"
          route = "/merchant/newProductLink"
          onClick = { this.hideSidebar }
        />
      )
    }
  }

  NewPaymentLink = () => {
    if (this.props.drifft.travelAgentUser.permission.viewNewOrder) {
      return (
        <SidebarLink title = "New"
          route = "/merchant/newPaymentLink"
          onClick = { this.hideSidebar }
        />
      )
    }
  }


  ViewItems = () => {
      return (
        <SidebarLink title = "View all"
          route = "/merchant/viewitems"
          onClick = { this.hideSidebar }
        />
      )
  }

  RefundLink = () => {
    if (this.props.drifft.travelAgentUser.permission.viewRefund) {

      // let hrefLink = this.props.drifft.v2FELink + "/refund/welcome?email=" + this.props.drifft.travelAgentUser.email
      return (
        <SidebarLink title = "Refund Request"
          route = "/merchant/settings/refundrequest"
          onClick = { this.hideSidebar }
        />
      )
    }
  }

  staticQRCode = () => {
    if (this.props.drifft.travelAgent.allowStaticQRPayment == true) {

      // let hrefLink = this.props.drifft.v2FELink + "/refund/welcome?email=" + this.props.drifft.travelAgentUser.email
      return (
        <SidebarLink title = "Branches"
          route = "/merchant/settings/branches"
          onClick = { this.hideSidebar }
        />
      )
    }
  }

  render() {
    if (!this.props.drifft.travelAgentUser.isAdmin) {
      return (
        <div className = "sidebar__content">
          <ul className = "sidebar__block">
            <SidebarLink title = "Home"
              icon = "home"
              route = "/merchant/pastpayments"
              onClick = { this.hideSidebar }
            />
            {this.props.drifft.travelAgentUser.permission.viewNewOrder &&
            <>            
              <SidebarCategory title = 'Single-use Payment Link'>
                {this.NewPaymentLink()}
              </SidebarCategory>
              {this.props.drifft.travelAgentUser.permission.viewNewItem && 
              
                <SidebarCategory title = "Multi-use Payment Link">
                  {this.NewProductLink()}
                  {this.ViewItems()}
                </SidebarCategory>
              }

            </>
            }
          </ul>
          <ul className = "sidebar__block">
            <SidebarCategory title = "Settings"
              icon = "cog"
            >
              <SidebarLink title = "Change Password"
                route = '/merchant/settings/changepassword'
                onClick = { this.hideSidebar }
              />
            </SidebarCategory>
          </ul>

          <ul className = "sidebar__block">
            <SidebarLink title = "Logout"
              icon = "exit"
              onClick = { this.logOutSideBar }
            />
          </ul>
        </div>
      )
    } else {
      return (
        <div className = "sidebar__content">
          <ul className = "sidebar__block">
            <SidebarLink title = "Dashboard"
              icon = "home"
              route = "/merchant/dashboard"
              onClick = { this.hideSidebar }
            />
            <SidebarCategory title = 'Single-use Payment Link'>
              {this.NewPaymentLink()}
            </SidebarCategory>
            {this.props.drifft.travelAgentUser.permission.viewNewItem && 
              <SidebarCategory title = "Multi-use Payment Link">
                {this.NewProductLink()}
                {this.ViewItems()}
              </SidebarCategory>
            }
            <SidebarLink title = "Past Payments"
              icon = "history"
              route = "/merchant/pastpayments"
              onClick = { this.hideSidebar }
            />
            {
              this.props.drifft.travelAgentUser.permission.viewSngl &&
              <SidebarLink title = "Redemption codes"
                route = "/merchant/redemptionCode"
                onClick = { this.hideSidebar }
              />
            }
          </ul>
          <ul className = "sidebar__block">
            <SidebarCategory title = "Settings"
              icon = "cog"
            >
              <SidebarLink title = "Reports"
                route = "/merchant/settings/report"
                onClick = { this.hideSidebar }
              />
              {/* <SidebarLink title="Static QR Code" route="/merchant/settings/qrcode" onClick={this.hideSidebar} /> */}
              {this.staticQRCode()}
              {this.RefundLink()}
              <SidebarLink title = "Change Password"
                route = '/merchant/settings/changepassword'
                onClick = { this.hideSidebar }
              />
            </SidebarCategory>
            <SidebarLink title = "Logout"
              icon = "exit"
              onClick = { this.logOutSideBar }
            />
          </ul>
        </div>
      )
    }
  }
}

export default SidebarContent
