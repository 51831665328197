import React, { PureComponent } from 'react'
import { Container } from 'reactstrap'
import HorizontalFormTicket from './components/HorizontalFormTicket'
import { connect } from "react-redux"
import moment from 'moment'
import {createDrifftLink, sendDriffEmail} from '../../../redux/reducers/drifftReducer'
import NotificationSystem from 'rc-notification'
import {bookingType} from '../../../helpers/constants'

let notification = null

const showNotification = (notificationContent) => {
  notification.notice({
    content: notificationContent,
    duration: 3,
    closable: true,
    style: { top: 0, left: 0 },
    className: 'full ltr-support',
  })
}

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    createDrifftLink: (dataForm, multiLink,type) => dispatch(createDrifftLink(dataForm, multiLink,type)),
    sendDriffEmail: (payload) => dispatch(sendDriffEmail(payload))
  })
)


class NewLinkPage extends PureComponent{ 
  constructor(){
    super()
    this.state = {
      clearData: false
    }
  }

  componentDidMount() {
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n)
  }

  showResults = (dataForm) => {
    if (dataForm.DOB){
      dataForm.DOB = moment(dataForm.DOB).format('YYYY-MM-DD')
    }
    if (dataForm.ticketDate){
      dataForm.ticketDate = moment(dataForm.ticketDate).format('YYYY-MM-DD')

    }
    if (dataForm.returnTicketDate){
      dataForm.returnTicketDate = moment(dataForm.returnTicketDate).format('YYYY-MM-DD')
    }
    
    if(dataForm.price && dataForm.ticketDate && dataForm.name && dataForm.email){
      //hackish way to turn negative value of price & deposit, need to go learn redux-form -.-''
      if(dataForm.price <= -1){
        dataForm.price = dataForm.price * -1
      }
      if(dataForm.deposit <= -1){
        dataForm.deposit = dataForm.deposit * -1
      }
      this.props.createDrifftLink(dataForm,false,bookingType.TICKET)
    }

  }

  onEmailClick = (data) => {
    this.props.sendDriffEmail(data)
  }

  render(){
    return(
      <Container className = "invoice">
        <HorizontalFormTicket 
          onSubmit = { this.showResults } 
          clearData = { this.state.clearData } 
          onEmailClick = { this.onEmailClick }
          showNotification = { (notificationContent) => showNotification(notificationContent) }
        />
      </Container>
    )
  }
}

export default NewLinkPage
