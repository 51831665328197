import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
import LogInForm from './components/LogInForm'
import { connect } from "react-redux"
import {login,changeUserType} from '../../../redux/reducers/customerReducer'

//TODO: Replace drifft reducer with new customerReducer

@connect(
  store => ({
    drifft: store.drifft,
    customer: store.customer
  }),
  dispatch => ({
    login: (email,password) => dispatch(login(email,password)),
    changeUserType: (type)=>dispatch(changeUserType(type)) 
  })
)
class LogIn extends Component {

  constructor(props) {
    super(props)
    this.props.changeUserType("customer")
    this.state = {
      justloggedIn: false,
      failedLogin: false,
      newUser: false
    }
  }

  newUserLink() {
    this.setState({newUser: true})
  }

  async showResults(values){
    await this.props.login(values.email,values.password)
    if(this.props.customer.error){
      if(this.props.customer.error.message.includes('email')){
        return{ email: this.props.customer.error.message } 
      }else{
        return {password: this.props.customer.error.message}
      }
    }
    else if(this.props.customer.loggedIn){
      this.setState({
        justloggedIn: true,
        failedLogin: false
      })
    }else this.setState({failedLogin: true})

  }

  render(){
    if(this.state.justloggedIn){
      return <Redirect to = { "/customer/dashboard" } />
    }
    if(this.state.newUser){
      return <Redirect to = { "/customer/newuser/" } />
    }
    return (
      <div className = "account" >
        <div className = "account__wrapper">
          <div className = "account__card">
            <div className = "account__head">
              <h3 className = "account__title">Log in to your Split account
              </h3>
            </div>
            <LogInForm onSubmit = { this.showResults.bind(this) }
              failedLogin = { this.state.failedLogin }
              newUserLink = { this.newUserLink.bind(this) }
            />
          </div>
        </div>
      </div>
    )
  }

}
export default LogIn
