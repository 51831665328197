import React from 'react'
import Button from '../../../components/button/Button'
import { Redirect } from 'react-router-dom'


class StageCompleteReset extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nextStage: false,
        }
    }

    render(){
        if (this.props.location.state == undefined || this.state.nextStage) return <Redirect to = '/merchant/login' />
        return(
          <div className = "account">
            <div className = "account__wrapper">
              <div className = "account__card">
                <div className = "account__head"
                  style = { { marginBottom: "10px" } }
                >
                  <h3 className = "account__title">You are all set!</h3>
                </div>
                {this.props.location.state.isForgot && <h5 className = "account_sub"
                  style = { { marginBottom: "30px" } } >
                The password reset link which is valid for 2 hours has been sent to your (or your main branch) email notification list.</h5>}
                {!this.props.location.state.isForgot && 
                  <h5 className = "account_sub"
                    style = { { marginBottom: "30px" } }
                  >
                Your password has been successfully reset.</h5>}
                <div style = { { marginBottom: "30px" } }>
                  <Button onClick = { () => {this.setState({nextStage: true})} }>Log In</Button>
                </div>
              </div>
            </div>
          </div>
    
            )
    }
}
export default StageCompleteReset