import React from 'react'
import Panel from '../../../../../shared/components/Panel'
import calanderMinus from '../../../assets/images/calander-minus.svg'
import arrowUpCircle from '../../../assets/images/arrow-up-circle.svg'
import arrowDownCircle from '../../../assets/images/arrow-down-circle.svg'
import './RawValues.css'
import { connect } from "react-redux"
import { latePayments } from '../../../../../redux/reducers/drifftReducer'

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    latePayments: (todayDate, currency) => dispatch(latePayments(todayDate, currency))
  })
)


class rawValues extends React.Component {
  constructor(props) {
    super(props)
  }

  async componentDidMount(){
    await this.props.latePayments(new Date(), this.props.drifft.currency)
  }

  async componentDidUpdate(nextProps) {
    if (nextProps.drifft.currency !== this.props.drifft.currency || (this.props.drifft.hasJWT && !this.props.drifft.loggedIn && nextProps.drifft.loggedIn)) {
      await this.props.latePayments(new Date(), nextProps.drifft.currency)
    }
  }

  render() {
    return (
      <Panel md = { 12 }
        lg = { 4 }
        xl = { 4 }
        xs = { 12 }
        title = "Late payments"
      >
        <div className = "dashboard__product">
          <div className = "basicCard">
            {/* <div className="cardContent withBorder">
            <img src= {alertOctagonFill}  width="25%" height="25%"  className="marginImage" />
                <div className="numberStats" >
                <h1 className="mainNumber">1</h1> 
                <div className="arrowImage">
                  <div style={{ width:"20px",height:"20px", marginLeft:"10px", marginTop:"12px"}}>
                    <img src={arrowUpCircle}  />
                  </div>
                </div>
              </div>
              <h5 className="contentBody"> number of expired link failed orders</h5>
              <h5 className="comparison_green" > <span style={{ fontWeight:"bold"}}> 15 </span> expired link failed orders last month</h5>

            </div> */}
            {this.props.drifft.latePayments === null ?
              <div >
                <div style = { { display: 'block', textAlign: 'center', paddingTop: '2rem' } }>
                  <div className = "load__icon-wrap">
                    <svg className = "load__icon">
                      <path fill = "#ff8600"
                        d = "M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              :
              <div className = "cardContent">
                <img src = { calanderMinus }
                  width = "25%"
                  height = "25%"
                  className = "marginImage"
                />
                <div className = "numberStats">
                  <h1 className = "mainNumber">{this.props.drifft.latePayments.thisMonth}</h1>
                  <div className = "arrowImage">
                    <div style = { { width: "20px", height: "20px", marginLeft: "10px", marginTop: "12px" } }>
                      <img src = { this.props.drifft.latePayments.trend === "good" ? arrowUpCircle : arrowDownCircle } />
                    </div>
                  </div>
                </div>
                <h5 className = "contentBody"> number of late payments</h5>
                <h5 className = { this.props.drifft.latePayments.trend === "good" ? "comparison_green" : "comparison_red" } > <span style = { { fontWeight: "bold" } }> {this.props.drifft.latePayments.lastMonth} </span> late payments last month</h5>
              </div>
            }
          </div>
        </div>
      </Panel>
    )
  }
}



export default rawValues
