import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SidebarLink from './SidebarLink'
import SidebarCategory from './SidebarCategory'
import { connect } from "react-redux"
import { logout } from '../../../../redux/reducers/customerReducer'

@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    logout: () => dispatch(logout())
  })
)
class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props

    onClick()
  };

  logOutSideBar = () => {
    this.props.logout()
  }
  
  render() {
    return (
      <div className = "sidebar__content">
        <ul className = "sidebar__block">
          <SidebarLink title = "Dashboard"
            icon = "home"
            route = "/customer/dashboard"
            onClick = { this.hideSidebar }
          />
          <SidebarLink title = "Instalments"
            icon = "history"
            route = "/customer/instalment"
            onClick = { this.hideSidebar }
          />
          <SidebarLink title = "Add New Card"
            icon = "license"
            route = "/customer/addnewcard"
            onClick = { this.hideSidebar }
          />
        </ul>
        <ul className = "sidebar__block">
          <SidebarCategory title = "Settings"
            icon = "cog"
          >
            <SidebarLink title = "Account"
              route = "/customer/account"
              onClick = { this.hideSidebar }
            />
          </SidebarCategory>
          <SidebarLink title = "Logout"
            icon = "exit"
            onClick = { this.logOutSideBar }
          />
        </ul>
      </div>
    )
  }
}

export default SidebarContent
