import React from 'react'
import { Redirect } from 'react-router-dom'
import Button from '../../../components/button/Button'
import { connect } from 'react-redux'
import { changeUserType } from '../../../redux/reducers/customerReducer'
import { merchantForgotPassword } from '../../../redux/reducers/drifftReducer'
import TextBox from '../../../components/textBox/TextBox'

@connect(
  store => ({
    customer: store.customer,
    drifft: store.drifft
  }),
  dispatch => ({
    merchantForgotPassword: (email) => dispatch(merchantForgotPassword(email)),
    changeUserType: (type) => dispatch(changeUserType(type))
  })
)

class StageCheckMerchant extends React.Component {
  constructor(props) {
    super(props)
    this.props.changeUserType("merchant")
    this.state = {
      newMerchantStage: false,
      previousStage: false,
      email: "",
      showCompletion: false
    }
  }

  render() {
    if (this.state.previousStage) return <Redirect to = '/merchant/login' />
    if (this.state.showCompletion) return <Redirect to = { { pathname: '/merchant/complete', state: { isForgot: true }} } />
    if (this.props.drifft.error) return <Redirect to = '/merchant/error' />
    return (
      <div className = "account">
        <div className = "account__wrapper">
          <div className = "account__card">
            <div className = "account__head"
              style = { { marginBottom: "10px" } }
            >
              <h3 className = "account__title">Let's get started!</h3>
            </div>
            <h5 className = "account_sub"
              style = { { marginBottom: "30px" } }
            />
            <form onSubmit = { async (event) => { 
              event.preventDefault()
              // will send the request and return complete regardless. prevent people from finding out which emails exists
              await this.props.merchantForgotPassword(this.state.email)
              if (!this.props.drifft.error) {
                this.setState({ showCompletion: true })
              }} }
            >
              <TextBox 
                onChange = { () => this.setState({ email: event.target.value.toLowerCase() }) } 
                label = "Email"
                placeholder = "Email ID" 
                message = "Please enter the email ID that you used to sign up with split."
              />
              <Button type = "button"
                white
                onClick = { () => { this.setState({ previousStage: true }) } }
              > Back </Button>
              <Button type = "submit" > Next </Button>
            </form>
          </div>
        </div>
      </div>

    )
  }
}
export default StageCheckMerchant