/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer,
} from 'recharts'
import PropTypes from 'prop-types'
import Panel from '../../../../../shared/components/Panel'
import { salesByLocation } from '../../../../../redux/reducers/drifftReducer'


const style = () => {
  const left = { left: 0 }

  return ({
    ...left,
    width: "100%",
    position: 'absolute',
  })
}

const renderLegend = ({ payload }) => (
  <ul className = "dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key = { `item-${index}` }><span style = { { backgroundColor: entry.color } } />{entry.value}</li>
      ))
    }
  </ul>
)

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
}
@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    salesByLocation: (currency) => dispatch(salesByLocation(currency))
  })

)

class SalesByLocation extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      x: 0,
      y: 0,
    }
  }

  async componentDidMount(){
      await this.props.salesByLocation(this.props.drifft.currency)
    
  }

  async componentWillUpdate(nextProps) {
    if (nextProps.drifft.currency !== this.props.drifft.currency || (this.props.drifft.hasJWT && !this.props.drifft.loggedIn && nextProps.drifft.loggedIn)) {
      await this.props.salesByLocation(nextProps.drifft.currency)
    }
  }

  onMouseMove = (e) => {
    if (e.tooltipPosition) {
      this.setState({ x: e.tooltipPosition.x, y: e.tooltipPosition.y })
    }
  };

  render() {
    const { x, y } = this.state

    return (
      <Panel lg = { 4 }
        xl = { 4 }
        md = { 12 }
        xs = { 12 }
        title = { 'Orders by country' }
      >
        {this.props.drifft.salesByLocation === null ? <div >
          <div style = { { display: 'block', textAlign: 'center', paddingTop: '2rem' } }>
            <div className = "load__icon-wrap">
              <svg className = "load__icon">
                <path fill = "#ff8600"
                  d = "M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                />
              </svg>
            </div>
          </div>
        </div>
          :
        <div>
          <ResponsiveContainer className = "dashboard__chart-pie dashboard__chart-pie--commerce"
            height = { "100%" }
          >
            <PieChart className = "dashboard__chart-pie-container">
              <Tooltip position = { { x, y } } />
              <Pie
                data = { this.props.drifft.salesByLocation }
                dataKey = "value"
                cy = { 180 }
                innerRadius = { "60%" }
                outerRadius = { "80%" }
                label
                onMouseMove = { this.onMouseMove }
              />
              <Legend layout = "vertical"
                verticalAlign = "bottom"
                wrapperStyle = { style() }
                content = { renderLegend }
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
        }
      </Panel>
    )
  }
}

export default SalesByLocation
