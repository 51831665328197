import React from "react";
import { Redirect } from "react-router-dom";
import Button from "../../../components/button/Button";
import PhoneInput from "../../../components/phoneInput/PhoneInput";
import TextBox from "../../../components/textBox/TextBox";
import { connect } from "react-redux";
import Picker from "../../../components/picker/Picker";
import {
  checkPhone,
  validationPhone,
  validateNumber,
} from "../../../redux/reducers/customerReducer";
import ButtonLoader from "../../../components/buttonLoader/ButtonLoader";
import { errors } from "../../../helpers/constants.js";
import phone from "phone";
import { correctMalaysiaAreaCode } from "../../../helpers/utils";

String.prototype.paddingLeft = function(paddingValue) {
  return String(paddingValue + this).slice(-paddingValue.length);
};

@connect(
  (store) => ({
    customer: store.customer,
  }),
  (dispatch) => ({
    checkPhone: (clientuid, id, sendCall) =>
      dispatch(checkPhone(clientuid, id, sendCall)),
    validationPhone: (id, phoneValidationCode) =>
      dispatch(validationPhone(id, phoneValidationCode)),
    validateNumber: (email, number) => dispatch(validateNumber(email, number)),
  })
)
class StageReturnPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextStage: false,
      previousStage: false,
      phones: this.props.customer.existingUserData.phone,
      phoneNumber: "",
      phoneSelected: "",
      otp: "",
      error: "",
      seconds: 0,
      otpError: "",
      showOTPError: false,
      OTPCount: 0,
      multiplePhones: false,
      otpVerified: false,
      countryCode: "sg",
      country: {},
      phoneEditEnabled: true,
      parsedNumber: "",
    };
    this.interval = null;

    this.countdownTimer = null;

    this.midPointTimeout = null;
  }

  async componentDidMount() {
    if (this.state.phones.length === 1) {
      this.setState({
        phoneSelected: this.state.phones[0],
      });
    } else {
      let options = [];

      for (let index = 0; index < this.state.phones.length; index++) {
        const phone = this.state.phones[index];

        options.push({ display: phone.securedNumber });
      }
      this.setState({
        phoneSelected: this.state.phones[0],
        multiplePhones: true,
        options: options,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.countdownTimer);
    clearTimeout(this.midPointTimeout);
    this.setState({ seconds: 0 });
  }

  sendOTPtoRegisteredNumber = async (sendCall = false) => {
    this.setState({ error: "" });
    await this.props.checkPhone(
      this.props.customer.email,
      this.state.parsedNumber,
      sendCall
    );
    this.setState(
      {
        otpError: this.props.customer.error
          ? this.props.customer.error.message
          : "",
        phoneMessage: "",
        otp: this.props.customer.phoneValidationCode || null,
        otpVerified: this.props.customer.phoneValidationCode ? true : false,
        isCounting: true,
      },
      () => {
        this.setState({ countdownTimer: 60 });
        this.countdownTimer = setInterval(() => {
          if (this.state.countdownTimer === 0) {
            clearInterval(this.countdownTimer);
          } else {
            this.setState({ countdownTimer: this.state.countdownTimer - 1 });
          }
        }, 1000);
        //interval for 60s timer
        this.interval = setTimeout(() => {
          clearTimeout(this.interval);
          if (!this.state.otpVerified) {
            this.setState({
              phoneMessage: "Didn't get your OTP? Try again.",
              phoneEditEnabled: true,
            });
          }
          this.setState({
            isCounting: false,
            midPointCheck: false,
            otpError: "",
          });
        }, 60 * 1000);
        // interval for 30s timer prompt
        this.midPointTimeout = setTimeout(() => {
          clearTimeout(this.midPointTimeout);
          this.setState({ midPointCheck: true });
        }, 30 * 1000);
      }
    );
    //calling verifyOTP if in dev mode
    if (this.props.customer.phoneValidationCode)
      this.verifyOTP(this.props.customer.phoneValidationCode.toString());
  };

  async verifyOTP(otp) {
    this.setState({
      otpError: "",
    });
    //validation for OTP length
    if (otp && otp.length != 5) {
      this.setState({ otpError: errors.OTP_LENGTH_INVALID });
    } else {
      await this.props.validationPhone(
        this.props.customer.email,
        parseInt(otp)
      );
      if (this.props.customer.error) {
        this.setState({ otpError: errors.OTP_INCORRECT });
      } else if (this.props.customer.hasPassOTP) {
        this.setState({ otpVerified: true });
      }
    }
  }

  onNextClick = async () => {
    if (this.props.customer.hasPassOTP) {
      this.setState({ nextStage: true });
    }
  };

  async onFieldChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
    if (evt.target.name === "otp") {
      if (evt.target.value && evt.target.value.length === 5) {
        await this.verifyOTP(evt.target.value);
      }
    }
  }

  async submitPhone(sendCall = false) {
    if (this.state.phone) {
      let phoneCheck = phone("+" + this.state.phone);

      if (phoneCheck.length == 0) {
        this.setState({ phoneError: errors.PHONE_INVALID });
      } else if (this.state.phone.length < 10) {
        this.setState({
          phoneError: errors.PHONE_INVALID,
        });
      }
      //passes phone validation
      else {
        this.setState({
          phoneError: "",
          otpError: "",
        });
        let number = Number(this.state.phone.replace(/((?!\d).)/g, ""));

        if (this.state.country.countryCode == 'my') {
          number = correctMalaysiaAreaCode(number);
        }
        let data = await this.props.validateNumber(
          this.props.customer.email,
          number
        );

        if (data) {
          this.setState({
            phoneEditEnabled: false,
            parsedNumber: number,
          }, async () => await this.sendOTPtoRegisteredNumber(sendCall))
        } else if (data == false) {
          this.setState({
            phoneError:
              "Sorry, but the number that you entered does not match the one in our systems.",
            errorCount: this.state.errorCount + 1,
          });
        }
      }
    }
  }

  renderButtons = () => {
    const buttonOnClick = (call = false) => {
      this.state.phoneEditEnabled
        ? this.submitPhone(call)
        : this.sendOTPtoRegisteredNumber(call);
    };

    if (this.state.otpVerified) {
      return (
        <Button control onClick={this.onNextClick} type="submit">
          Continue
        </Button>
      );
    }
    if (!this.state.isCounting) {
      return (
        <React.Fragment>
          <Button control onClick={() => buttonOnClick(false)}>
            {this.state.phoneEditEnabled ? "Get OTP" : "Resend OTP"}
          </Button>
          <Button control white onClick={() => buttonOnClick(true)}>
            Get OTP via Call
          </Button>
        </React.Fragment>
      );
    } else if (this.state.isCounting) {
      let textFormatter = () => {
        let min = parseInt(this.state.countdownTimer / 60)
          .toString()
          .paddingLeft("00");
        let sec = this.state.countdownTimer - min * 60;

        sec = sec.toString().paddingLeft("00");
        return min + ":" + sec;
      };

      return <ButtonLoader seconds={60} text={textFormatter()} />;
    }
  };

  onPhoneChange = (phoneNumber, country) => {
    if (this.state.phoneError) {
      this.setState({ phoneError: "" });
    }
    this.setState({
      phone: phoneNumber,
      country: country,
    });
  };

  render() {
    if (this.state.nextStage)
      return <Redirect to="/customer/returninguser/password" />;
    if (this.state.previousStage) return <Redirect to="/customer/newuser" />;
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head" style={{ marginBottom: "10px" }}>
              <h3 className="account__title">{"Welcome back!"}</h3>
            </div>
            <h5 className="account_sub" style={{ marginBottom: "30px" }}>
              This one-time verification is to keep your account secure.
            </h5>
            <div
              style={{
                display: "flex",
                marginTop: "1.4rem",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div>
                  {this.state.multiplePhones ? (
                    <Picker
                      label="Phone"
                      data={this.state.options}
                      message={this.state.phoneMessage}
                      onChange={(evt) => {
                        let phone = this.state.phones.find((element) => {
                          return (
                            element.id == evt.target.selectedOptions[0].value
                          );
                        });
                        this.setState({
                          phoneSelected: phone,
                        });
                      }}
                      disabled={this.state.isCounting}
                    />
                  ) : (
                    <PhoneInput
                      label="Phone"
                      value={this.state.phoneSelected.securedNumber}
                      isSecured
                      disabled={true}
                      message={this.state.phoneMessage}
                    />
                  )}
                </div>
              </div>
            </div>
            <PhoneInput
              label="Please re-enter your phone number"
              value={this.state.phoneNumber}
              country={this.state.countryCode}
              onChange={this.onPhoneChange}
              preferredCountries={["sg", "my", "bn"]}
              placeholder={"Country code & phone no."}
              message={this.state.phoneMessage}
              error={this.state.phoneError}
              disabled={!this.state.phoneEditEnabled}
            />
            {!this.state.phoneEditEnabled && (
              <TextBox
                label="OTP"
                placeholder={"xxxxx"}
                onChange={this.onFieldChange.bind(this)}
                name={"otp"}
                type="number"
                value={this.state.otp}
                message={
                  this.state.midPointCheck &&
                  "If you still haven't received your OTP, just get a quick OTP via phone call. It's super fast."
                }
                error={this.state.otpError}
                success={this.state.otpVerified && " "}
                disabled={this.state.otpVerified}
              />
            )}
            <div style={{ display: "flex", marginTop: "1.4rem" }}>
              {this.renderButtons()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default StageReturnPhone;
