import React from 'react'
import { connect } from "react-redux"
import { Col, Container, Row,Card, CardBody } from 'reactstrap'
import ChangePassword from './ChangePassword/ChangePassword'
import ChangePhoneNumber from './ChangePhoneNumber/ChangePhoneNumber'

import Button from '../../../components/button/Button'
@connect(
    store => ({
      customer: store.customer
    })
  )
  
class Account extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      changingPassword: false,
      changingPhoneNumber: false
    }
  }

  setChangingPassword(){
    this.setState({changingPassword: !this.state.changingPassword})
  }

  setChangingPhoneNumber(){
    this.setState({changingPhoneNumber: !this.state.changingPhoneNumber})
  }

    render(){
      if(this.state.changingPassword){
        return <ChangePassword toggle = { this.setChangingPassword.bind(this) } />
      }
      if(this.state.changingPhoneNumber){
        return <ChangePhoneNumber toggle = { this.setChangingPhoneNumber.bind(this) } />
      }

        return(
          <div>

            <Container>
              <Row style = { {display: 'flex',justifyContent: 'space-between'} }>
                <Col style = { {margin: 'auto'} }>
                  <h3 className = "page-title" >{`My Account`}</h3>
                </Col>
              </Row>
              <Row>
                <Card style = { {height: '500px'} }>
                  <CardBody>
                
                    <Col xs = "12"
                      md = "6"
                    >
                      <Row style = { {alignItems: 'center',justifyContent: 'space-between',marginBottom: '16px'} }>
                        <h5 style = { {fontWeight: '450'} }>My password:</h5>
                        <Row style = { {justifyContent: 'space-between',borderBottom: '1px solid #B8B8B8'} }>
                          <h5 style = { {alignSelf: 'center',marginLeft: '10px'} }>**********</h5>
                          <Button 
                            style = { {
                            fontSize: '0.8rem', 
                            marginBottom: '5px',
                            marginLeft: '80px'} }
                            onClick = { ()=>this.setState({changingPassword: true}) }
                          >Change</Button>
                        </Row>
                      </Row>
                      <Row style = { {alignItems: 'center',justifyContent: 'space-between'} }>
                        <h5 style = { {fontWeight: '450'} }>Phone number:</h5>
                        <Row style = { {justifyContent: 'space-between',borderBottom: '1px solid #B8B8B8'} }>
                          <h5 style = { {alignSelf: 'center',marginLeft: '10px'} }>{this.props.customer.userInfo ? this.props.customer.userInfo.defaultClient.phone.securedNumber : '**********'}</h5>
                          <Button 
                            style = { {
                            fontSize: '0.8rem', 
                            marginBottom: '5px',
                            marginLeft: '80px'} }
                            onClick = { ()=>this.setState({changingPhoneNumber: true}) }
                          >Change</Button>
                        </Row>
                      </Row>

                    </Col>
            
                  </CardBody>
                </Card>
              </Row>
            </Container>
          </div>

          )
    }
}

export default Account