import React, { useEffect } from "react"
import classNames from 'classnames'
import styles from './styles.module.css'

export default (props) => {
  let { className, seconds, onComplete, text } = props

  useEffect(() => {
    if (seconds && seconds > 0) {
      var elem = document.getElementById("loadingButton")
      var width = 0
      let interval = seconds * 1000 / 100
      let frame = () => {
        if (elem) {
          if (width >= 100) {
            clearInterval(id)
            //if there needs to be a callback
            if(onComplete){
              onComplete()
            }
            seconds = null
          } else {
            width++
            elem.style.width = width + '%'
          }
        }
      }
      var id = setInterval(frame, interval)
    }
  // only run if seconds changes
  },[seconds])

  return (
    <div className = { classNames([styles.buttonGradientLoader, className]) }>
      <div className = { classNames([styles.buttonGradientText, className]) }>
        {text}
      </div>
      <div id = "loadingButton"
        className = { classNames([styles.buttonGradientLoading, className]) }
      />
    </div>
  )

}