import React, { useEffect, useState } from "react"
import classNames from 'classnames'
import styles from './styles.module.css'
import Masked from './Masked'
import EyeIcon from 'mdi-react/EyeIcon'
import EyeOffIcon from 'mdi-react/EyeOffIcon'
const TextBox = (props) => {
  let { className, error, success, mask, type, label, message, noBottomMargin, extraText, upperCase, password, ...other } = props
  const showPassword = (e) => {
    e.preventDefault()
    togglePasswordShown(!isPasswordShown)
  }
  const [isPasswordShown, togglePasswordShown] = useState(false)

  useEffect(() => {
    if (extraText) {
      let extraText = document.getElementById('extraText')
      let extraTextLength = (extraText.offsetWidth + 20).toString() + 'px'
      let element = document.getElementById('input')

      element.style.setProperty('padding', `6px 46px 6px ${extraTextLength}`, 'important')
      if (!label) extraText.style.setProperty('top', '18px', 'important')
    }
    return
  }, [extraText])
  if (password) {
    type = isPasswordShown ? 'text' : 'password'
  }
  if (mask) {
    return <Masked { ...props } />
  }
  if (success) {
    message = success
  } else if (error) {
    message = error
  }

  return (
    <div { ...other }
      className = { classNames([styles.textBoxWrapper, (extraText || password) && styles.textBoxWrapperPositionRelative, noBottomMargin && styles.noBottomMargin, className]) }
    >
      {extraText && <label id = { 'extraText' }
        className = { classNames([styles.textBoxInput, styles.extraTextPosition]) }
                    >{extraText}</label>}
      <label className = { classNames([styles.textBoxLabel, className]) }>{label}</label>
      <input { ...other }
        type = { type }
        id = { extraText && 'input' }
        className = { classNames([styles.textBox, error && styles.error, success && styles.success, upperCase && styles.upperCase, extraText && styles.extraTextInput, className]) }
      />
      {password && <button
        type = 'button'
        className = { classNames([styles.textBoxPasswordButton]) }
        onClick = { e => showPassword(e) }
                   >{isPasswordShown ? <EyeIcon /> : <EyeOffIcon />}
      </button>
      }      <label className = { classNames([styles.textBoxMessage, error && styles.error, success && styles.success, className]) }>{message}</label>
    </div>
  )
}

export default TextBox