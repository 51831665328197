import React, { PureComponent } from 'react'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'

class DatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  constructor() {
    super()
    this.state = {
      startDate: null
    }
    this.handleChange = this.handleChange.bind(this)
  }

  
  componentWillReceiveProps= (nextProps)=>{
    if(nextProps.clearData){
      this.setState({startDate: ''})
    }
  }
  
  handleChange(date) {
    const { onChange } = this.props

    this.setState({
      startDate: date,
    })
    onChange(date)
  }

  handleDateChangeRaw(e) {
    e.preventDefault()
  }
  
  render() {
    const { startDate } = this.state

    return (
      <div className = "form__form-group-input-wrap form__form-group-input-wrap--error-above">
        <div className = "date-picker">
          <DatePicker
            className = "form__form-group-datepicker"
            selected = { startDate }
            onChange = { this.handleChange }
            onChangeRaw = { this.props.preventRawChange ? this.handleDateChangeRaw : null }
            dateFormat = "dd/MM/yyyy"
            dropDownMode = "select"
            minDate = { this.props.minDate || null }
            placeholderText = { this.props.placeholderText }
            showYearDropdown = { this.props.showYearDropdown }
            scrollableYearDropdown
            yearDropdownItemNumber = { this.props.yearDropdownItemNumber }
            id = { this.props.id }
            autoComplete = "off"
          />
        </div>
        {this.props.showDateError && <span className = "form__form-group-error" >Travel date is required</span>}
        {this.props.showEarlyReturnError && <span className = "form__form-group-error" >Return date is earlier than departure date</span>}
        {this.props.showDateOfBirthError && <span className = "form__form-group-error" >Must be 18 years of age</span>}
      </div>
    )
  }
}

const renderDatePickerField = (props) => {
  const { 
    input, 
    placeholderText, 
    showYearDropdown,
    yearDropdownItemNumber,
    showDateError, 
    showEarlyReturnError, 
    showDateOfBirthError,
    preventRawChange,
    id,
    clearData, 
    minDate
  } = props

  return <DatePickerField 
    { ...input }
    placeholderText = { placeholderText } 
    showYearDropdown = { showYearDropdown }
    yearDropdownItemNumber = { yearDropdownItemNumber }
    showDateError = { showDateError } 
    preventRawChange = { preventRawChange }
    showEarlyReturnError = { showEarlyReturnError }
    showDateOfBirthError = { showDateOfBirthError }
    id = { id } 
    clearData = { clearData }
    minDate = { minDate }
         />
}

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
}

export default renderDatePickerField
