import React, { PureComponent } from 'react'
import { Col, Container, Row,Card, CardBody } from 'reactstrap'
import SalesByLocation from './components/SalesByLocation/SalesByLocation'
import RawValues from './components/rawValues/RawValues'
import RecentOrders from './components/RecentOrders/RecentOrders'
import ProductSales from './components/productSales/ProductSales'
import Rankings from './components/rankings/Rankings'
import { connect } from "react-redux"
import Collapse from '../../../shared/components/Collapse'
import {changeCurrency,getCurrencies} from '../../../redux/reducers/drifftReducer'
@connect(
  store => ({
      drifft: store.drifft
  }),
  dispatch => ({
      changeCurrency: (currency) => dispatch(changeCurrency(currency)),
      getCurrencies: () => dispatch(getCurrencies())
  })
)
class ECommerceDashboard extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      collapse: false
    }
  }  

  async componentDidMount(){
      await this.props.getCurrencies()
  }

  async componentWillUpdate(nextProps) {
    if (nextProps.drifft.travelAgent !== this.props.drifft.travelAgent || (this.props.drifft.hasJWT && !this.props.drifft.loggedIn && nextProps.drifft.loggedIn)) {
      await this.props.getCurrencies()
    }
  }

  toggle(){
    this.setState((prevState)=>({ collapse: !prevState.collapse}))
  }

  populateCollapse(){
    return this.props.drifft.currencies.map((currency) => {
      return (
        <p type = "button"
          key = { currency }
          onClick = { ()=>{this.props.changeCurrency(currency);this.setState({collapse: false})} }
        >{currency}</p>
      )
    })

  }

  render() {
    return (

      <Container className = "dashboard">
        <Row style = { {display: 'flex',justifyContent: 'space-between'} }>
          <Col style = { {margin: 'auto'} }>
            <h3 className = "page-title" >{this.props.drifft.travelAgent.name + ` overview`}</h3>
          </Col>
          <Col md = { 4 }> 
            <Card >
              <CardBody style = { {padding: '10px' } }>
                <Collapse collapse = { this.state.collapse }
                  toggle = { this.toggle.bind(this) }
                  title = { this.props.drifft.currency }
                  className = 'with-shadow'
                >
                  <div>
                    {this.populateCollapse()}
                  </div>
                </Collapse>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <TotalProducts />
          <TotalProfit />
          <OrdersToday />
          <Subscriptions />
        </Row> */}
        <Row>
          <ProductSales />
          <RecentOrders />
          <RawValues />
          <Rankings />

          {/* <SalesStatistic /> */}
          {/* <MyTodos /> */}
          {/* <SalesStatistisBar /> */}
          {/* <SalesReport /> */}
          {/* <Emails />
          <ShortReminders /> */}
          <SalesByLocation />
          {/* <NewOrders newOrder={newOrder} onDeleteRow={this.onDeleteRow} /> */}
        </Row>
      </Container>
    )
  }
}

export default ECommerceDashboard