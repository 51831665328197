import React from "react"
import classNames from 'classnames'
import styles from './styles.module.css'

const Picker = (props) => {
  let { className, error, success, placeholder,label,message, ...other} = props

  if(error){
    message = error
  } else if (success){
    message = success
  }

  return (
    <div { ...other }
      className = { classNames([styles.textBoxWrapper,className]) }
    >
      <label className = { classNames([styles.textBoxLabel,className]) }>{label}</label>
      <div>
        <select { ...props }
          className = { classNames([styles.textBox,error && styles.error, success && styles.success,props.classname]) }
        >
          <option value = ""
            defaultValue
            disabled
            hidden
            className = { classNames([styles.textBoxPlaceholder,classNames]) }
          >{placeholder}</option>
          {props.data.map((item) => (
            <option className = { classNames([styles.pickerListItem,props.classname]) }
              value = { item.title }
              key = { item.title }
            >{item.display}</option>
        ))}
        </select>
      </div>
      <label className = { classNames([styles.textBoxMessage,error && styles.error, success && styles.success,className]) }>{message}</label>
    </div>
  )
}

export default Picker