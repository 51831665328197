import React, { PureComponent } from 'react'
import TextBox from './TextBox/TextBox'

class PhoneInput extends PureComponent {
  constructor() {
    super()

    this.state = {
      phone: ''
    }

    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange(evt) {
    const { onChange } = this.props

    this.setState({
      [evt.target.name]: evt.target.value.toUpperCase()
    })
    onChange(evt.target.value.toUpperCase())
  }

  render() {
    return (
      <TextBox onChange = { this.onFieldChange }
        style = { { width: '100%' } }
        name = { 'phone' }
        value = { this.state.phone }
        mask = { '+999999999999' }
        placeholder = { "Phone Number" }
      />
    )
  }
}

const renderPhoneInputField = (props) => {
  const {
    input, meta: { touched, error }
  } = props

  return (<div className = "form__form-group-input-wrap form__form-group-input-wrap--error-above" > <PhoneInput
    { ...input }
                                                                                                    />
    {touched && error && <span className = "form__form-group-error">{error}</span>}
  </div>)
}

export default renderPhoneInputField
