import React, { Component } from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import Layout from '../merchant/Layout/index'
import LayoutCustomer from '../customer/Layout/index'
import MainWrapper from './MainWrapper'
import LogIn from '../merchant/LogIn/index'
import {default as CustomerLogin} from '../customer/LogIn/index'
import ReportPage from '../merchant/Report/index'
import QRCodePage from '../merchant/StaticQRCode/index'
import NewLinkPage from '../merchant/NewLink/index'
import NewItemPage from '../merchant/NewItem/index'
import NewProductLink from '../merchant/NewProductLink/index'
import NewPaymentLink from '../merchant/NewPaymentLink/index'
import DatatablePage from '../merchant/PastBookings/index'
import ViewItemsPage from '../merchant/items/index'
import NotFound404 from '../404/index'
import { connect } from "react-redux"
import StorageService from "../../services/StorageServcies"
import {returnUser,getJWTToken} from '../../redux/reducers/drifftReducer'
import {clearStore,returnCustomer,changeUserType} from '../../redux/reducers/customerReducer'
import NewOrderPage from '../merchant/NewProductLink/index'
import RefundRequestpage from '../merchant/RefundRequests/index'
import ECommerceDashboard from '../merchant/ECommerce/index'
import StageCheckMerchant from '../merchant/ForgotPassword/StageCheckMerchant'
import StageResetPassword from '../merchant/ForgotPassword/StageResetPassword'
import StageCompleteReset from '../merchant/ForgotPassword/StageCompleteReset'
import StageCheckUser from '../customer/NewUser/StageCheckUser'
import StageFinish from '../customer/NewUser/StageFinish'
import StageReturnPhone from '../customer/NewUser/StageReturnPhone'
import StageReturnPassword from '../customer/NewUser/StageReturnPassword'
import Dashboard from '../customer/Dashboard/index'
import Account from '../customer/Account/index'
import Instalment from '../customer/Instalment/index'
import PayNow from '../customer/PayNow/index'
import ErrorPhoneIdNull from '../customer/NewUser/Errors/ErrorPhoneIdNull'
import {InjectedAddNewCard} from '../customer/PaymentMethods/AddNewCard/index'
import ChangePassword from '../merchant/ChangePassword/ChangePassword'
import ViewBranchPage from '../merchant/branches/index'
import GenericError from '../merchant/ForgotPassword/errors/GenericError'
import RedemptionCodePage from '../merchant/RedemptionCode'

function PrivateRouteProcess({component,isAuthenticated,...rest}){
  return (<Route { ...rest }
    render = { () => {
      return (isAuthenticated === true ?
          component
      : <Redirect to = '/customer/newuser' />
  )}
}
          />)
}
@connect(
  store => ({
    drifft: store.drifft,
    customer: store.customer
  }),
  dispatch => ({
    returnUser: (jwtData) => dispatch(returnUser(jwtData)),
    clearStore: () => dispatch(clearStore()),
    returnCustomer: (data) => dispatch(returnCustomer(data)),
    changeUserType: (type) => dispatch(changeUserType(type)),
    getJWTToken: (token) => dispatch(getJWTToken(token)),
  })
)
class Router extends Component {

  constructor(props){
    super(props)
    //check localteStorage if JWT is there load it to API client in difftReducer 
    this.state = {
      inFlow: false,
    }
  }

  async componentWillMount() {
    let checkTokenExpiry = (expiredAt) => {
      return (new Date(expiredAt).getTime() <= new Date().getTime())
    }
    let data = StorageService.get("client_JWT")

    if(data){
      //double check against DB that it still exists
      if(data.token.substring(0,2) == 'DF'){
        this.props.returnUser(data)
        this.props.changeUserType("merchant")
      }  
      await this.props.getJWTToken(data.token)
      if(checkTokenExpiry(data.expiredAt) || !this.props.drifft.hasJWT){
        StorageService.remove("client_JWT")
        alert('Your session has expired, please re-login to the dashboard again')
        if(data.token.substring(0,2) == 'CS'){
          this.setState({redirect: <Redirect to = { '/customer/login' } />})
        }else if (data.token.substring(0,2) == 'DF'){
          this.setState({redirect: <Redirect to = { '/merchant/login' } />})
        }
      }
      else if(data.token.substring(0,2) == 'CS' ){
        //call redecuer to set the JWT for APIClient and turn drifft.loggedIn to true
        this.props.returnCustomer(data)
        this.props.changeUserType("customer")
      }
    }

  }

  authenticate(){
    this.setState({
        inFlow: true,
    })
    //clear redux store everytime we reach this page
    this.props.clearStore()
    return <Redirect to = { '/customer/verify/email' } />
}

  viewNewLink = () => {
    if (this.props.drifft.travelAgentUser.permission.viewNewLink) {
      return (
        <Route path = "/merchant/newlink"
          component = { NewLinkPage }
        />
      )
    }
  }

  viewProductLink =()=>{
    if(this.props.drifft.travelAgentUser.permission.viewNewOrder){
      return (
        <Route path = "/merchant/newProductLink"
          component = { NewProductLink }
        />
      )
    }
  }

  viewPaymentLink =()=>{
    if(this.props.drifft.travelAgentUser.permission.viewNewOrder){
      return (
        <Route path = "/merchant/newPaymentLink"
          component = { NewPaymentLink }
        />
      )
    }
  }

  viewNewItem=()=>{
    if(this.props.drifft.travelAgentUser.permission.viewNewItem){
      return (
        <Route path = "/merchant/newItem"
          component = { NewItemPage }
        />
      )
    }
  }

  viewViewItems=()=>{
    if(this.props.drifft.travelAgentUser.permission.viewNewItem){
      return (
        <Route path = "/merchant/viewItems"
          component = { ViewItemsPage }
        />
      )
    }
  }

  redirectCustomer(){
    return <Redirect to = "/customer/login" />
  }

  checkType(){
    let data = StorageService.get("client_JWT")

    if(data != null){
      if(data.token.substring(0,2) == 'CS' ){
        return "customer"
      }
      else if(data.token.substring(0,2) == 'DF'){
        return "merchant"
      }
    }
    return null

  }

  render() {
    if(this.state.redirect){
      let redirect = this.state.redirect

      this.setState({redirect: null})
      return redirect
    }
    
    return (
      <MainWrapper>
        <main>
          <BrowserRouter>
            <Switch>
              <Route exact
                path = "/"
                render = { this.redirectCustomer }
              />
              <Route exact
                path = "/merchant/login"
                component = { LogIn }
              />
              <Route exact
                path = "/customer/login"
                component = { CustomerLogin }
              />
              <Route exact
                path = '/404'
                component = { NotFound404 }
              />
              <Route exact
                path = "/customer/newuser"
                render = { this.authenticate.bind(this) }
              />
              <Route exact
                path = "/merchant/forgotPassword"
                component = { StageCheckMerchant }
              />
              <Route exact
                path = "/merchant/resetPassword"
                component = { StageResetPassword }
              />
              <Route exact
                path = "/merchant/error"
                component = { GenericError }
              />
              <Route exact
                path = "/merchant/complete"
                render = { (props) => <StageCompleteReset { ...props } /> }
              />
              <PrivateRouteProcess exact
                path = "/customer/verify/email"
                component = { <StageCheckUser /> }
                isAuthenticated = { this.state.inFlow }
              />
              <PrivateRouteProcess exact
                path = "/customer/newuser/finish"
                component = { <StageFinish /> }
                isAuthenticated = { this.state.inFlow }
              />
              <PrivateRouteProcess exact
                path = "/customer/returninguser/phone"
                component = { <StageReturnPhone /> }
                isAuthenticated = { this.state.inFlow }
              />
              <PrivateRouteProcess exact
                path = "/customer/returninguser/password"
                component = { <StageReturnPassword /> }
                isAuthenticated = { this.state.inFlow }
              />
              <PrivateRouteProcess exact
                path = "/customer/returninguser/error"
                component = { <ErrorPhoneIdNull /> }
                isAuthenticated = { this.state.inFlow }
              />
              <PrivateRouteLogin 
                type = { this.checkType.bind(this) } 
                viewProductLink = { this.viewProductLink.bind(this) } 
                viewPaymentLink = { this.viewPaymentLink.bind(this) } 
                viewNewLink = { this.viewNewLink.bind(this) }
                viewNewItem = { this.viewNewItem.bind(this) }
                viewViewItems = { this.viewViewItems.bind(this) }
                travelAgentUser = { this.props.travelAgentUser }
              />
              {/* <PrivateRouteMerchant exact isAuthenticated={this.checkLocalStorage()} >
                {this.wrappedRoutesMerchant()}
              </PrivateRouteMerchant>
 */}
              <Route component = { NotFoundRedirect } />
            </Switch>

          </BrowserRouter>

        </main>
      </MainWrapper>
    )
  }

}
const NotFoundRedirect = () => <Redirect to = '/404' />

function PrivateRouteLogin({type, viewNewLink, viewPaymentLink, viewProductLink, viewNewItem, viewViewItems,travelAgentUser, ...rest}){
  return (<Route { ...rest }
    render = { () => {
    let checkType = type()

    if (checkType === "customer"){
      return(
        <div>
          <LayoutCustomer />
          <div className = "container__wrap">
            <Switch>
              <Route path = "/customer/account"
                component = { Account }
              />
              <Route path = "/customer/dashboard"
                component = { Dashboard }
              />
              <Route path = "/customer/instalment"
                component = { Instalment }
              />
              <Route path = { '/customer/paynow/:orderId' }
                component = { PayNow }
              />
              <Route path = "/customer/addnewcard"
                component = { InjectedAddNewCard }
              />
              <Route component = { NotFoundRedirect } />
            </Switch>
          </div>
        </div>
      )    
    }
    if(checkType === "merchant") {
      if(travelAgentUser && !travelAgentUser.isAdmin){
        return(
          <div>
            <Layout />
            <div className = "container__wrap">
              <Switch>
                {viewNewLink()}
                {viewProductLink()}
                {viewPaymentLink()}
                <Route path = "/merchant/pastpayments"
                  component = { DatatablePage }
                />
                <Route path = "/merchant/settings/changepassword"
                  component = { ChangePassword }
                />
                <Route component = { NotFoundRedirect } />
              </Switch>
            </div>
          </div>
        )
      }else{
        return (
          <div>
            <Layout />
            <div className = "container__wrap">
              <Switch>
                {viewNewItem()}
                {viewViewItems()}
                {viewNewLink()}
                {viewProductLink()}
                {viewPaymentLink()}

                {/* <Route path="/newlink" component={NewLinkPage} /> */}
                <Route path = "/merchant/dashboard"
                  component = { ECommerceDashboard }
                />
                <Route path = "/merchant/pastpayments"
                  component = { DatatablePage }
                />
                <Route path = "/merchant/redemptionCode"
                  component = { RedemptionCodePage }
                />
                <Route path = "/merchant/settings/refundrequest"
                  component = { RefundRequestpage }
                />
                <Route path = "/merchant/settings/changepassword"
                  component = { ChangePassword }
                />
                <Route path = "/merchant/settings/report"
                  component = { ReportPage }
                />
                <Route path = "/merchant/settings/qrcode"
                  component = { QRCodePage }
                />
                <Route path = "/merchant/settings/branches"
                  component = { ViewBranchPage }
                />
                <Route component = { NotFoundRedirect } />
              </Switch>
            </div>
          </div>
      )
      }
      


    } 
    else{ 
      return <Redirect to = '/404' />
    }
  }
}
          />)
}



export default Router
