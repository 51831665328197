import React from 'react'
import { Container } from 'reactstrap'
import InstalmentCard from '../../../shared/components/instalmentCard/index'
import { connect } from 'react-redux'
import { getInstalmentCard, submitEarlyPayment } from '../../../redux/reducers/customerReducer'
import Button from '../../../components/button/Button'
import { Link } from 'react-router-dom'
import {BlockButtonContainer} from '../../../shared/components/layout/BlockButtonContainer'
@connect(
  store => ({
    customer: store.customer
  }),
  dispatch => ({
    getInstalmentCard: (clients) => dispatch(getInstalmentCard(clients)),
    submitEarlyPayment: (orderId,paymentType) => dispatch(submitEarlyPayment(orderId,paymentType))
  })
)

class PayNow extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      success: null, 
      width: window.innerWidth,
      loading: null,
      paymentType: null,
      onlyPayFully: null
    }
    this.paymentErrorPage = this.paymentErrorPage.bind(this)
    this.paymentSuccessPage = this.paymentSuccessPage.bind(this)
    this.paymentSelectionPage = this.paymentSelectionPage.bind(this)
    this.idInvalidPage = this.idInvalidPage.bind(this)

    
  }

  
  async componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
    if (this.props.customer.userInfo && !this.props.customer.instalmentCard) {
      await this.props.getInstalmentCard(this.props.customer.userInfo.clients)
    }

    if(this.props.customer.instalmentCard){
      this.props.customer.instalmentCard.forEach(instalment => {
        if (instalment.internalId === this.props.match.params.orderId && !this.state.onlyPayFully) {
          if ((instalment.instalments.total - instalment.instalments.complete) == 1){
            this.setState({onlyPayFully: true, paymentType: 'toPayFully'})
          }
        }
      })  
    }
  }

  async componentWillReceiveProps(nextProps){
    if (nextProps.customer.userInfo && !nextProps.customer.instalmentCard) {
      await nextProps.getInstalmentCard(nextProps.customer.userInfo.clients)
    }
    if(nextProps.customer.instalmentCard){
      nextProps.customer.instalmentCard.forEach(instalment => {
        if (instalment.internalId === nextProps.match.params.orderId && !this.state.onlyPayFully) {
          if ((instalment.instalments.total - instalment.instalments.complete) == 1){
            this.setState({onlyPayFully: true, paymentType: 'toPayFully'})
          }
        }
      })  
    }
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }
  
  async submitEarlyPayment() {
    this.props.submitEarlyPayment(this.props.match.params.orderId, this.state.paymentType).then(() => {
      if (this.props.customer.payNowResult) {
        this.setState({ success: true, loading: false })
      }
      else this.setState({ success: false, loading: false })
    }, () => {
      this.setState({ success: false, loading: false })
    })
    this.setState({ loading: true })
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  };

  renderCards() {
    let result

    this.props.customer.instalmentCard.forEach(instalment => {
      if (instalment.internalId == this.props.match.params.orderId) {

        const { shopName, orderId, internalId, total, orderDate, instalments, instalmentDetails } = instalment

        result = (<InstalmentCard
          flexible
          paymentType = { this.state.paymentType }
          key = { orderId }
          orderId = { orderId }
          internalId = { internalId }
          data = { { shopName: shopName, orderDate: orderDate, total: total, instalments: instalments } }
          instalments = { instalmentDetails }
                  />)
      }
    })
    return result
  }

  isIDValid(){
    return this.props.customer.instalmentCard.some((instalment)=>{
      return (instalment.internalId == this.props.match.params.orderId)
    })
  }

  backButtonClicked(){
    if(this.state.onlyPayFully){
      window.open('/customer/instalment',"_self")
    }else{
      this.setState({paymentType: null})
    }
  }

  getinstalmentCardDetails(type) {

    let foundInstalment = null

    for(const instalment of this.props.customer.instalmentCard){
      if (instalment.internalId === this.props.match.params.orderId) foundInstalment = instalment
    }

    return (foundInstalment && type in foundInstalment) ? foundInstalment[type] : null
  }

  paymentSelectionPage(){
    return (
      <Container>

        <div className = "account">
          <div className = "account__wrapper">
            <div className = "account__card"
              style = { { maxWidth: "1920px" } }
            >
              <div className = "account__head"
                style = { { marginBottom: "10px" } }
              >
                <h3 className = "account__title">Pay off your instalment plan</h3>
              </div>
              <h5 className = "account_sub"
                style = { { marginBottom: "30px", lineHeight: '1.8' } }
              >
            Would you like to pay off this instalment plan?
              </h5>
              <BlockButtonContainer style = { { marginTop: '50px' } }>
                {/* Temporarily remove functionality to pay off only one instalment because of Stripe changing prorating of subscriptions. */}
                {/* <Button onClick = { () => { this.setState({paymentType: 'toPayOne'})} }>Pay just one instalment</Button> */}
                <Button onClick = { () => { this.setState({paymentType: 'toPayFully'})} }>Pay off all remaining instalments</Button>
              </BlockButtonContainer>
            </div>
          </div>
        </div>
      </Container>
    )
  }

  paymentErrorPage(){
    return (
      <div className = "account">
        <div className = "account__wrapper">
          <div className = "account__card"
            style = { { maxWidth: "1920px" } }
          >
            <div className = "account__head"
              style = { { marginBottom: "10px" } }
            >
              <h3 className = "account__title">Sorry, your payment couldn’t be completed.</h3>
            </div>
            <h5 className = "account_sub"
              style = { { marginBottom: "30px", lineHeight: '1.8' } }
            >
              This could be because there’s a problem with the bank and they are rejecting this payment or there are insufficient funds in the card you tried paying with.
              <br /> Please add another card to finish the transaction.                </h5>
            <div style = { { marginTop: '50px' } }>
              <Button onClick = { () => { window.location.reload() } }>Retry</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  paymentSuccessPage(){
    let title
    let subtitle

    if(this.state.paymentType === 'toPayFully'){
      title = "Your payment is being processed!"
      subtitle = ". Upon successful payment you shall receive email notification to your designated email address."
    }else{
      title = "You have successfully paid off your next instalment!"
      subtitle = ". Your next instalment will be due one month from now."
    }  
    return (
      <div className = "account">
        <div className = "account__wrapper">
          <div className = "account__card"
            style = { { maxWidth: "1920px" } }
          >
            <div className = "account__head"
              style = { { marginBottom: "10px" } }
            >
              <h3 className = "account__title"
                style = { { fontSize: '1.5rem' } }
              >{title}</h3>
            </div>
            <h5 className = "account_sub"
              style = { { marginBottom: "30px" } }
            />
            <div style = { { lineHeight: '1.8' } }>
              {"Your card ending in " + this.getinstalmentCardDetails('defaultCard')
                + " was charged " + this.getinstalmentCardDetails(this.state.paymentType) + subtitle}</div>
            <div style = { { marginTop: '50px' } }>
              <Link to = '/customer/dashboard'
                onClick = { async () => { await new Promise(r => setTimeout(r, 1000)); window.location.reload() } }
              >
                <Button>Return to dashboard</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  idInvalidPage(){
    return(
      <div className = "account">
        <div className = "account__wrapper">
          <div className = "account__card"
            style = { { maxWidth: "1920px" } }
          >
            <div className = "account__head"
              style = { { marginBottom: "10px" } }
            >
              <h3 className = "account__title">Whoops! Looks like your link was invalid.</h3>
            </div>
            <h5 className = "account_sub"
              style = { { marginBottom: "30px", lineHeight: '1.8' } }
            >
          Go back to the dashboard and try again.  
            </h5>
            <div style = { { marginTop: '50px' } }>
              <Link to = '/customer/dashboard'
                onClick = { async () => { await new Promise(r => setTimeout(r, 1000)); window.location.reload() } }
              >
                <Button>Return to dashboard</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  showPayOffPage(){
    let title
    let subtitle

    if(this.state.paymentType === 'toPayFully'){
      title = <h3 className = "account__title"
        style = { { fontSize: '1.5rem' } }
              >You are choosing to pay off all your remaining instalments.</h3>
      subtitle = <h5 className = "account_sub"
        style = { { marginBottom: "30px" } }
                 >For your reference, here are the details of your instalment plan.
        <br /> Note: once you pay off early, you will not be charged any more monthly instalments.</h5>
    }else{
      title = <h3 className = "account__title"
        style = { { fontSize: '1.5rem' } }
              >You are choosing to pay one instalment.</h3>
      subtitle = <h5 className = "account_sub"
        style = { { marginBottom: "30px" } }
                 >For your reference, here are the details of your instalment plan.
        <br /> Note: once you pay off one instalment, the billing cycle will reset and the next instalment will come as scheduled below.</h5>
    }
      return (
        <div className = "account">
          <div className = "account__wrapper">
            <div className = "account__card"
              style = { { maxWidth: "1920px" } }
            >
              <div className = { window.innerWidth < 500 ? '' : "account__head" }
                style = { { marginBottom: "10px" } }
              >
                {title}
              </div>
              {subtitle}
              <div style = { { marginBottom: "30px" } }>
                {this.renderCards()}
              </div>
              {this.props.customer.instalmentCard && <div style = { { display: "flex", marginTop: "1.4rem", marginBottom: "1.4rem", background: '#F1F7F9', borderRadius: '4px' } }>
                <p />
                <span style = { { fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'normal', fontSize: '.85rem', lineHeight: '24px', letterSpacing: '0.1px', margin: '10px', color: '#374044' } }>
                  {'By clicking "Confirm", you acknowledge that you will be charged ' + this.getinstalmentCardDetails(this.state.paymentType) + ' on your card ending in ' + this.getinstalmentCardDetails('defaultCard') + '.'}</span>
              </div>}

              <Button onClick = { this.backButtonClicked.bind(this) }
                white
              >Back</Button>
              <Button onClick = { this.submitEarlyPayment.bind(this) }>Confirm</Button>
            </div>
          </div>
        </div>
      )
    }

  render() {
    if (this.state.loading || this.props.customer.instalmentCard == undefined) {
      return (
        <div style = { { display: 'block', margin: 'auto', width: '1%', marginTop: '25%' } }>
          <div className = "load__icon-wrap">
            <svg className = "load__icon">
              <path fill = "#ff8600"
                d = "M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      )
    }
    if(!this.isIDValid()){
      return this.idInvalidPage()
    }
    if (this.state.paymentType == null){
      return this.paymentSelectionPage()
    }
    if(this.state.paymentType && this.state.success == null){
      return this.showPayOffPage()
    }
    if (this.state.success === true) {
      return this.paymentSuccessPage()
    } else if (this.state.success === false) {
      return this.paymentErrorPage()
    } 
  }
}


export default PayNow