import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
import EyeIcon from 'mdi-react/EyeIcon'
import KeyVariantIcon from 'mdi-react/KeyVariantIcon'
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Button from '../../../../components/button/Button'

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super()
    this.state = {
      showPassword: false,
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      ...this.state,
      nextProps
    })
  }

  showPassword = (e) => {
    e.preventDefault()
    this.setState(prevState => ({ showPassword: !prevState.showPassword }))
  }

  render() {
    const { handleSubmit } = this.props
    const { showPassword } = this.state

    return (
      <form className = "form"
        onSubmit = { handleSubmit }
      >
        <div className = "form__form-group">
          <span className = "form__form-group-label">Username</span>
          <div className = "form__form-group-field">
            <div className = "form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name = "email"
              component = "input"
              type = "text"
              placeholder = "Email"
            />
          </div>
        </div>
        <div className = "form__form-group">
          <span className = "form__form-group-label">Password</span>
          <div className = "form__form-group-field">
            <div className = "form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name = "password"
              component = "input"
              type = { showPassword ? 'text' : 'password' }
              placeholder = "Password"
            />
            <button
              className = { `form__form-group-button${showPassword ? ' active' : ''}` }
              onClick = { e => this.showPassword(e) }
              type = "button"
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div style = { {display: 'flex',justifyContent: 'space-between',width: '100%'} }>
          <Button type = "submit">Submit</Button>
          <button type = "button"
            className = "new-user-link"
            onClick = { this.props.forgotPasswordLink }
          >Forgot Password</button>
        </div>
        <div style = { {width: '100%',textAlign: 'left'} }>
          <Link to = '/customer/login'>
            <button type = "button"
              className = "merchant-link"
            >Are you a Split user? Click here.</button>
          </Link>
        </div>

        {this.props.failedLogin && this.props.errorMessage == null &&
          <span className = "form__form-group-label">Incorrect Password Entered</span>
        }
        {this.props.failedLogin && this.props.errorMessage != null &&
          <span className = "form__form-group-label"> {this.props.errorMessage} </span>
        }
      </form>
    )
  }
}

export default reduxForm({
  form: 'log_in_form',
})(LogInForm)
