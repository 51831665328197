import React from 'react'
import { Redirect } from 'react-router-dom'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { resetError } from '../../../../redux/reducers/drifftReducer'


@connect(
    store => ({
        drifft: store.drifft,
    }),
    dispatch => ({
      resetError: () => dispatch(resetError())
    })
)
class GenericError extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            returnLogIn: false
        }
    }

    componentWillUnmount() {
      this.props.resetError()
    }

    render(){
        if(this.props.drifft.error == undefined || this.state.returnLogIn) return <Redirect to = '/merchant/login' />
        return(
          <div className = "account">
            <div className = "account__wrapper">
              <div className = "account__card">
                <h3 className = "account__title">{this.props.drifft.error}</h3>
                <div style = { { margin: "30px 0px 0px 0px" } }>
                  <Button color = "primary"
                    onClick = { () => this.setState({returnLogIn: true}) }
                  >Return to log in page</Button>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default GenericError