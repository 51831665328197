import React, { Component } from 'react'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'
import {
  Card, CardBody, Col, Row, Container
} from 'reactstrap'
import Button from '../../../components/button/Button'
import { connect } from "react-redux"
import { hideDrifftItem, loadCreatedItems, setV2FELink, toggleDrifftItemStatus } from '../../../redux/reducers/drifftReducer'
import { Fragment } from 'react'
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
@connect(
  store => ({
    drifft: store.drifft
  }),
  dispatch => ({
    loadCreatedItems: (startDate, endDate) => dispatch(loadCreatedItems(startDate, endDate)),
    toggleDrifftItemStatus: (token) => dispatch(toggleDrifftItemStatus(token)),
    hideDrifftItem: (token) => dispatch(hideDrifftItem(token)),
    setV2FELink: () => dispatch(setV2FELink())
  })
)

class ItemDatatablePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      token: '',
      data: {
        columns: this.getColumns(),
        rows: this.props.drifft.createdItems.rows
      }
    }
  }

  componentDidMount() {
    this.props.setV2FELink()
    this.props.loadCreatedItems()
  }

  componentWillReceiveProps = (nextProps) => {
    for (let i = 0; i < nextProps.drifft.createdItems.rows.length; i++) {
      if (nextProps.drifft.createdItems.rows[i].status !== "Expired") {
        let tokenString = nextProps.drifft.createdItems.rows[i].link.split('=')[1]

        nextProps.drifft.createdItems.rows[i].toggle = {}
        // TODO: Change this so it doesn't create a new function in onclick
        nextProps.drifft.createdItems.rows[i].toggle =
          <Button color = "primary"
            size = "sm"
            onClick = { () => this.toggleDrifftLink(tokenString) }
          >
            {nextProps.drifft.createdItems.rows[i].status === "Active" ? "Deactivate" : "Activate"}
          </Button>
      }

      if (nextProps.drifft.createdItems.rows[i].status === "Not Active") {
        let tokenString = nextProps.drifft.createdItems.rows[i].link.split('=')[1]

        nextProps.drifft.createdItems.rows[i].hideItem = {}
        nextProps.drifft.createdItems.rows[i].hideItem =
          <Button color = "primary"
            size = "sm"
            onClick = { () => this.handleClickOpen(tokenString) }
          >
            Delete
          </Button>
      }

      if (nextProps.drifft.createdItems.rows[i].status === "Expired") {
        let tokenString = nextProps.drifft.createdItems.rows[i].link.split('=')[1]

        nextProps.drifft.createdItems.rows[i].toggle =
          <Fragment />

        nextProps.drifft.createdItems.rows[i].hideItem = {}
        nextProps.drifft.createdItems.rows[i].hideItem =
          <Button color = "primary"
            size = "sm"
            onClick = { () => this.handleClickOpen(tokenString) }
          >
            Delete
          </Button>
      }
    }

    // Create function that updates column values so MDR gets updated
    this.setState({
      data: {
        columns: this.getColumns(),
        rows: nextProps.drifft.createdItems.rows
      }
    })
  }

  handleClose = () => {
    this.setState({ open: false })
  };

  async handleDelete(token) {
    if (token !== '') {
      await this.props.hideDrifftItem(token)
      this.props.loadCreatedItems()
      this.handleClose()
    }
  }

  handleClickOpen = (tokenString) => {
    this.setState({ open: true, token: tokenString })
  };

  async toggleDrifftLink(token) {
    if (token !== '') {
      await this.props.toggleDrifftItemStatus(token)
      this.props.loadCreatedItems()
    }
  }

  getColumns = () => {
    let columns = [
      {
        label: 'Title',
        field: 'title',
        width: 270
      },
      {
        label: 'Description',
        field: 'description',
        width: 200
      },
      {
        label: 'Price',
        field: 'price',
        width: 100
      },
      {
        label: 'Link',
        field: 'link',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Uses Left',
        field: 'usesLeft',
        width: 100
      },
      {
        label: 'Expires At',
        field: 'expiresAt',
        width: 100
      },
      {
        label: 'Status',
        field: 'status',
        width: 100
      },
      {
        label: 'Payment Type',
        field: 'paymentType',
        width: 100
      },
      {
        label: 'Toggle Active',
        field: 'toggle',
      },
      {
        label: 'Delete',
      }
    ]

    return columns
  }

  render() {
    return (
      <Container className = "invoice" >
        <Row>
          <Col md = { 12 }>
            <Card>
              <CardBody className = "grid">
                <MDBTable responsive>
                  <MDBTableHead columns = { this.state.data.columns } />
                  <MDBTableBody rows = { this.state.data.rows } />
                </MDBTable>
              </CardBody>
            </Card>

          </Col>

        </Row>

        <Dialog
          open = { this.state.open }
          onClose = { this.handleClose }
          aria-labelledby = "alert-dialog-title"
          aria-describedby = "alert-dialog-description"
        >
          <DialogTitle id = "alert-dialog-title">
            {"Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id = "alert-dialog-description">
              Are you sure you want to delete this item? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick = { this.handleClose }
              color = "primary"
            >
              No
            </Button>
            <Button onClick = { () => this.handleDelete(this.state.token) }
              color = "primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Container>


    )
  }
}

export default ItemDatatablePage