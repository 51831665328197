import InputMask from 'react-input-mask'
import React from "react"
import TextBox from './TextBox'

const Masked = (p) => (
  <InputMask className = { p.className }
    name = { p.name }
    style = { p.style }
    maskChar = { null }
    mask = { p.mask }
    value = { p.value }
    onChange = { p.onChange }
    onBlur = { p.onBlur }
  >
    {(inputProps) => <TextBox { ...inputProps }
      error = { p.error }
      placeholder = { p.placeholder }
      autoComplete = { p.autoComplete }
      type = { p.type }
                     />}
  </InputMask>
)
  
export default Masked