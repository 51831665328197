import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { connect } from "react-redux"
import ActiveInstalments from './ActiveInstalments/index'
@connect(
    store => ({
      customer: store.customer
    }),
  )
  
class Instalment extends React.Component{
    constructor(props){
      super(props)
    }

    render(){
        return(
          <Container className = "dashboard">
            <Row style = { {display: 'flex',justifyContent: 'space-between'} }>
              <Col style = { {margin: 'auto'} }>
                <h3 className = "page-title" >{`My Instalments`}</h3>
              </Col>
            </Row>
            <Row>
              <ActiveInstalments />
            </Row>
          </Container>
          )
    }
}

export default Instalment