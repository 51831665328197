import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker'
import { Col, Row, Card, CardBody } from 'reactstrap'
import Button from '../../../../components/button/Button'
const ReportForm = props => {
    const { handleSubmit } = props

    return (<Row>
      <Col md = { 12 }>
        <Card>
          <CardBody>
            <div className = "card__title">
              <h5 className = "bold-text">Download past orders by Date of First Payment</h5>
              <p />
              <p />
              <form className = "form"
                onSubmit = { handleSubmit }
              >
                <div className = "form__form-group">
                  <div className = "form__form-group-field">
                    <Field
                      name = "interval_date"
                      component = { renderIntervalDatePickerField }
                    />
                  </div>
                </div>
                <Button type = "submit">Submit</Button>
              </form>
            </div>
          </CardBody>
        </Card>
      </Col> 
    </Row> 
    )
}



export default reduxForm({
    form: 'date_picker_form', // a unique identifier for this form
  })(withTranslation('common')(ReportForm))