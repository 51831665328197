import React from "react"
import { connect } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import Panel from '../../../shared/components/Panel'
import { getCreditLimit, checkFrozenStatus } from '../../../redux/reducers/customerReducer'
import InstalmentWidget from "./InstalmentWidget/index"
import { BalanceCard } from "@paywithsplit/split-react-component"
import "@paywithsplit/split-react-component/dist/index.css"
import { FullWideNotification } from '../../../shared/components/Notification'
import NotificationSystem from 'rc-notification'

@connect(
  (store) => ({
    customer: store.customer,
  }),
  dispatch => ({
    getCreditLimit: (clients) => dispatch(getCreditLimit(clients)),
    checkFrozenStatus: (client) => dispatch(checkFrozenStatus(client))
  })
)

class Dashboard extends React.Component {
  async componentDidMount(){
    if (this.props.customer.userInfo){
      await this.props.getCreditLimit()
    }
    if (this.props.customer.isFrozen == null && this.props.customer.userInfo) {
      await this.props.checkFrozenStatus(this.props.customer.userInfo.defaultClient.UID)
      if (this.props.customer.isFrozen) {
        this.showFrozenNotification()
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.customer.userInfo !== this.props.customer.userInfo){
      await this.props.getCreditLimit()
    }
  }

  showFrozenNotification = () => {
    NotificationSystem.newInstance({ style: { top: 65 } }, notification=> {
      notification.notice({
        content: (
          <FullWideNotification 
            color = "success"
            message = "Your account has been frozen. To continue using Split's services, please reach out to us at support@paywithsplit.co"
            
          />
        ),
        duration: 30,
        closable: true,
        style: { top: 0, left: 0 },
        className: 'full ltr-support'
      })
    })
  }

  render() {
    return (
      <Container className = "dashboard">
        <Row style = { { display: "flex", justifyContent: "space-between" } }>
          <Col style = { { margin: "auto" } }>
            <h3 className = "page-title">{`My Overview`}</h3>
          </Col>
        </Row>
        {(this.props.customer.remainingCredit || this.props.customer.remainingCredit === 0) &&
          <Row>
            <Panel lg = { 8 }
              noMinus = 'true'
            >
              <div style = { {justifyContent: 'center', display: 'flex'} }>
                <BalanceCard 
                  balance = { this.props.customer.remainingCredit } 
                  currency = "MYR"
                  width = { '280px' }
                  height = { '150px' }
                /> 
              </div>
            </Panel>
          </Row>
      } 
        <Row>
          <InstalmentWidget />
        </Row>
      </Container>
    )
  }
}

export default Dashboard
