import React, { PureComponent } from 'react'
import { Col, Container, Row } from 'reactstrap'
import "react-datepicker/dist/react-datepicker.css"
import ApiClient from '../../../helpers/ApiClient'
import moment from 'moment'
import ReportForm from './components/ReportForm'
export default class ReportPage extends PureComponent {
   
  constructor(props) {
    super(props)
  }

   showResults = async (values) => {
    if (values && values.interval_date && values.interval_date.start && values.interval_date.end){
      let startDate = moment(values.interval_date.start)
      let endDate = moment(values.interval_date.end)

      await ApiClient._sendDownloadReport('GET','/drifft/report', {startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD')})
    }
  }

  render() {
    return (
      <Container className = "dashboard">
        <Row>
          <Col md = { 12 }>
            <h3 className = "page-title">Reports</h3>
          </Col>
        </Row>
        <ReportForm onSubmit = { this.showResults } />
      </Container>
    )
  }
}





