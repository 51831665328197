import PhoneInput from 'react-phone-input-2'
import styles from './styles.module.css'
import './react-phone-input-2.css'
import classNames from 'classnames'
import React from "react"

export default (props) => {
    let { className, error, success, type, name, label, value,placeholder, preferredCountries, onChange, disabled,country,isSecured, message, ...other } = props

    if (success) {
        message = success
    } else if (error) {
        message = error
    }
    return (
      <div className = { classNames([styles.textBoxWrapper, className]) }>
        <label className = { classNames([styles.textBoxLabel, className]) } >{label}</label>
        {isSecured
            ? <input { ...other }
              type = { type }
              value = { value }
              disabled
              className = { classNames([styles.textBox, error && styles.error, success && styles.success, className]) }
              />
            : <PhoneInput
              country = { country }
              value = { value }
              name = { name }
              onChange = { onChange }
              preferredCountries = { preferredCountries }
              placeholder = { placeholder }
              enableSearch
              disableSearchIcon
              countryCodeEditable = { false }
              isValid = { error ? false : true }
              disabled = { disabled }
              />
}            <label className = { classNames([styles.textBoxMessage, error && styles.error, success && styles.success, className]) } >{message}</label>
      </div>
    )
}

